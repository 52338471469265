import { useState, useEffect } from "react";
import { Button, Box, Modal, IconButton, Tooltip } from "@mui/material";
import ConfettiExplosion from "react-confetti-explosion";
import SocialShare from "../SocialShare";
import { Launch } from "@mui/icons-material";
import NoImage from "../../images/no-image.png";
import logo from "../../images/logoBlack.png";

export default function ShareableCard({
  assetTitle,
  collectionTitle,
  endDate,
  duration,
  imageURL,
  ranking,
  rankingURL,
  showing,
  walletAddress,
  isComplete,
  isEmergencyBreak,
  transactionHash,
}) {
  const [hasShownConfetti, setHasShownConfetti] = useState(false);

  useEffect(() => {
    if (showing) {
      setTimeout(() => {
        setHasShownConfetti(true);
      }, 5000);
    }
  }, [showing, hasShownConfetti]);

  return isComplete ? (
    <Modal open={showing} sx={{ display: "flex", overflow: "scroll" }}>
      <Box
        sx={{
          bgcolor: "background.paper",
          width: "60vw",
          //   height: "80vh",
          marginX: "auto",
          marginY: "auto",
          textAlign: "center",
          borderRadius: "10px",
        }}
      >
        {showing && !isEmergencyBreak && !hasShownConfetti && (
          <Box
            sx={{
              width: "100%",
              justifyContent: "center",
              display: "flex",
            }}
          >
            <ConfettiExplosion floorHeight={2000} />
          </Box>
        )}
        <div
          id="share-card"
          style={{
            backgroundColor: "white",
            paddingBottom: "20px",
            borderRadius: "10px",
          }}
        >
          <Box sx={{ display: "flex", justifyContent: "center" }}>
            <Box
              sx={{
                width: "60px",
                marginTop: "auto",
                marginBottom: "auto",
                marginRight: "20px",
              }}
            >
              <img src={logo} alt="logo" style={{ width: 55 }} />
            </Box>
            {isEmergencyBreak ? (
              <h3 style={{ color: "black" }}>Emergency Unlock!</h3>
            ) : (
              <h3 style={{ color: "black" }}>Diamond-Hand Completed!</h3>
            )}
            <Tooltip title="See Transaction">
              <IconButton
                size="large"
                aria-label="Go to Vault"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                onClick={() => {
                  let url =
                    process.env.REACT_APP_ENV === "sandbox" ||
                    process.env.REACT_APP_ENV === "dev"
                      ? "https://goerli.etherscan.io/tx/" + transactionHash
                      : "https://etherscan.io/tx/" + transactionHash;
                  window.open(url);
                }}
                sx={{
                  height: "60px",
                  width: "60px",
                  marginTop: "auto",
                  marginBottom: "auto",
                }}
              >
                <Launch sx={{ color: "#93a2a9" }}></Launch>
              </IconButton>
            </Tooltip>
          </Box>
          <img
            className="Diamondhanded-Image"
            src={imageURL}
            onError={({ currentTarget }) => {
              currentTarget.onerror = null; // prevents looping
              currentTarget.src = NoImage;
            }}
            alt="Diamond-Handed Asset"
          />
          {isEmergencyBreak ? (
            <p className="p-shareablecard">
              Emergency unlock was a success! You're no longer diamond-handing
              and this item is now back in your wallet!
            </p>
          ) : (
            <p className="p-shareablecard">
              Congratulations! You have successfully diamond-handed{" "}
              <b>{assetTitle}</b> for <b>~ {duration}</b>! This item is now back
              in your wallet!
            </p>
          )}
        </div>

        {isEmergencyBreak ? null : (
          <>
            <p className="p-shareablecard" style={{ marginTop: "-20px" }}>
              Share to your socials?
            </p>
            <SocialShare
              message={
                "I've successfully diamond-handed my " +
                assetTitle +
                " for " +
                duration +
                "!"
              }
              address={walletAddress}
              showDownload={true}
            />
          </>
        )}

        <br></br>
        <Button
          href="/profile"
          sx={{
            color: "white",
            textTransform: "none",
            background: "#08121b",
            "&:hover": {
              backgroundColor: "black",
            },
            height: "60px",
            marginBottom: "20px",
            marginLeft: "auto",
            marginRight: "auto",
            width: 200,
            fontSize: 21,
            fontFamily: "Poppins",
            borderRadius: "20px",
          }}
        >
          Back to Profile!
        </Button>
      </Box>
    </Modal>
  ) : (
    <Modal open={showing} sx={{ display: "flex", overflow: "scroll" }}>
      <Box
        sx={{
          bgcolor: "background.paper",
          width: "60vw",
          //   height: "80vh",
          marginX: "auto",
          marginY: "auto",
          textAlign: "center",
          borderRadius: "10px",
        }}
      >
        {showing && !hasShownConfetti && (
          <Box
            sx={{
              width: "100%",
              justifyContent: "center",
              display: "flex",
            }}
          >
            <ConfettiExplosion floorHeight={2000} />
          </Box>
        )}
        <div
          id="share-card"
          style={{
            backgroundColor: "white",
            paddingBottom: "20px",
            borderRadius: "10px",
          }}
        >
          <Box sx={{ display: "flex", justifyContent: "center" }}>
            <Box
              sx={{
                width: "60px",
                marginTop: "auto",
                marginBottom: "auto",
                marginRight: "20px",
              }}
            >
              <img src={logo} alt="logo" style={{ width: 55 }} />
            </Box>

            <h3 style={{ color: "black" }}>Diamond-Hand Successful!</h3>
            <Tooltip title="See Transaction">
              <IconButton
                id="tx-link"
                size="large"
                aria-label="Go to Vault"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                onClick={() => {
                  let url =
                    process.env.REACT_APP_ENV === "sandbox" ||
                    process.env.REACT_APP_ENV === "dev"
                      ? "https://goerli.etherscan.io/tx/" + transactionHash
                      : "https://etherscan.io/tx/" + transactionHash;
                  window.open(url);
                }}
                sx={{
                  height: "60px",
                  width: "60px",
                  marginTop: "auto",
                  marginBottom: "auto",
                }}
              >
                <Launch sx={{ color: "#93a2a9" }}></Launch>
              </IconButton>
            </Tooltip>
          </Box>
          <img
            className="Diamondhanded-Image"
            src={imageURL}
            onError={({ currentTarget }) => {
              currentTarget.onerror = null; // prevents looping
              currentTarget.src = NoImage;
            }}
            alt="Diamond-Handed Asset"
          />
          <p className="p-shareablecard">
            You have successfully placed <b>{assetTitle}</b> into your vault to
            be diamond-handed for <b>~ {duration}</b>, withdrawable on{" "}
            <b>{endDate}!</b>
          </p>
          <p
            className="p-shareablecard"
            style={{
              maxWidth: "1200px",
            }}
          >
            You are currently placed <b>#{ranking}</b> on the{" "}
            <b>
              <a href={rankingURL}>leaderboard</a>
            </b>{" "}
            of diamond-handers for <b>{collectionTitle}</b>
            {collectionTitle !== "" ? "" : "this collection"}!
          </p>
        </div>
        <p className="p-shareablecard" style={{ marginTop: "-20px" }}>
          Share to your socials?
        </p>
        <SocialShare
          message={
            "I'm diamond-handing my " + assetTitle + " for " + duration + "!"
          }
          address={walletAddress}
          showDownload={true}
        />
        <p className="p-shareablecard">
          View & Unlock your Diamond-Hands in your Profile!
        </p>
        <Button
          href="/profile"
          sx={{
            color: "white",
            textTransform: "none",
            background: "#08121b",
            "&:hover": {
              backgroundColor: "black",
            },
            height: "60px",
            marginBottom: "20px",
            marginLeft: "auto",
            marginRight: "auto",
            width: 200,
            fontSize: 21,
            fontFamily: "Poppins",
            borderRadius: "20px",
          }}
        >
          Go to Profile!
        </Button>
      </Box>
    </Modal>
  );
}
