import React, { useEffect, useState } from "react";
import { Body } from "../components";
import {
  Avatar,
  Grid,
  Box,
  Card,
  CardMedia,
  CardContent,
  IconButton,
  Tooltip,
  FormControlLabel,
} from "@mui/material";

import NavBarHome from "../components/Home/NavBarHome";
import Footer from "../components/Footer";
import blockies from "ethereum-blockies";
import { StatsSwitch } from "../components/Styles/SwitchStyles";

//Wallet
import { contractFactoryAddress } from "./SpecificNFT";
import { Link, useParams } from "react-router-dom";
import CircularIndeterminate from "../components/CircularIndeterminate";

import Web3 from "web3-utils";
import { useAccount } from "wagmi";
import { ethers } from "ethers";

import { useNavigate } from "react-router-dom";

import { secondsToString } from "../extensions/secondsToString";
import ipfsToUrl from "../extensions/ipfsToUrl";
import NoImage from "../images/no-image.png";

//Icons
import { Inventory, Launch } from "@mui/icons-material";
import { startGrid } from "../components/Styles/GridWave";

function Profile() {
  const [address, setAddress] = useState("");

  const [vaultAddress, setVaultAddress] = useState("");
  const [displayAddress, setDisplayAddress] = useState("");
  // const [diamondList, setDiamondList] = useState([]);
  const [filteredDiamondList, setFilteredDiamondList] = useState([]); //filter out diamondIDs where status === 0 (still diamondhanding)
  const [isEmptyList, setIsEmptyList] = useState(true);
  //if diamondStruct was fetched
  const [structLoading, setStructLoading] = useState(true);
  //if DiamondList was fetched
  const [hasFetched, setHasFetched] = useState(false);

  //Load more
  const [currentFetchCursor, setCurrentFetchCursor] = useState("");
  const [oldFetchData, setOldFetchData] = useState([]);

  //Match diamondID to diamondList in this dictionary
  const [diamondListDict, setDiamondListDict] = useState({});
  //match diamondID to diamondStructs in this dictionary
  const [diamondStructs, setDiamondStructs] = useState({});

  //For fetching NFTs in vault and their metadata
  const [erc721InVault, setERC721InVault] = useState(false);
  const [erc1155InVault, setERC1155InVault] = useState(false);

  //match diamondID to opensea's api results in this dictionary
  const [diamondAssets, setDiamondAssets] = useState({});

  //profile stats
  const [profileStats, setProfileStats] = useState({});
  const [iconURL, setIconURL] = useState("");
  const [personalCollectionsAllTime, setPersonalCollectionsAllTime] =
    useState(false);
  const [personalCollectionsCurrent, setPersonalCollectionsCurrent] =
    useState(false);
  const [isAllTime, setIsAllTime] = useState(false);
  const { specified_address } = useParams();

  let navigate = useNavigate();

  //Gridwave animation
  useEffect(() => {
    startGrid();
  }, []);

  //Wallet
  const account = useAccount({
    onConnect({ address }) {
      setAddress(address);
    },
    onDisconnect() {
      setAddress("");
    },
  });
  useEffect(() => {
    //Make sure account updates
    if (account.isConnected) {
      setAddress(account.address);
    } else if (account.isDisconnected) {
      setAddress("");
    }
    if (address !== account.address && address !== "" && account.address) {
      //Address switched, reload
      window.location.reload();
    }
  }, [account, address]);

  async function updateENS() {
    //fetch ENS name if applicable, and change display address
    let walletAddress = address;

    if (specified_address) {
      walletAddress = specified_address;
    }
    if (Web3.isAddress(walletAddress)) {
      fetch(
        (process.env.REACT_APP_ENV === "production" ||
        process.env.REACT_APP_ENV === "sandbox"
          ? process.env.REACT_APP_HOST
          : "http://localhost:3001") +
          "/get_ens_name?" +
          new URLSearchParams({ walletAddress })
      )
        .then((response) => {
          if (response.status === 200) {
            return response.text();
          } else {
            throw new Error("fetch error");
          }
        })
        .then((name) => {
          setDisplayAddress(name);
        })
        .catch((error) => {
          // console.log(error);
        });
    }
  }

  function getBlockies(inputAddress) {
    const newIconURL = blockies
      .create({
        seed: inputAddress, // seed used to generate icon data, default: random
        size: 8, // width/height of the icon in blocks, default: 8
        scale: 16, // width/height of each block in pixels, default: 4
        // spotcolor: "#000", // each pixel has a 13% chance of being of a third color,
        // default: random. Set to -1 to disable it. These "spots" create structures
        // that look like eyes, mouths and noses.
      })
      .toDataURL();
    setIconURL(newIconURL);
  }

  function getProfileStats(creator_address) {
    //Fetch personal stats
    fetch(
      (process.env.REACT_APP_ENV === "production" ||
      process.env.REACT_APP_ENV === "sandbox"
        ? process.env.REACT_APP_HOST
        : "http://localhost:3001") +
        "/personal_stats?" +
        new URLSearchParams({ creator_address })
    )
      .then((response) => {
        return response.text();
      })
      .then((data) => {
        setProfileStats(JSON.parse(data));
      });

    //Fetch personal collections
    fetch(
      (process.env.REACT_APP_ENV === "production" ||
      process.env.REACT_APP_ENV === "sandbox"
        ? process.env.REACT_APP_HOST
        : "http://localhost:3001") +
        "/personal_collections?" +
        new URLSearchParams({ creator_address })
    )
      .then((response) => {
        return response.text();
      })
      .then((data) => {
        setPersonalCollectionsAllTime(JSON.parse(data));
      });
  }

  useEffect(() => {
    if (personalCollectionsAllTime) {
      //initialize sorting collection stats based on current diamond hands
      let current = JSON.parse(JSON.stringify(personalCollectionsAllTime));
      current.sort((a, b) =>
        Number(a.current_diamond_count) < Number(b.current_diamond_count)
          ? 1
          : -1
      );
      //filter out current = 0
      current = current.filter((el) => {
        return Number(el.current_diamond_count) !== 0;
      });
      setPersonalCollectionsCurrent(current);
    }
  }, [personalCollectionsAllTime]);

  //Use effect to fetch list of diamond hands when address changes
  useEffect(() => {
    async function fetchDiamondLists(walletAddress) {
      const contractAddress = contractFactoryAddress;
      fetch(
        (process.env.REACT_APP_ENV === "production" ||
        process.env.REACT_APP_ENV === "sandbox"
          ? process.env.REACT_APP_HOST
          : "http://localhost:3001") +
          "/get_vault_address?" +
          new URLSearchParams({ contractAddress, walletAddress })
      )
        .then((response) => response.text())
        .then((response) => {
          if (response !== "no vault") {
            const vaultAddress = response;
            setVaultAddress(vaultAddress);
            //fetch diamond nfts
            fetchNFTsInVault(vaultAddress);
            fetch(
              (process.env.REACT_APP_ENV === "production" ||
              process.env.REACT_APP_ENV === "sandbox"
                ? process.env.REACT_APP_HOST
                : "http://localhost:3001") +
                "/get_diamond_nfts?" +
                new URLSearchParams({ vaultAddress })
            )
              .then((response) => response.json())
              .then((response) => {
                setFilteredDiamondList(response.filteredDiamondList);
                setDiamondStructs(response.diamondStructs);
                setDiamondListDict(response.diamondListDict);
                setStructLoading(false);
              })
              .catch((error) => {
                console.error(error);
              });
          } else {
            setFilteredDiamondList([]);
            setHasFetched(true);
            setStructLoading(false);
          }
        })
        .catch((error) => {
          setFilteredDiamondList([]);
          setHasFetched(true);
        });
    }
    if (address !== "" && !specified_address) {
      //loading
      setStructLoading(true);
      fetchDiamondLists(address);
      getProfileStats(address);
      updateENS();
    } else if (specified_address) {
      //loading
      setStructLoading(true);
      //Check if specified address is a valid address
      if (Web3.isAddress(specified_address)) {
        fetchDiamondLists(specified_address);
        getProfileStats(specified_address);
        updateENS();
      } else {
        alert("The specified address is not a valid ethereum address!");
        navigate("/");
      }
    } else {
      //no address, skip loading
      setStructLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [address, specified_address]);

  useEffect(() => {
    //Make sure blockies generate the correct iconURL
    if (address !== "" && !specified_address) {
      getBlockies(address);
    } else if (specified_address) {
      //Check if specified address is a valid address
      if (Web3.isAddress(specified_address)) {
        getBlockies(specified_address);
      }
    }
  }, [iconURL, address, specified_address]);
  //Use effect to update diamondlist UI when list changes
  useEffect(() => {
    if (filteredDiamondList.length === 0) {
      setIsEmptyList(true);
      if (address !== "") {
        setHasFetched(true);
      } else if (specified_address) {
        setHasFetched(true);
      }
    } else {
      setIsEmptyList(false);
      //For each diamondhand list, fetch the specifc NFT being diamondhanded
      // fetchDiamondNFTs();
    }
  }, [filteredDiamondList, hasFetched, address, specified_address]);

  //Use effect to fetch more metadata if pageKey exists
  useEffect(() => {
    //fetch diamond nfts
    if (vaultAddress !== "") {
      fetchNFTsInVault(vaultAddress);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentFetchCursor]);

  async function fetchNFTsInVault(address) {
    let next = currentFetchCursor;
    let filter = false;
    fetch(
      (process.env.REACT_APP_ENV === "production" ||
      process.env.REACT_APP_ENV === "sandbox"
        ? process.env.REACT_APP_HOST
        : "http://localhost:3001") +
        "/get_nfts_of_address?" +
        new URLSearchParams({ address, next, filter })
    )
      .then((response) => response.json())
      .then((response) => {
        //Merge data with old data and set it as new
        let newData = oldFetchData.concat(response.nfts);
        setOldFetchData(newData);
        //Check if response exceeds page limit
        if (response.next) {
          //Keep fetching until all received all metadata
          setCurrentFetchCursor(response.next);
        } else {
          //separate erc721 from erc1155
          let filteredERC1155 = newData.filter((item) => {
            return item.contract.type === "ERC1155";
          });
          setERC1155InVault(filteredERC1155);
          let filteredERC721 = newData.filter((item) => {
            return item.contract.type === "ERC721";
          });
          setERC721InVault(filteredERC721);
        }
      })
      .catch((error) => console.log("error", error));
  }

  useEffect(() => {
    //Matches diamondContractAddresses + diamondTokenID to a diamondID
    let diamondIDMatch = {};

    //[contract_address, token_id]
    let diamondERC721s = [];
    let diamondERC1155s = [];

    if (!structLoading && (erc721InVault || erc1155InVault)) {
      for (let i = 0; i < filteredDiamondList.length; i++) {
        const diamondID = ethers.BigNumber.from(
          filteredDiamondList[i][0]
        ).toNumber();
        const diamondContractAddress = diamondStructs[diamondID][0][0];
        let diamondTokenID = diamondStructs[diamondID][0][3];
        //Ignore multiple tokenIDs for now
        diamondTokenID = ethers.BigNumber.from(diamondTokenID[0]).toString();
        if (diamondStructs[diamondID][0][2] === 0) {
          //ERC721
          diamondERC721s[diamondID] = [diamondContractAddress, diamondTokenID];
        } else if (diamondStructs[diamondID][0][2] === 1) {
          diamondERC1155s[diamondID] = [diamondContractAddress, diamondTokenID];
        }
        diamondIDMatch[[diamondContractAddress, diamondTokenID]] = diamondID;
        //Ignore ERC20 and ETH for now
      }
      //Compare to ERC721 and ERC1155s in vault
      //Find corresponding metadata for each NFTs
      if (erc721InVault) {
        for (let key in diamondERC721s) {
          const value = diamondERC721s[key];
          const nftArray = [
            value[0], //Contract address
            value[1], //Token ID
            erc721InVault.filter((obj) => {
              return (
                obj.contract_address.toLowerCase() === value[0].toLowerCase() &&
                obj.token_id === value[1]
              );
            }),
          ];
          const diamondID = diamondIDMatch[[value[0], value[1]]];
          setDiamondAssets((prevState) => ({
            ...prevState,
            [diamondID]: nftArray,
          }));
        }
      }
      if (erc1155InVault) {
        for (let key in diamondERC1155s) {
          const value = diamondERC1155s[key];
          const nftArray = [
            value[0], //Contract address
            value[1], //Token ID
            erc1155InVault.filter((obj) => {
              return (
                obj.contract_address.toLowerCase() === value[0].toLowerCase() &&
                obj.token_id === value[1]
              );
            }),
          ];
          const diamondID = diamondIDMatch[[value[0], value[1]]];
          setDiamondAssets((prevState) => ({
            ...prevState,
            [diamondID]: nftArray,
          }));
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [structLoading, erc721InVault, erc1155InVault]);
  //   Load UI Components
  const DiamondObjects = () => {
    if (structLoading) {
      return <div></div>;
    } else {
      return Object.entries(diamondAssets).map(([diamondID, nftArray]) => {
        const newArray = [diamondID]
          .concat(nftArray)
          .concat([diamondListDict[diamondID]]);
        //Only show those whose status is diamondhanding
        if (diamondListDict[diamondID][4] === 0) {
          let metadata = nftArray[2][0];
          return (
            <Grid item xs={7} sm={6} md={5} lg={4} xl={3} key={diamondID}>
              <Card
                sx={{
                  // width: "240px",
                  // height: "340px",
                  width: "100%",
                  maxWidth: "240px",
                  marginLeft: "auto",
                  marginRight: "auto",
                  backgroundColor: "white",
                  borderRadius: "20px",
                }}
              >
                <Link
                  key={diamondID}
                  to={"/diamond-asset"}
                  state={{
                    asset: newArray,
                    isCurrentUsers:
                      specified_address === address || !specified_address,
                  }}
                  style={{ textDecoration: "none" }}
                >
                  <Box sx={{ height: { xs: "200px", sm: "240px" } }}>
                    <CardMedia
                      component="img"
                      className="NFT-Image-Start"
                      image={
                        metadata.image_url
                          ? metadata.image_url
                          : metadata.extra_metadata.image_original_url
                          ? ipfsToUrl(
                              metadata.extra_metadata.image_original_url
                            )
                            ? ipfsToUrl(
                                metadata.extra_metadata.image_original_url
                              )
                            : NoImage
                          : NoImage
                      }
                      onError={({ currentTarget }) => {
                        currentTarget.onerror = null; // prevents looping
                        currentTarget.src = NoImage;
                      }}
                      alt="Image Not Available"
                    />
                  </Box>
                  <CardContent>
                    <p className="nft-collection-name">
                      {metadata.collection.name
                        ? metadata.collection.name
                        : metadata.contract_address.substring(0, 6) +
                          "..." +
                          metadata.contract_address.substring(36)}
                    </p>
                    <p className="nft-name">
                      {metadata.name
                        ? metadata.name
                        : metadata.collection.name
                        ? metadata.collection.name + " " + metadata.token_id
                        : "Metadata Unavailable"}
                    </p>
                  </CardContent>
                </Link>
              </Card>
            </Grid>
          );
        } else {
          return null;
        }
      });
    }
  };

  let noNFTsMessage = "You're currently not diamond-handing any NFTs!";
  let noNFTsMessage2 = "This address is currently not diamond-handing any NFTs";

  return (
    <>
      <Body>
        <div className="grid-wave-container-2">
          <div className="grid-wave w-full h-full bg-blue-1"></div>
          <div className="grid-wave-content">
            <NavBarHome />
            <h2
              hidden={address !== "" || specified_address}
              style={{
                marginTop: "auto",
                marginBottom: "auto",
                textAlign: "center",
              }}
            >
              Please connect your wallet to get started!
            </h2>
            {address === "" && !specified_address ? null : (
              <>
                <div hidden={!structLoading}>
                  <Box
                    sx={{
                      width: "80%",
                      height: "70vh",
                      maxWidth: "1200px",
                      textAlign: "center",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      marginX: "auto",
                      marginY: "auto",
                    }}
                  >
                    <CircularIndeterminate size={80} />
                  </Box>
                </div>
                <div hidden={structLoading && !isEmptyList}>
                  <div hidden={isEmptyList}>
                    <Box
                      sx={{
                        width: "80vw",
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-between",
                        marginLeft: "auto",
                        marginRight: "auto",
                      }}
                    >
                      <Box
                        sx={{
                          width: "300px",
                        }}
                      ></Box>
                      <Box
                        sx={{
                          width: "80vw",
                        }}
                      >
                        <Avatar
                          alt="Profile"
                          src={iconURL}
                          sx={{
                            width: "80px",
                            height: "80px",
                            marginLeft: "auto",
                            marginRight: "auto",
                          }}
                        />
                        <h2 className="walletAddress">{displayAddress}</h2>
                      </Box>
                      <Box
                        sx={{
                          width: "300px",
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "flex-end",
                        }}
                      >
                        <Tooltip title="Display Current or All-Time Stats">
                          <FormControlLabel
                            control={
                              <StatsSwitch
                                onChange={(event) => {
                                  if (event.target.checked) {
                                    setIsAllTime(true);
                                  } else {
                                    setIsAllTime(false);
                                  }
                                }}
                              />
                            }
                            label={
                              <p className="stats-switch-label">
                                {isAllTime ? "All-Time" : "Current"}
                              </p>
                            }
                          />
                        </Tooltip>
                      </Box>
                    </Box>

                    <Box
                      sx={{
                        display: "flex",
                        width: { xs: "95%", sm: "80%" },
                        justifyContent: "center",
                        marginLeft: "auto",
                        marginRight: "auto",
                      }}
                    >
                      <Grid container>
                        <Grid item xs={4}>
                          {isAllTime ? (
                            <h2 className="statsLabel">
                              # of Diamond-Handed NFTs:
                              <br />
                              {Object.keys(profileStats).length === 0
                                ? "N/A"
                                : profileStats[0].all_time_diamond_count}
                            </h2>
                          ) : (
                            <h2 className="statsLabel">
                              # of Diamond-Handed NFTs: <br />
                              {Object.keys(profileStats).length === 0
                                ? "N/A"
                                : Number(
                                    profileStats[0].current_diamond_count
                                  ) === 0
                                ? "N/A"
                                : profileStats[0].current_diamond_count}
                            </h2>
                          )}
                        </Grid>
                        <Grid item xs={4}>
                          {isAllTime ? (
                            <h2 className="statsLabel">
                              Longest Diamond-Hand Duration:
                              <br />
                              {Object.keys(profileStats).length === 0
                                ? "N/A"
                                : secondsToString(
                                    profileStats[0].all_time_diamond_duration
                                  )}
                            </h2>
                          ) : (
                            <h2 className="statsLabel">
                              Longest Diamond-Hand Duration: <br />
                              {Object.keys(profileStats).length === 0
                                ? "N/A"
                                : Number(
                                    profileStats[0]
                                      .current_longest_diamond_duration
                                  ) === 0
                                ? "N/A"
                                : secondsToString(
                                    profileStats[0]
                                      .current_longest_diamond_duration
                                  )}
                            </h2>
                          )}
                        </Grid>
                        <Grid item xs={4}>
                          {isAllTime ? (
                            <h2 className="statsLabel">
                              Top Diamond-Handed Collection: <br />
                              {Object.keys(personalCollectionsAllTime)
                                .length === 0
                                ? "N/A"
                                : personalCollectionsAllTime[0]
                                    .collection_name !== ""
                                ? personalCollectionsAllTime[0].collection_name
                                : personalCollectionsAllTime[0].collection_address.substring(
                                    0,
                                    6
                                  ) +
                                  "..." +
                                  personalCollectionsAllTime[0].collection_address.substring(
                                    36
                                  )}
                            </h2>
                          ) : (
                            <h2 className="statsLabel">
                              Top Diamond-Handed Collection: <br />
                              {Object.keys(personalCollectionsCurrent)
                                .length === 0
                                ? "N/A"
                                : personalCollectionsCurrent[0]
                                    .collection_name !== ""
                                ? personalCollectionsCurrent[0].collection_name
                                : personalCollectionsCurrent[0].collection_address.substring(
                                    0,
                                    6
                                  ) +
                                  "..." +
                                  personalCollectionsCurrent[0].collection_address.substring(
                                    36
                                  )}
                            </h2>
                          )}
                        </Grid>
                      </Grid>
                    </Box>
                  </div>
                  <Box
                    sx={{
                      justifyContent: "space-between",
                      display: isEmptyList ? "none" : "flex",
                      width: "60vw",
                      marginLeft: "auto",
                      marginRight: "auto",
                    }}
                  >
                    <Box sx={{ width: "100px" }}></Box>
                    <h2
                      style={{
                        textAlign: "center",
                        // display: isEmptyList ? "none" : "block",
                        fontSize: "32px",
                      }}
                    >
                      Current Diamond-Hands
                    </h2>
                    <Box
                      sx={{
                        width: "100px",
                        display: "flex",
                      }}
                    >
                      {address === "" ||
                      (specified_address &&
                        specified_address !== address) ? null : (
                        <Box sx={{ display: "flex" }}>
                          <Tooltip title="Go to your Vault">
                            <IconButton
                              size="large"
                              aria-label="Go to Vault"
                              aria-controls="menu-appbar"
                              aria-haspopup="true"
                              onClick={() => {
                                let url =
                                  "https://" + process.env.REACT_APP_ENV ===
                                  "sandbox"
                                    ? "goerli."
                                    : "" +
                                      "etherscan.io/address/" +
                                      vaultAddress;
                                window.open(url);
                              }}
                              sx={{
                                height: "60px",
                                width: "60px",
                                marginTop: "auto",
                                marginBottom: "auto",
                              }}
                            >
                              <Launch sx={{ color: "#93a2a9" }}></Launch>
                            </IconButton>
                          </Tooltip>
                          <Tooltip title="Check Airdrops">
                            <IconButton
                              size="large"
                              aria-label="Check Airdrops"
                              aria-controls="menu-appbar"
                              aria-haspopup="true"
                              onClick={() => {
                                let url =
                                  "https://diamond-hand.me/vault-airdrop";
                                window.open(url);
                              }}
                              sx={{
                                height: "60px",
                                width: "60px",
                                marginTop: "auto",
                                marginBottom: "auto",
                              }}
                            >
                              <Inventory sx={{ color: "#93a2a9" }}></Inventory>
                            </IconButton>
                          </Tooltip>
                        </Box>
                      )}
                    </Box>
                  </Box>
                  <Box
                    sx={{
                      display:
                        isEmptyList && hasFetched && !structLoading
                          ? "flex"
                          : "none",
                      justifyContent: "center",
                    }}
                  >
                    <h2
                      style={{
                        textAlign: "center",
                      }}
                    >
                      {address === specified_address || !specified_address
                        ? noNFTsMessage
                        : noNFTsMessage2}
                    </h2>
                    <Box
                      sx={{
                        display: "flex",
                      }}
                    >
                      {address === "" ||
                      (specified_address && specified_address !== address) ||
                      vaultAddress === "" ? null : (
                        <Box sx={{ display: "flex" }}>
                          <Tooltip title="Go to your Vault">
                            <IconButton
                              size="large"
                              aria-label="Go to Vault"
                              aria-controls="menu-appbar"
                              aria-haspopup="true"
                              onClick={() => {
                                let url =
                                  "https://" + process.env.REACT_APP_ENV ===
                                  "sandbox"
                                    ? "goerli."
                                    : "" +
                                      "etherscan.io/address/" +
                                      vaultAddress;
                                window.open(url);
                              }}
                              sx={{
                                height: "60px",
                                width: "60px",
                                marginTop: "auto",
                                marginBottom: "auto",
                              }}
                            >
                              <Launch sx={{ color: "#93a2a9" }}></Launch>
                            </IconButton>
                          </Tooltip>
                          <Tooltip title="Check Airdrops">
                            <IconButton
                              size="large"
                              aria-label="Check Airdrops"
                              aria-controls="menu-appbar"
                              aria-haspopup="true"
                              onClick={() => {
                                let url =
                                  "https://diamond-hand.me/vault-airdrop";
                                window.open(url);
                              }}
                              sx={{
                                height: "60px",
                                width: "60px",
                                marginTop: "auto",
                                marginBottom: "auto",
                              }}
                            >
                              <Inventory sx={{ color: "#93a2a9" }}></Inventory>
                            </IconButton>
                          </Tooltip>
                        </Box>
                      )}
                    </Box>
                  </Box>
                  <Box
                    sx={{
                      width: "80%",
                      height: "100%",
                      minHeight: "60vh",
                      background: "#1D335F",
                      display: isEmptyList ? "none" : "flex",
                      borderRadius: "20px",
                      marginLeft: "auto",
                      marginRight: "auto",
                    }}
                  >
                    <Grid
                      container
                      direction="row"
                      justifyContent="center"
                      alignItems="center"
                      rowSpacing={3}
                      columnSpacing={1}
                      sx={{ paddingY: "20px" }}
                    >
                      <DiamondObjects />
                    </Grid>
                  </Box>
                </div>
              </>
            )}
            <Footer></Footer>
          </div>
        </div>
      </Body>
    </>
  );
}

export default Profile;
