import React, { useEffect, useState, Fragment } from "react";

import { BigNumber, ethers } from "ethers";
import Web3 from "web3-utils";

import ReactHtmlParser from "react-html-parser";

//Components
import { Body } from "../components";
import NavBarHome from "../components/Home/NavBarHome";
import Footer from "../components/Footer";
import {
  Grid,
  Box,
  Card,
  CardMedia,
  CardContent,
  Button,
  Dialog,
  DialogContent,
  TextField,
  Tabs,
  Tab,
} from "@mui/material";
import PropTypes from "prop-types";

import ClaimAlert from "../components/Modals/ClaimAlert";
import ErrorAlert from "../components/Modals/ErrorAlert";
import CircularIndeterminate from "../components/CircularIndeterminate";

import { useAccount, useProvider, useSigner, useNetwork } from "wagmi";
import { Contract } from "@ethersproject/contracts";
import { contractFactoryAbi, contractFactoryAddress } from "./SpecificNFT";
import { vaultAbi } from "./SpecificNFT";

//Extensions
import useWindowDimensions from "../hooks/useWindowDimensions";

import NoImage from "../images/no-image.png";
import { startGrid } from "../components/Styles/GridWave";

function ERC20TextField({ checkERC20, vaultAddress }) {
  const [erc20Address, setERC20Address] = useState("");

  return (
    <>
      <TextField
        id="outlined-number"
        label="ERC20 Contract Address"
        type="text"
        InputLabelProps={{
          shrink: true,
        }}
        value={erc20Address}
        onChange={(event) => {
          let newValue = event.target.value;
          setERC20Address(newValue);
        }}
        sx={{
          input: { color: "black" },
          label: { color: "black" },
          width: {
            xs: "80%",
            sm: "60%",
            md: "50%",
            lg: "30%",
            xl: "20%",
          },
        }}
      />
      <br></br>
      <Button
        onClick={() => {
          checkERC20(vaultAddress, erc20Address);
        }}
        disabled={erc20Address === ""}
        sx={{
          color: "white",
          textTransform: "none",
          background: "#08121b",
          "&:hover": {
            backgroundColor: "black",
          },
          marginTop: "20px",
          marginLeft: "auto",
          marginRight: "auto",
          width: 140,
          fontSize: 21,
          height: 50,
          fontFamily: "Poppins",
          borderRadius: "20px",
        }}
      >
        Check
      </Button>
    </>
  );
}

function Airdrops() {
  const [address, setAddress] = useState("");
  const provider = useProvider();
  const { data: signer } = useSigner();

  const [vaultAddress, setVaultAddress] = useState("");
  const [erc721InVault, setERC721InVault] = useState(false);
  const [erc1155InVault, setERC1155InVault] = useState(false);
  const [ethInVault, setETHInVault] = useState(false);

  //From diamond-hand contract
  const [diamondERC721s, setDiamondERC721s] = useState([]);
  const [diamondERC1155s, setDiamondERC1155s] = useState([]);
  const [diamondERC20s, setDiamondERC20s] = useState([]);
  const [diamondETHs, setDiamondETHs] = useState([]);

  //Filtered results after comparisons
  const [filteredERC721s, setFilteredERC721s] = useState(false);
  const [filteredERC1155s, setFilteredERC1155s] = useState(false);
  const [filteredETHs, setFilteredETHs] = useState(false);

  //When the user selects an asset to claim
  const [selectedAssetType, setSelectedAssetType] = useState(false);
  const [selectedImageURL, setSelectedImageURL] = useState("");
  const [selectedContractAddress, setSelectedContractAddress] = useState(false);
  const [selectedTokenID, setSelectedTokenID] = useState(false);
  const [selectedBalanceToClaim, setSelectedBalanceToClaim] = useState(false);
  const [selectedTokenSymbol, setSelectedTokenSymbol] = useState(false);
  const [selectedTokenName, setSelectedTokenName] = useState(false);

  //For transaction UI
  const [startTransaction, setStartTransaction] = useState(false);
  const [transactionSuccessful, setTransactionSuccessful] = useState(false);
  const [transactionTitle, setTransactionTitle] = useState("");

  //For displaying alerts
  const [alertTitle, setAlertTitle] = useState("");
  const [alertMessage, setAlertMessage] = useState("");
  const [showingAlert, setShowingAlert] = useState(false);
  const [chainError, setChainError] = useState(false);

  //For Tabs
  const [tabValue, setTabValue] = React.useState(0);
  const { width } = useWindowDimensions();

  //Loading status
  const [hasFetched, setHasFetched] = useState(false);
  const [noVault, setNoVault] = useState(false);

  //Load more
  const [currentFetchCursor, setCurrentFetchCursor] = useState("");
  const [oldFetchData, setOldFetchData] = useState([]);

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  //Wallet
  const account = useAccount({
    onConnect({ address }) {
      setAddress(address);
    },
    onDisconnect() {
      setAddress("");
    },
  });

  useEffect(() => {
    //Make sure account updates
    if (account.isConnected) {
      setAddress(account.address);
    } else if (account.isDisconnected) {
      setAddress("");
    }
  }, [account]);

  const { chain, chains } = useNetwork();
  useEffect(() => {
    if (account.isConnected) {
      //Check if chain is supported
      if (chain.unsupported) {
        //Disable buttons
        setChainError(true);
      } else {
        setChainError(false);
      }
    }
  }, [chain, account]);

  //Gridwave Animation
  useEffect(() => {
    startGrid();
  }, []);

  //Get vault address
  useEffect(() => {
    if (address !== "") {
      getVaultAddress();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [address]);

  //Load more NFT if necessary
  useEffect(() => {
    if (vaultAddress !== "") {
      fetchNFTs(vaultAddress);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentFetchCursor, vaultAddress]);

  //Compare Assets
  useEffect(() => {
    if (erc721InVault.length > 0) {
      compareERC721();
    }
    if (erc1155InVault.length > 0) {
      compareERC1155();
    }
    if (ethInVault > 0) {
      compareETH();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    erc721InVault,
    erc1155InVault,
    ethInVault,
    diamondERC721s,
    diamondERC1155s,
    diamondETHs,
  ]);

  //For claim alert
  const [isOpen, setIsOpen] = useState(false);
  const [claimTitle, setClaimTitle] = useState(
    "You've received an airdrop to your vault!"
  );

  function handleError(error) {
    //Check if it's user rejecting transaction
    if (error.code === "ACTION_REJECTED") {
      //Ignore
      //Closing step dialog
      setStartTransaction(false);
    } else {
      let extractedError = error;
      if (error.error) {
        extractedError = error.error;
      }
      // if (!isObject(error)) {
      //   extractedError = extractJSON(String(error))[0];
      // }
      setAlertTitle("Error");
      setAlertMessage(extractedError.message);
      setShowingAlert(true);
      //Closing step dialog
      setStartTransaction(false);
      window.scrollTo(0, 0);
    }
  }

  function updateOpen(openStatus) {
    setIsOpen(openStatus);
  }
  async function startClaim() {
    //check for network error
    if (chainError) {
      setAlertTitle("Error");
      setAlertMessage(
        "Please switch your wallet to the " + chains[0].name + " network!"
      );
      setShowingAlert(true);
      //Closing step dialog
      setStartTransaction(false);
      window.scrollTo(0, 0);
      return;
    }
    setStartTransaction(true);
    setTransactionTitle("Claiming Airdrop");

    const diamondContract = new Contract(vaultAddress, vaultAbi, signer);
    //call different functions depending on asset type
    if (selectedAssetType === "ERC721") {
      await diamondContract
        .withdrawERC721(selectedContractAddress, selectedTokenID)
        .then(
          (receipt) => {
            // This is entered if the transaction receipt indicates success
            finishClaim(receipt);
          },
          (error) => {
            handleError(error);
          }
        );
    } else if (selectedAssetType === "ERC1155") {
      await diamondContract
        .withdrawERC1155(selectedContractAddress, selectedTokenID)
        .then(
          (receipt) => {
            // This is entered if the transaction receipt indicates success
            finishClaim(receipt);
          },
          (error) => {
            handleError(error);
          }
        );
    } else if (selectedAssetType === "ERC20") {
      await diamondContract.withdrawERC20(selectedContractAddress).then(
        (receipt) => {
          // This is entered if the transaction receipt indicates success
          finishClaim(receipt);
        },
        (error) => {
          handleError(error);
        }
      );
    } else {
      await diamondContract.withdrawETH().then(
        (receipt) => {
          // This is entered if the transaction receipt indicates success
          finishClaim(receipt);
        },
        (error) => {
          handleError(error);
        }
      );
    }
  }

  async function finishClaim(claimHash) {
    await claimHash.wait().then(
      (receipt) => {
        // This is entered if the transaction receipt indicates success
        setTransactionSuccessful(true);
        setTransactionTitle(
          "Airdrop claim successful! See <a href=https://" +
            (process.env.REACT_APP_ENV === "sandbox" ||
            process.env.REACT_APP_ENV === "dev"
              ? "goerli."
              : "") +
            "etherscan.io/tx/" +
            claimHash.hash +
            "> transaction </a>"
        );
      },
      (error) => {
        handleError(error);
      }
    );
  }

  async function getVaultAddress() {
    let walletProvider = provider;
    const diamondFactoryContract = new Contract(
      contractFactoryAddress,
      contractFactoryAbi,
      walletProvider
    );

    //Get the vault address of this wallet
    await diamondFactoryContract.getVaultAddresses(address).then(
      async (vaultAddress) => {
        if (vaultAddress[0] !== ethers.constants.AddressZero) {
          setVaultAddress(vaultAddress[0]);
          //fetch NFTs
          fetchNFTs(vaultAddress[0]);
          fetchETH(vaultAddress[0]);
          fetchDiamondAssets(vaultAddress[0]);
        }
      },
      (error) => {
        //no vault
        setHasFetched(true);
        setNoVault(true);
      }
    );
  }

  function fetchNFTs(address) {
    let pageKey = currentFetchCursor;
    fetch(
      (process.env.REACT_APP_ENV === "production" ||
      process.env.REACT_APP_ENV === "sandbox"
        ? process.env.REACT_APP_HOST
        : "http://localhost:3001") +
        "/get_nfts_of_address?" +
        new URLSearchParams({ address, pageKey })
    )
      .then((response) => response.json())
      .then((response) => {
        //Merge data with old data and set it as new
        let newData = oldFetchData.concat(response.ownedNfts);
        setOldFetchData(newData);
        //Check if response exceeds page limit
        if (response.pageKey) {
          //Keep fetching until all received all metadata
          setCurrentFetchCursor(response.pageKey);
        } else {
          //separate erc721 from erc1155
          let filteredERC1155 = newData.filter((item) => {
            return item.id.tokenMetadata.tokenType === "ERC1155";
          });
          setERC1155InVault(filteredERC1155);
          let filteredERC721 = newData.filter((item) => {
            return item.id.tokenMetadata.tokenType === "ERC721";
          });
          setERC721InVault(filteredERC721);
          // setHasFetched(true);
          setOldFetchData(newData);
        }
      })
      .catch((error) => {
        console.log("error", error);
        setHasFetched(true);
      });
  }

  function checkERC20(address, tokenAddress) {
    //check if address is valid
    if (!ethers.utils.isAddress(tokenAddress)) {
      setAlertTitle("Error");
      setAlertMessage("Invalid Token Address");
      setShowingAlert(true);
      return;
    }
    if (chainError) {
      setAlertTitle("Error");
      setAlertMessage("Please change your network to " + chains[0].name + "!");
      setShowingAlert(true);
      return;
    }
    fetch(
      (process.env.REACT_APP_ENV === "production" ||
      process.env.REACT_APP_ENV === "sandbox"
        ? process.env.REACT_APP_HOST
        : "http://localhost:3001") +
        "/get_erc20s_of_address?" +
        new URLSearchParams({ address, tokenAddress })
    )
      .then((response) => response.json())
      .then((response) => {
        // Get balance
        let balance = response["result"]["tokenBalances"][0].tokenBalance;
        if (
          balance !==
          "0x0000000000000000000000000000000000000000000000000000000000000000"
        ) {
          //get metadata
          fetch(
            (process.env.REACT_APP_ENV === "production" ||
            process.env.REACT_APP_ENV === "sandbox"
              ? process.env.REACT_APP_HOST
              : "http://localhost:3001") +
              "/get_erc20_metadata?" +
              new URLSearchParams({ tokenAddress })
          )
            .then((metadata) => metadata.json())
            .then((metadata) => {
              metadata = metadata["result"];
              // Compute token balance in human-readable format
              let originalBalance = balance;
              balance = balance / Math.pow(10, metadata["decimals"]);
              balance = balance.toFixed(2);

              //check if balance is > diamond-handed amount
              if (Object.keys(diamondERC20s).length === 0) {
                //none diamond-handed
                setSelectedAssetType("ERC20");
                setSelectedContractAddress(tokenAddress);
                setSelectedImageURL(metadata["logo"] ? metadata["logo"] : "");
                setSelectedTokenID(false);
                setClaimTitle("Token Airdrop Found!");
                updateOpen(true);
                setSelectedBalanceToClaim(balance);
                setSelectedTokenSymbol(
                  metadata["symbol"] ? "(" + metadata["symbol"] + ")" : false
                );
                setSelectedTokenName(
                  metadata["name"] ? metadata["name"] : false
                );
              } else {
                //Compare ERC20
                let res = Object.values(diamondERC20s).find((element) => {
                  return element.find((subElement) => {
                    return (
                      subElement.contractAddress.toLowerCase() ===
                      tokenAddress.toLowerCase()
                    );
                  });
                });
                if (res) {
                  //result isn't undefined, meaning element was found.
                  //loop through and sum up balances of res
                  let totalBalance = 0;
                  //for each res, sum up amount
                  for (let i = 0; i < res.length; i++) {
                    totalBalance += parseFloat(res[i].quantity.toString());
                  }
                  if (
                    Web3.fromWei(originalBalance, "ether") >
                    Web3.fromWei(totalBalance.toString(), "ether")
                  ) {
                    //have more in the vault then amount diamond-handed
                    setSelectedAssetType("ERC20");
                    setSelectedContractAddress(tokenAddress);
                    setSelectedImageURL(
                      metadata["logo"] ? metadata["logo"] : ""
                    );
                    setClaimTitle("Token Airdrop Found!");
                    setSelectedTokenID(false);
                    updateOpen(true);
                    setSelectedBalanceToClaim(
                      Web3.fromWei(
                        (originalBalance - totalBalance).toString(),
                        "ether"
                      )
                    );
                    setSelectedTokenSymbol(
                      metadata["symbol"]
                        ? "(" + metadata["symbol"] + ")"
                        : false
                    );
                    setSelectedTokenName(
                      metadata["name"] ? metadata["name"] : false
                    );
                  } else {
                    setAlertTitle("Error");
                    setAlertMessage("Airdrop Not Found");
                    setShowingAlert(true);
                  }
                } else {
                  //element wasn't found in diamond-hand
                  setSelectedAssetType("ERC20");
                  setSelectedContractAddress(tokenAddress);
                  setSelectedImageURL(metadata["logo"] ? metadata["logo"] : "");
                  setClaimTitle("Token Airdrop Found!");
                  setSelectedTokenID(false);
                  updateOpen(true);
                  setSelectedBalanceToClaim(
                    Web3.fromWei(originalBalance, "ether")
                  );
                  setSelectedTokenSymbol(
                    metadata["symbol"] ? "(" + metadata["symbol"] + ")" : false
                  );
                  setSelectedTokenName(
                    metadata["name"] ? metadata["name"] : false
                  );
                }
              }
            })
            .catch((error) => {
              console.log("error", error);
              setAlertTitle("Error");
              setAlertMessage("Error Fetching Token Data");
              setShowingAlert(true);
            });
        } else {
          //token balance is 0
          setAlertTitle("Error");
          setAlertMessage("Airdrop Not Found");
          setShowingAlert(true);
        }
      })
      .catch((error) => {
        console.log("error", error);
        setAlertTitle("Error");
        setAlertMessage("Error Fetching Token Data");
        setShowingAlert(true);
      });
  }

  async function fetchETH(address) {
    fetch(
      (process.env.REACT_APP_ENV === "production" ||
      process.env.REACT_APP_ENV === "sandbox"
        ? process.env.REACT_APP_HOST
        : "http://localhost:3001") +
        "/get_eth_balance?" +
        new URLSearchParams({ address })
    )
      .then((response) => response.json())
      .then((response) => {
        const balance = Web3.fromWei(response.hex, "ether");
        setETHInVault(balance);
      })
      .catch((error) => console.log("error", error));
  }

  //Fetch Diamond-Handed Assets
  async function fetchDiamondAssets(vaultAddress) {
    fetch(
      (process.env.REACT_APP_ENV === "production" ||
      process.env.REACT_APP_ENV === "sandbox"
        ? process.env.REACT_APP_HOST
        : "http://localhost:3001") + "/get_diamond_nfts",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          vaultAddress,
          vaultAbi,
        }),
      }
    )
      .then((response) => response.json())
      .then((response) => {
        const filteredDiamondList = response.filteredDiamondList;
        const diamondStructs = response.diamondStructs;
        //Filter out erc721, erc1155, erc20, and eth from diamondStructs

        let erc721FromList = [];
        let erc1155FromList = [];
        let erc20FromList = [];
        let ethFromList = [];

        for (let i = 0; i < filteredDiamondList.length; i++) {
          const diamondID = ethers.BigNumber.from(
            filteredDiamondList[i][0]
          ).toNumber();
          if (diamondStructs[diamondID][0][2] === 0) {
            //ERC721
            erc721FromList.push(diamondStructs[diamondID]);
          } else if (diamondStructs[diamondID][0][2] === 1) {
            erc1155FromList.push(diamondStructs[diamondID]);
          } else if (diamondStructs[diamondID][0][2] === 2) {
            erc20FromList.push(diamondStructs[diamondID]);
          } else {
            ethFromList.push(diamondStructs[diamondID]);
          }
        }
        setDiamondERC721s(erc721FromList);
        setDiamondERC1155s(erc1155FromList);
        setDiamondERC20s(erc20FromList);
        setDiamondETHs(ethFromList);
        setHasFetched(true);
      })
      .catch((error) => {
        console.error(error);
      });
  }

  function compareERC721() {
    if (diamondERC721s.length === 0) {
      //No need to filter
      setFilteredERC721s(erc721InVault);
    } else {
      //Compare ERC721
      let filteredERC721 = erc721InVault.filter((el) => {
        let res = diamondERC721s.find((element) => {
          return element.find((subElement) => {
            return (
              BigNumber.from(subElement[3][0]).toHexString() ===
                BigNumber.from(el.id.tokenId).toHexString() &&
              subElement[0].toLowerCase() === el.contract.address.toLowerCase()
            );
          });
        });
        if (res) {
          //result isn't undefined, meaning element was found.
          return false;
        } else {
          return true;
        }
      });
      setFilteredERC721s(filteredERC721);
    }
  }
  function compareERC1155() {
    //check if diamond-hand is empty
    if (diamondERC1155s.length === 0) {
      setFilteredERC1155s(erc1155InVault);
    } else {
      // Compare ERC1155
      let filteredERC1155 = [];
      for (const el of erc1155InVault) {
        //sum up amount of diamondERC1155 with same contract address and id, and check that it's less than total amount
        let res = diamondERC1155s.find((element) => {
          return element.find((subElement) => {
            return (
              BigNumber.from(subElement[3][0]).toHexString() ===
                BigNumber.from(el.id.tokenId).toHexString() &&
              subElement[0].toLowerCase() === el.contract.address.toLowerCase()
            );
          });
        });
        if (res) {
          //total diamond-handed amount
          let totalAmount = 0;
          //for each res, sum up amount
          for (let i = 0; i < res.length; i++) {
            totalAmount += parseInt(res[i][4].toString());
          }
          if (el.balance > totalAmount) {
            //add to list
            filteredERC1155.push(el);
          }
        } else {
          //element not found in diamondVault, add element
          filteredERC1155.push(el);
        }
      }
      setFilteredERC1155s(filteredERC1155);
    }
  }

  function compareETH() {
    if (diamondETHs.length === 0) {
      //No need to filter
      setFilteredETHs(ethInVault);
    } else {
      //Compare ETH
      //sum up amount of diamondETHs and subtract from total in vault
      let total = 0;
      for (let i = 0; i < diamondETHs.length; i++) {
        total += Number(
          Web3.fromWei(
            ethers.BigNumber.from(diamondETHs[i][0][4][0]).toString(),
            "ether"
          )
        );
      }
      setFilteredETHs(ethInVault - total);
    }
  }

  const ERC721Grid = Object.values(filteredERC721s).map((assets, index) => {
    return (
      <Grid item xs={12} sm={6} md={4} lg={3} xl={2} key={index}>
        <Card
          sx={{
            // width: "240px",
            height: "340px",
            width: "80%",
            maxWidth: "240px",
            marginLeft: "auto",
            marginRight: "auto",
            backgroundColor: "white",
            borderRadius: "10px",
            boxShadow: "0 16px 70px -12.125px rgba(0,0,0,0.3)",
            paddingBottom: "20px",
            cursor: "pointer",
          }}
          onClick={() => {
            setSelectedAssetType("ERC721");
            setSelectedImageURL(assets.media[0].gateway);
            setSelectedContractAddress(assets.contract.address);
            setSelectedTokenID(assets.id.tokenId);
            setClaimTitle("You've received an airdrop to your vault!");
            setSelectedBalanceToClaim(false);
            updateOpen(true);
            setSelectedTokenSymbol(false);
            setSelectedTokenName(false);
          }}
        >
          <Box sx={{ height: "240px" }}>
            <CardMedia
              component="img"
              className="NFT-Image-Start"
              image={
                assets.media[0].gateway ? assets.media[0].gateway : NoImage
              }
              onError={({ currentTarget }) => {
                currentTarget.onerror = null; // prevents looping
                currentTarget.src = NoImage;
              }}
              alt="NFT"
            />
          </Box>
          <CardContent>
            {/* <p className="nft-collection-name">{assets.title}</p> */}
            <p className="nft-name">{assets.metadata.name}</p>
          </CardContent>
        </Card>
      </Grid>
    );
  });
  const ERC1155Grid = Object.values(filteredERC1155s).map((assets, index) => {
    return (
      <Grid item xs={12} sm={6} md={4} lg={3} xl={2} key={index}>
        <Card
          sx={{
            // width: "240px",
            height: "340px",
            width: "80%",
            maxWidth: "240px",
            marginLeft: "auto",
            marginRight: "auto",
            backgroundColor: "white",
            borderRadius: "10px",
            boxShadow: "0 16px 70px -12.125px rgba(0,0,0,0.3)",
            paddingBottom: "20px",
            cursor: "pointer",
          }}
          onClick={() => {
            setSelectedAssetType("ERC1155");
            setSelectedImageURL(assets.media[0].gateway);
            setSelectedContractAddress(assets.contract.address);
            setSelectedTokenID(assets.id.tokenId);
            setClaimTitle("You've received an airdrop to your vault!");
            setSelectedBalanceToClaim(false);
            setSelectedTokenSymbol(false);
            setSelectedTokenName(false);
            updateOpen(true);
          }}
        >
          <Box sx={{ height: "240px" }}>
            <CardMedia
              component="img"
              className="NFT-Image-Start"
              image={
                assets.media[0].gateway ? assets.media[0].gateway : NoImage
              }
              onError={({ currentTarget }) => {
                currentTarget.onerror = null; // prevents looping
                currentTarget.src = NoImage;
              }}
              alt="NFT "
            />
          </Box>
          <CardContent>
            {/* <p className="nft-collection-name">{assets.title}</p> */}
            <p className="nft-name">{assets.metadata.name}</p>
          </CardContent>
        </Card>
      </Grid>
    );
  });

  function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
      </div>
    );
  }

  TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
  };

  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
    };
  }

  return (
    <>
      <Body>
        <div className="grid-wave-container-2">
          <div className="grid-wave w-full h-full bg-blue-1"></div>
          <div className="grid-wave-content">
            <NavBarHome />
            <ErrorAlert
              title={alertTitle}
              message={alertMessage}
              open={showingAlert}
              updateOpen={setShowingAlert}
            />
            <h1 className="stats_title">Airdrops</h1>
            <h2
              hidden={address !== ""}
              style={{
                marginTop: "auto",
                marginBottom: "auto",
                textAlign: "center",
              }}
            >
              Please connect your wallet to get started!
            </h2>
            <h2
              hidden={!noVault || !hasFetched}
              style={{
                marginTop: "auto",
                marginBottom: "auto",
                textAlign: "center",
              }}
            >
              You don't have a Diamond Vault yet!
            </h2>

            <Box
              sx={{
                width: "100%",
                bgcolor: "background.paper",
                visibility: address !== "" && !noVault ? "visible" : "hidden",
              }}
            >
              <Tabs
                value={tabValue}
                onChange={handleTabChange}
                centered={width >= 500}
                variant={width < 500 ? "scrollable" : "standard"}
              >
                <Tab label="ERC721 NFTs" {...a11yProps(0)} />
                <Tab label="ERC1155 NFTs" {...a11yProps(1)} />
                <Tab label="ERC20" {...a11yProps(2)} />
                <Tab label="ETH" {...a11yProps(3)} />
              </Tabs>
              <TabPanel value={tabValue} index={0}>
                <div hidden={hasFetched}>
                  <CircularIndeterminate />
                </div>
                <div hidden={!hasFetched}>
                  <Grid
                    container
                    direction="row"
                    justifyContent="center"
                    alignItems="center"
                    rowSpacing={3}
                    columnSpacing={1}
                  >
                    {Object.keys(filteredERC721s).length > 0 ? (
                      ERC721Grid
                    ) : (
                      <h2>No Airdrops Found</h2>
                    )}
                  </Grid>
                </div>
              </TabPanel>
              <TabPanel value={tabValue} index={1}>
                <Grid
                  container
                  direction="row"
                  justifyContent="center"
                  alignItems="center"
                  rowSpacing={3}
                  columnSpacing={1}
                >
                  {Object.keys(filteredERC1155s).length > 0 ? (
                    ERC1155Grid
                  ) : (
                    <h2>No Airdrops Found</h2>
                  )}
                </Grid>
              </TabPanel>
              <TabPanel value={tabValue} index={2}>
                <Box sx={{ textAlign: "center" }}>
                  <h2>
                    Enter the contract address of an ERC20 token to check if it
                    was airdropped:
                  </h2>
                  <ERC20TextField
                    checkERC20={checkERC20}
                    vaultAddress={vaultAddress}
                  />
                </Box>
              </TabPanel>
              <TabPanel value={tabValue} index={3}>
                <Box sx={{ textAlign: "center" }}>
                  <h2>Claimable ETH = {filteredETHs ? filteredETHs : 0}</h2>
                  <Button
                    onClick={() => {
                      setSelectedAssetType("ETH");
                      startClaim();
                    }}
                    disabled={Number(filteredETHs) === 0}
                    sx={{
                      color: "white",
                      textTransform: "none",
                      background: "#08121b",
                      "&:hover": {
                        backgroundColor: "black",
                      },
                      marginTop: "20px",
                      marginLeft: "auto",
                      marginRight: "auto",
                      width: 140,
                      fontSize: 21,
                      height: 50,
                      fontFamily: "Poppins",
                      borderRadius: "20px",
                    }}
                  >
                    Claim
                  </Button>
                </Box>{" "}
              </TabPanel>
            </Box>
            <ClaimAlert
              open={isOpen}
              updateOpen={updateOpen}
              title={claimTitle}
              imageURL={selectedImageURL}
              confirmLabel="Claim"
              declineLabel="Cancel"
              message={[
                <Fragment key={"confirmation"}>
                  <b>Would you like to claim this airdrop?</b>
                  <br></br>
                  <span className="claim_note">
                    <b>Contract Address:</b> {selectedContractAddress}
                    <br></br>
                    {selectedTokenID ? <b>Token ID: </b> : null}
                    {selectedTokenID
                      ? ethers.BigNumber.from(selectedTokenID).toString()
                      : null}
                    {selectedTokenName ? <b>Token: </b> : null}
                    {selectedTokenName ? selectedTokenName : null}{" "}
                    {selectedTokenSymbol ? selectedTokenSymbol : null}
                    <br></br>
                    {selectedBalanceToClaim ? <b>Claimable Balance: </b> : null}
                    {selectedBalanceToClaim ? selectedBalanceToClaim : null}
                  </span>
                </Fragment>,
              ]}
              onConfirm={startClaim}
              onDecline={() => {}}
            />
            <Dialog
              open={startTransaction}
              keepMounted
              // onClose={}
              aria-describedby="alert-dialog-slide-description"
            >
              <DialogContent>
                <div
                  style={{
                    textAlign: "center",
                  }}
                >
                  <h2>{ReactHtmlParser(transactionTitle)}</h2>
                  <div
                    style={{
                      display: "inline-flex",
                      alignItems: "center",
                    }}
                  >
                    <div hidden={transactionSuccessful}>
                      <CircularIndeterminate />
                    </div>
                    <div hidden={!transactionSuccessful}>
                      <Button
                        style={{
                          margin: "0 auto",
                          display: "flex",
                          textDecoration: "none",
                          textTransform: "none",
                          fontFamily: "Poppins",
                          fontStyle: "normal",
                          fontWeight: 400,
                          fontSize: "22px",
                          color: "white",
                          "&:hover": {
                            backgroundColor: "black",
                          },
                          backgroundColor: "black",
                          borderRadius: "30px",
                          paddingLeft: "15px",
                          paddingRight: "15px",
                        }}
                        href="/vault-airdrop"
                      >
                        Done!
                      </Button>
                    </div>
                  </div>
                </div>
              </DialogContent>
            </Dialog>

            <Footer></Footer>
          </div>
        </div>
      </Body>
    </>
  );
}

export default Airdrops;
