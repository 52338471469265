import erc20Abi from "./abis/erc20.json";
import diamondHandFactoryABI from "./abis/diamondHandFactory.json";
import diamondVaultABI from "./abis/diamondVault.json";
import diamondPassABI from "./abis/diamondpass.json";
import erc721Abi from "./abis/erc721.json";
import erc1155Abi from "./abis/erc1155.json";

const abis = {
  erc20: erc20Abi,
  diamondHandFactory: diamondHandFactoryABI,
  diamondVault: diamondVaultABI,
  diamondPass: diamondPassABI,
  erc721: erc721Abi,
  erc1155: erc1155Abi,
};

export default abis;
