import PropTypes from "prop-types";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
import Paper from "@mui/material/Paper";
import { visuallyHidden } from "@mui/utils";
import CircularIndeterminate from "./CircularIndeterminate";
import { useNavigate } from "react-router-dom";
import { useState, useEffect } from "react";

import { secondsToString } from "../extensions/secondsToString";

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

// This method is created for cross-browser compatibility, if you don't
// need to support IE11, you can use Array.prototype.sort() directly
function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

const currentHeadCells = [
  {
    id: "rank",
    numeric: true,
    disablePadding: false,
    label: "#",
  },
  {
    id: "unique_wallet",
    numeric: false,
    disablePadding: false,
    label: "Diamond-Handers (unique wallets)",
  },
  {
    id: "current_diamond_count",
    numeric: true,
    disablePadding: false,
    label: "# Diamond-Handed NFTs",
  },
  {
    id: "current_longest_diamond_duration",
    numeric: true,
    disablePadding: false,
    label: "Longest Diamond-Hand Duration",
  },
];

const allTimeHeadCells = [
  {
    id: "rank",
    numeric: true,
    disablePadding: false,
    label: "#",
  },
  {
    id: "unique_wallet",
    numeric: false,
    disablePadding: false,
    label: "Diamond-Handers (unique wallets)",
  },
  {
    id: "all_time_diamond_count",
    numeric: true,
    disablePadding: false,
    label: "# Diamond-Handed NFTs",
  },
  {
    id: "all_time_diamond_duration",
    numeric: true,
    disablePadding: false,
    label: "Longest Diamond-Hand Duration",
  },
];

function EnhancedTableHead(props) {
  const { order, orderBy, onRequestSort, isAllTime } = props;
  const createSortHandler = (property) => (event) => {
    if (property !== "unique_wallet" && property !== "rank") {
      onRequestSort(event, property);
    }
  };
  const [headCells, setHeadCells] = useState(currentHeadCells);

  useEffect(() => {
    if (isAllTime) {
      setHeadCells(allTimeHeadCells);
    } else {
      setHeadCells(currentHeadCells);
    }
  }, [isAllTime]);

  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? "right" : "left"}
            padding={headCell.disablePadding ? "none" : "normal"}
            sortDirection={orderBy === headCell.id ? order : false}
            sx={{
              backgroundColor: "#24272d",
              color: "white",
              fontFamily: "Poppins",
              // fontWeight: 600,
              fontSize: "16px",
              textAlign: "center",
              width: headCell.id === "rank" ? "30px" : "30%",
              cursor:
                headCell.id === "rank" || headCell.id === "unique_wallet"
                  ? "default"
                  : "pointer",
            }}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={createSortHandler(headCell.id)}
              style={{ color: "white" }}
              sx={{
                "&.Mui-active": {
                  color: "white",
                },
                "& .MuiTableSortLabel-icon": {
                  display:
                    headCell.id === "unique_wallet" || headCell.id === "rank"
                      ? "none"
                      : "block",
                  color: "white !important",
                },
                cursor:
                  headCell.id === "rank" || headCell.id === "unique_wallet"
                    ? "default"
                    : "pointer",
              }}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === "desc" ? "sorted descending" : "sorted ascending"}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  onRequestSort: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

export default function SpecificCollectionTable({ data, isAllTime }) {
  const [order, setOrder] = useState("desc");
  const [orderBy, setOrderBy] = useState("current_diamond_count");
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(20);
  const [rows, setRows] = useState(false);
  const [allRows, setAllRows] = useState(false);

  const [walletDict, setWalletDict] = useState({});
  let navigate = useNavigate();

  useEffect(() => {
    //Check for ENS in row of unique wallets, and update them as needed
    async function updateENS() {
      let parsedData = JSON.parse(data);
      if (parsedData) {
        for (let i = 0; i < parsedData.length; i++) {
          let walletAddress = parsedData[i].unique_wallet;
          if (walletAddress) {
            let result = await fetch(
              (process.env.REACT_APP_ENV === "production" ||
              process.env.REACT_APP_ENV === "sandbox"
                ? process.env.REACT_APP_HOST
                : "http://localhost:3001") +
                "/get_ens_name?" +
                new URLSearchParams({ walletAddress })
            )
              .then((response) => {
                if (response.status === 200) {
                  return response.text();
                } else {
                  throw new Error("fetch error");
                }
              })
              .catch((error) => {
                // console.log(error);
              });
            //If no ENS, abbreviate wallet address, set dict to match
            setWalletDict((prevState) => ({
              ...prevState,
              [walletAddress]:
                result === walletAddress
                  ? walletAddress.substring(0, 6) +
                    "..." +
                    walletAddress.substring(36)
                  : result,
            }));
          }
        }
      }
      setAllRows(parsedData);
    }
    updateENS();
  }, [data]);

  useEffect(() => {
    if (isAllTime) {
      //Change orderBy to counterpart
      if (orderBy === "current_diamond_count") {
        setOrderBy("all_time_diamond_count");
      } else if (orderBy === "current_longest_diamond_duration") {
        setOrderBy("all_time_diamond_duration");
      }
    } else {
      if (orderBy === "all_time_diamond_duration") {
        setOrderBy("current_longest_diamond_duration");
      } else if (orderBy === "all_time_diamond_count") {
        setOrderBy("current_diamond_count");
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAllTime]);

  useEffect(() => {
    //Filter rows based on isAllTime
    if (allRows) {
      if (isAllTime) {
        setRows(allRows);
      } else {
        const filteredRows = allRows.filter((el) => {
          if (Number(el.current_diamond_count) === 0) {
            //skip collections with 0 current diamond hands
            return false;
          }
          return true;
        });
        setRows(filteredRows);
      }
    }
  }, [isAllTime, allRows]);

  //Navigate to specific collection stats
  if (!rows) {
    return (
      <Box
        sx={{
          width: "80%",
          maxWidth: "1200px",
          textAlign: "center",
          marginX: "auto",
        }}
      >
        <CircularIndeterminate size={"3vw"} />
      </Box>
    );
  } else {
    function clickedRow(wallet_address) {
      navigate(`/profile/${wallet_address}`, {
        state: { address: wallet_address },
      });
    }

    const handleRequestSort = (event, property) => {
      const isAsc = orderBy === property && order === "asc";
      setOrder(isAsc ? "desc" : "asc");
      setOrderBy(property);
    };

    const handleChangePage = (event, newPage) => {
      setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
      setRowsPerPage(parseInt(event.target.value, 10));
      setPage(0);
    };

    // Avoid a layout jump when reaching the last page with empty rows.
    const emptyRows =
      page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

    return (
      <Box sx={{ width: "80%", maxWidth: "1200px", marginX: "auto" }}>
        <Paper sx={{ width: "100%", mb: 2 }}>
          <TableContainer>
            <Table
              sx={{ minWidth: 750 }}
              aria-labelledby="tableTitle"
              size={"medium"}
            >
              <EnhancedTableHead
                order={order}
                orderBy={orderBy}
                onRequestSort={handleRequestSort}
                rowCount={rows.length}
                isAllTime={isAllTime}
              />
              <TableBody>
                {/* if you don't need to support IE11, you can replace the `stableSort` call with:
                     rows.slice().sort(getComparator(order, orderBy)) */}
                {stableSort(rows, getComparator(order, orderBy))
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((row, index) => {
                    const labelId = `enhanced-table-checkbox-${index}`;

                    return (
                      <TableRow
                        hover
                        tabIndex={-1}
                        key={row.unique_wallet}
                        onClick={() => {
                          clickedRow(row.unique_wallet);
                        }}
                        className="statsTable"
                      >
                        <TableCell id={labelId} scope="row" padding="none">
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "center",
                            }}
                          >
                            <p
                              style={{
                                marginTop: "auto",
                                marginBottom: "auto",
                              }}
                            >
                              {page * rowsPerPage + index + 1}
                            </p>
                          </div>
                        </TableCell>
                        <TableCell
                          id={labelId}
                          scope="row"
                          padding="none"
                          align="center"
                        >
                          <p style={{ marginRight: "15px" }}>
                            {walletDict[row.unique_wallet]}
                          </p>
                        </TableCell>
                        <TableCell align="right">
                          {
                            <p
                              style={{
                                marginTop: "auto",
                                marginBottom: "auto",
                                marginLeft: "10px",
                              }}
                            >
                              {isAllTime
                                ? row.all_time_diamond_count
                                : row.current_diamond_count}
                            </p>
                          }
                        </TableCell>
                        <TableCell align="right">
                          <p
                            style={{
                              marginTop: "auto",
                              marginBottom: "auto",
                              marginLeft: "10px",
                            }}
                          >
                            {secondsToString(
                              isAllTime
                                ? row.all_time_diamond_duration
                                : row.current_longest_diamond_duration
                            )}
                          </p>
                        </TableCell>
                      </TableRow>
                    );
                  })}
                {emptyRows > 0 && (
                  <TableRow
                    style={{
                      height: 53 * emptyRows,
                    }}
                  >
                    <TableCell colSpan={6} />
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[]}
            component="div"
            count={Math.ceil(rows.length / rowsPerPage)}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Paper>
      </Box>
    );
  }
}
