//https://mui.com/components/app-bar/
import { useEffect, useState } from "react";
import MenuIcon from "../../images/menuIcon.png";
import {
  AppBar,
  Box,
  Toolbar,
  IconButton,
  Typography,
  Container,
  Avatar,
  Button,
  Tooltip,
  Grid,
  Drawer,
  List,
  Divider,
  ListItem,
} from "@mui/material";
// import { ConnectButton } from "@rainbow-me/rainbowkit";
import CustomConnectButton from "../WalletButton/CustomConnectButton";
import { useAccount } from "wagmi";

import logo from "../../images/logoBlack.png";
import blockies from "ethereum-blockies";

const pages = ["Start", "Stats", "Diamond Pass"];
//Linking each page to the route path
let pagesLink = {};
pagesLink[pages[0]] = "/start";
pagesLink[pages[1]] = "/stats";
pagesLink[pages[2]] = "/diamondpass";

//Passing in variables needed for wallet
function NavBarHome() {
  const [address, setAddress] = useState("");
  const [accountStatus, setAccountStatus] = useState("disconnected");
  const account = useAccount({
    onConnect({ address }) {
      setAddress(address);
      //generate icon
      const newIconURL = blockies
        .create({
          seed: address, // seed used to generate icon data, default: random
          size: 8, // width/height of the icon in blocks, default: 8
          scale: 16, // width/height of each block in pixels, default: 4
          // spotcolor: "#000", // each pixel has a 13% chance of being of a third color,
          // default: random. Set to -1 to disable it. These "spots" create structures
          // that look like eyes, mouths and noses.
        })
        .toDataURL();
      if (newIconURL !== iconURL) {
        setIconURL(newIconURL);
      }
    },
  });

  useEffect(() => {
    async function saveVisitorWallet() {
      await fetch(
        (process.env.REACT_APP_ENV === "production" ||
        process.env.REACT_APP_ENV === "sandbox"
          ? process.env.REACT_APP_HOST
          : "http://localhost:3001") + "/add_visitor",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            address: account.address,
          }),
        }
      );
    }

    if (accountStatus === "connecting" && account.status === "connected") {
      //From a disconnected state to connected (not through reconnects) --> save visitor wallet
      saveVisitorWallet();
    }

    //When user back buttons, or other react-router-dom links, and update is set, make sure to connect address
    if (account.isConnected) {
      setAddress(account.address);
    } else if (account.isDisconnected) {
      setAddress("");
    }
    //save account status for reconnecting check
    setAccountStatus(account.status);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [account]);

  const [iconURL, setIconURL] = useState("");

  useEffect(() => {
    if (address === "") {
      setIconURL("");
    } else {
      setIconURL(generateBlockies(address));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [address]);

  function generateBlockies(address) {
    const newIconURL = blockies
      .create({
        seed: address, // seed used to generate icon data, default: random
        size: 8, // width/height of the icon in blocks, default: 8
        scale: 16, // width/height of each block in pixels, default: 4
        // spotcolor: "#000", // each pixel has a 13% chance of being of a third color,
        // default: random. Set to -1 to disable it. These "spots" create structures
        // that look like eyes, mouths and noses.
      })
      .toDataURL();
    if (
      newIconURL ===
      "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAIAAAACACAYAAADDPmHLAAAAAXNSR0IArs4c6QAAAehJREFUeF7t2MERACAQwkDsv2idsY2sHZzJAzjb7rzsDxwCZNn/wwnQ5k+AOH8CEEAITDsgA6TxC4Fx/AQggB2g7YAM0OavBsb5E4AAdoC0AzJAGr8aGMdPAALYAdoOyABt/mpgnD8BCGAHSDsgA6Txq4Fx/AQggB2g7YAM0OavBsb5E4AAdoC0AzJAGr8aGMdPAALYAdoOyABt/mpgnD8BCGAHSDsgA6Txq4Fx/AQggB2g7YAM0OavBsb5E4AAdoC0AzJAGr8aGMdPAALYAdoOyABt/mpgnD8BCGAHSDsgA6Txq4Fx/AQggB2g7YAM0OavBsb5E4AAdoC0AzJAGr8aGMdPAALYAdoOyABt/mpgnD8BCGAHSDsgA6Txq4Fx/AQggB2g7YAM0OavBsb5E4AAdoC0AzJAGr8aGMdPAALYAdoOyABt/mpgnD8BCGAHSDsgA6Txq4Fx/AQggB2g7YAM0OavBsb5E4AAdoC0AzJAGr8aGMdPAALYAdoOyABt/mpgnD8BCGAHSDsgA6Txq4Fx/AQggB2g7YAM0OavBsb5E4AAdoC0AzJAGr8aGMdPAALYAdoOyABt/mpgnD8BCGAHSDsgA6Txq4Fx/AQggB2g7YAM0OavBsb5E4AAdoC0AzJAGr8aGMe/PQwNgAEnVeAsAAAAAElFTkSuQmCC"
    ) {
      return generateBlockies(address);
    } else {
      return newIconURL;
    }
  }

  return (
    <AppBar position="static" sx={{ bgcolor: "transparent" }} elevation={0}>
      <Container maxWidth="100%">
        <Toolbar disableGutters sx={{ justifyContent: "center" }}>
          <Grid
            container
            spacing={2}
            width="100%"
            justifyContent={"space-between"}
          >
            <Grid item xs={2} md={3} lg={4} xl={6} sx={{ my: 2 }}>
              <Button
                href="/"
                sx={{
                  "&:hover": {
                    backgroundColor: "transparent",
                  },
                }}
                disableRipple
                className="logo_navbar_home"
              >
                <img src={logo} className="logo_navbar_image" alt="logo" />
              </Button>
            </Grid>
            <Grid
              item
              xs={8}
              md={7}
              lg={6}
              display="flex"
              sx={{ justifyContent: "flex-end" }}
            >
              <Box
                sx={{
                  flexGrow: 2,
                  display: {
                    xs: "none",
                    md: "flex",
                  },
                  justifyContent: "flex-end",
                }}
              >
                {pages.map((page) => {
                  return (
                    <Button
                      key={page}
                      sx={{
                        my: 2,
                        color: "white",
                        display: "block",
                        textTransform: "none",
                      }}
                      style={{
                        fontFamily: "Poppins",
                        fontStyle: "normal",
                        fontWeight: 400,
                        fontSize: "20px",
                        lineHeight: "24px",
                        textAlign: "center",
                        color: "#93a2a9",
                        marginLeft: "min(5vw, 50px)",
                      }}
                      href={pagesLink[page]}
                    >
                      {page}
                    </Button>
                  );
                })}
              </Box>

              <Box
                sx={{
                  flexGrow: 0,
                  my: 2.5,
                  marginLeft: "30px",
                  display: { xs: "none", md: "block" },
                }}
              >
                <Tooltip title="Profile">
                  <IconButton
                    sx={{
                      p: 0,
                      marginTop: "auto",
                      marginBottom: "auto",
                      display: "block",
                    }}
                    href="/profile"
                  >
                    <Avatar src={iconURL} />
                  </IconButton>
                </Tooltip>
              </Box>
              <Box sx={{ display: { xs: "none", md: "block" } }}>
                <Box
                  sx={{
                    my: 1.5,
                    paddingRight: {
                      lg: "10vw",
                    },
                  }}
                >
                  <CustomConnectButton />
                </Box>
              </Box>

              <Box
                sx={{
                  display: { xs: "flex", md: "none" },
                  justifyContent: "flex-end",
                }}
              >
                <MobileMenu
                  WalletButton={<CustomConnectButton />}
                  iconURL={iconURL}
                />
              </Box>
            </Grid>
          </Grid>
        </Toolbar>
      </Container>
    </AppBar>
  );
}

function MobileMenu({ WalletButton, iconURL }) {
  const [state, setState] = useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  });

  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setState({ ...state, [anchor]: open });
  };

  const list = (anchor) => (
    <Box
      sx={{ width: anchor === "top" || anchor === "bottom" ? "auto" : 250 }}
      role="presentation"
      onKeyDown={toggleDrawer(anchor, false)}
    >
      <List>
        {pages.map((title) => (
          <ListItem key={title}>
            <Button href={pagesLink[title]} sx={{ width: "100%" }}>
              <Typography
                sx={{
                  my: 1,
                  textTransform: "none",
                  fontFamily: "Poppins",
                  fontStyle: "normal",
                  fontWeight: 400,
                  fontSize: "20px",
                  lineHeight: "24px",
                  textAlign: "center",
                  color: "#93a2a9",
                }}
              >
                {title}
              </Typography>
            </Button>
          </ListItem>
        ))}
      </List>
      <Divider />
      <Box sx={{ display: "flex", justifyContent: "center" }}>
        <Tooltip title="Profile">
          <IconButton
            sx={{
              p: 0,
              marginTop: "auto",
              marginBottom: "auto",
              display: "block",
            }}
            href="/profile"
          >
            {/* <Avatar alt="Remy Sharp" src="/static/images/avatar/2.jpg" /> */}
            <Avatar alt="Profile" src={iconURL} />
          </IconButton>
        </Tooltip>
        <>{WalletButton}</>
      </Box>
    </Box>
  );

  return (
    <>
      <IconButton
        size="large"
        aria-label="menu"
        aria-controls="menu-appbar"
        aria-haspopup="true"
        onClick={toggleDrawer("right", true)}
        sx={{
          color: "black",
          justifyContent: "right",
          "&:hover": {
            backgroundColor: "transparent",
          },
        }}
      >
        <img src={MenuIcon} alt="Menu" style={{ width: "50%" }} />
      </IconButton>
      <Drawer
        anchor="right"
        open={state["right"]}
        onClose={toggleDrawer("right", false)}
      >
        {list("right")}
      </Drawer>
    </>
  );
}

export default NavBarHome;
