import React, { useEffect } from "react";

//Components
import { Body } from "../components";
import NavBarHome from "../components/Home/NavBarHome";
import { Grid, Box, Button } from "@mui/material";
import HowItWorks from "../components/Home/HowItWorks";
import WhyUse from "../components/Home/WhyUse";
import FAQSection from "../components/Home/FAQSection";
import Footer from "../components/Footer";
import { startGrid } from "../components/Styles/GridWave";

//Icons
import DiamondTwoToneIcon from "@mui/icons-material/DiamondTwoTone";

//Images
import frontIllustration from "../images/frontIllustration.png";
import waveBackground from "../images/waveBackground.png";

function Home() {
  useEffect(() => {
    startGrid();
  }, []);

  return (
    <>
      <Body>
        <div className="grid-wave-container">
          <div className="grid-wave w-full h-full bg-blue-1"></div>
          <div className="grid-wave-content-main">
            <NavBarHome />
            <Grid container>
              <Grid
                item
                xs={12}
                md={5}
                lg={6}
                sx={{
                  left: {
                    xs: "0%",
                    md: "5%",
                    lg: "13%",
                  },
                  position: "relative",
                  marginTop: { xs: "3vh", md: "12vh" },
                  textAlign: { xs: "center", md: "left" },
                }}
              >
                <h1 className={"home_title"}>Diamond-Hand your NFTs</h1>
                <h2 className={"home_subtitle"}>
                  Sold Checks too early? Never again 💎
                </h2>
                <h2 className={"home_subtitle"}>
                  Get your own time-locked smart contract vault!
                </h2>
                <div className="diamondHandButton">
                  <Button
                    variant="contained"
                    endIcon={<DiamondTwoToneIcon />}
                    sx={{
                      fontFamily: "Poppins",
                      fontStyle: "normal",
                      fontWeight: 600,
                      fontSize: "22px",
                      // lineHeight: "30px",
                      textTransform: "none",
                      background: "#08121b",
                      "&:hover": {
                        backgroundColor: "black",
                      },
                      width: "240px",
                      height: "60px",
                      borderRadius: "30px",
                    }}
                    href="/start"
                  >
                    Start!
                  </Button>
                </div>
              </Grid>
              <Grid item xs={12} md={7} lg={6} sx={{ position: "relative" }}>
                <img
                  src={frontIllustration}
                  className={"front_illustration"}
                  alt="Diamond-Hand Instruction"
                />
              </Grid>
            </Grid>
            {/* <div className="spacer"></div> */}
            <HowItWorks />
          </div>
        </div>
        <Box
          sx={{
            width: "100%",
            height: { xs: "600px", sm: "600px" },
            // background: "#1D335F",
          }}
        ></Box>
        <WhyUse />
        <FAQSection />
        <Box
          sx={{
            width: "100%",
            background: "#1D335F",
          }}
        >
          <div className="wave_block">
            <img
              src={waveBackground}
              className="wave_background"
              alt="wave background"
            />
            <h2 className="wave_title">
              Maximize the potential of your NFT portfolio
            </h2>

            <Button
              variant="contained"
              endIcon={<DiamondTwoToneIcon />}
              sx={{
                fontFamily: "Poppins",
                fontStyle: "normal",
                fontWeight: 400,
                fontSize: "21px",
                // lineHeight: "30px",
                textTransform: "none",
                background: "#08121b",
                "&:hover": {
                  backgroundColor: "black",
                },
                width: "240px",
                height: "60px",
                borderRadius: "30px",
                border: "1px solid white",
                marginTop: {
                  md: "0px",
                  lg: "30px",
                },
              }}
              href="/start"
            >
              Start
            </Button>
          </div>
        </Box>
        <Box sx={{ width: "100%", height: "500px", background: "white" }}></Box>
        <p className="footnote">
          Note: Diamond-Hand.Me is currently in beta. By accessing this site,
          you agree to the{" "}
          <a className="footnoteA" href="https://diamond-hand.me/terms">
            Terms of Use
          </a>{" "}
          and <br></br>your use of our platform shall be at your sole risk.{" "}
          <br></br>
        </p>
        <Footer />
      </Body>
    </>
  );
}

export default Home;
