import React, { useEffect, useState } from "react";

//Wallet
import Web3 from "web3-utils";
import { useAccount, useSigner, useNetwork } from "wagmi";

//Contract
import { Contract } from "@ethersproject/contracts";
import { addresses, abis } from "@project/contracts";

//Components
import { Body } from "../components";
import NavBarHome from "../components/Home/NavBarHome";
import ErrorAlert from "../components/Modals/ErrorAlert";
import Footer from "../components/Footer";
import {
  Dialog,
  DialogContent,
  Button,
  Alert,
  AlertTitle,
  Collapse,
} from "@mui/material";
import CircularIndeterminate from "../components/CircularIndeterminate";

import diamondPass from "../images/diamond_pass.gif";
import DiamondTwoToneIcon from "@mui/icons-material/DiamondTwoTone";

import ReactHtmlParser from "react-html-parser";
import { startGrid } from "../components/Styles/GridWave";
import SuccessfulMint from "../components/Modals/SuccessfulMint";

const contractAbi = abis.diamondPass;
const contractAddress =
  process.env.REACT_APP_ENV === "sandbox" || process.env.REACT_APP_ENV === "dev"
    ? addresses.diamondPass
    : addresses.diamondPassMainnet;

function Mint() {
  const [address, setAddress] = useState("");
  const { data: signer } = useSigner();

  const [amountMinted, setAmountMinted] = useState("–");
  const [maxSupply, setMaxSupply] = useState("–");

  //For transaction UI
  const [startTransaction, setStartTransaction] = useState(false);
  const [transactionSuccessful, setTransactionSuccessful] = useState(false);
  const [transactionTitle, setTransactionTitle] = useState("");
  const [txHash, setTxHash] = useState("");

  //For displaying alerts
  const [alertTitle, setAlertTitle] = useState("");
  const [alertMessage, setAlertMessage] = useState("");
  const [showingAlert, setShowingAlert] = useState(false);
  const [chainError, setChainError] = useState(false);
  const [showingConnectWalletAlert, setShowingConnectWalletAlert] =
    useState(false);
  const [showingWrongChainAlert, setShowingWrongChainAlert] = useState(false);

  //Gridwave
  useEffect(() => {
    startGrid();
  }, []);

  //Wallet
  const account = useAccount({
    onConnect({ address }) {
      setAddress(address);
    },
    onDisconnect() {
      setAddress("");
    },
  });

  useEffect(() => {
    //Make sure account updates
    if (account.isConnected) {
      setAddress(account.address);
    } else if (account.isDisconnected) {
      setAddress("");
    }
  }, [account]);

  const { chain, chains } = useNetwork();
  useEffect(() => {
    if (account.isConnected) {
      //Check if chain is supported
      if (chain.unsupported) {
        //Disable buttons
        setChainError(true);
      } else {
        setChainError(false);
      }
    }
  }, [chain, account]);

  //update stat every refresh
  useEffect(() => {
    getAmountMinted();
  }, []);

  function handleError(error) {
    //Check if it's user rejecting transaction
    if (error.code === "ACTION_REJECTED") {
      //Ignore
      //Closing step dialog
      setStartTransaction(false);
    } else {
      let extractedError = error;
      if (error.error) {
        extractedError = error.error;
      }
      // if (!isObject(error)) {
      //   extractedError = extractJSON(String(error))[0];
      // }
      setAlertTitle("Error");
      setAlertMessage(extractedError.message);
      setShowingAlert(true);
      //Closing step dialog
      setStartTransaction(false);
      window.scrollTo(0, 0);
    }
  }

  async function getAmountMinted() {
    fetch(
      (process.env.REACT_APP_ENV === "production" ||
      process.env.REACT_APP_ENV === "sandbox"
        ? process.env.REACT_APP_HOST
        : "http://localhost:3001") +
        "/get_diamond_pass_minted?" +
        new URLSearchParams({ contractAddress })
    )
      .then((response) => response.json())
      .then((response) => {
        setAmountMinted(response.amountMinted);
        setMaxSupply(response.maxSupply);
      });
  }

  async function mintNFT() {
    //Check if wallet is connected first
    if (address === "") {
      setShowingConnectWalletAlert(true);
    } else if (chainError) {
      //show wrong chain error
      setShowingWrongChainAlert(true);
    } else {
      //UI
      setStartTransaction(true);
      setTransactionTitle("Minting a Diamond Pass");

      const nftContract = new Contract(contractAddress, contractAbi, signer);
      const amountMinted = await nftContract.totalSupply();
      let payment = 0;
      if (amountMinted >= 500) {
        payment = Web3.toWei("0.1", "ether");
      }

      await nftContract
        .mint({ value: payment })
        .then((receipt) => {
          // This is entered if the transaction receipt indicates success
          finishMinting(receipt);
        })
        .catch((error) => {
          handleError(error);
        });
    }
  }

  async function finishMinting(approvalHash) {
    await approvalHash.wait().then(
      (receipt) => {
        // This is entered if the transaction receipt indicates success
        setTxHash(approvalHash.hash);
        setTransactionSuccessful(true);
        //hide transaction modal
        setStartTransaction(false);
        // const txUrl =
        //   "https://" +
        //   (process.env.REACT_APP_ENV === "sandbox" ? "goerli." : "") +
        //   "etherscan.io/tx/" +
        //   approvalHash.hash;

        // setTransactionTitle(
        //   "Diamond Pass minted! See <a href=" +
        //     txUrl +
        //     "> transaction </a> <br> </br> You can now use this site for free, forever!"
        // );
      },
      (error) => {
        handleError(error);
      }
    );
  }

  return (
    <>
      {/* <Header>
      </Header> */}
      <Body>
        <div className="grid-wave-container-2">
          <div className="grid-wave w-full h-full bg-blue-1"></div>
          <div className="grid-wave-content">
            <NavBarHome />
            <ErrorAlert
              title={alertTitle}
              message={alertMessage}
              open={showingAlert}
              updateOpen={setShowingAlert}
            />

            <div
              style={{
                // height: "100%",
                // display: "flex",
                // flexDirection: "column",
                // justifyContent: "center",
                textAlign: "center",
                // marginTop: "10vh",
              }}
            >
              <h1 style={{ padding: "20px" }}>Become a Diamond-Hand</h1>
              <img
                src={diamondPass}
                className="diamondPass"
                alt="Diamond Pass"
              />
              <h2>
                Passes Left:{" "}
                {amountMinted === "–" ? amountMinted : maxSupply - amountMinted}{" "}
                / {maxSupply}
              </h2>
              <Collapse in={showingConnectWalletAlert}>
                <Alert
                  severity="info"
                  sx={{ width: "400px", marginX: "auto", marginY: "30px" }}
                  onClose={() => {
                    setShowingConnectWalletAlert(false);
                  }}
                >
                  <AlertTitle>Connect your wallet</AlertTitle>
                  Please connect your wallet in order to mint!
                </Alert>
              </Collapse>
              <Collapse in={showingWrongChainAlert}>
                <Alert
                  severity="info"
                  sx={{ width: "400px", marginX: "auto", marginY: "30px" }}
                  onClose={() => {
                    setShowingWrongChainAlert(false);
                  }}
                >
                  <AlertTitle>Wrong Network</AlertTitle>
                  Please change your network to {chains[0]?.name}!
                </Alert>
              </Collapse>

              <Button
                variant="contained"
                endIcon={<DiamondTwoToneIcon />}
                sx={{
                  fontFamily: "Poppins",
                  fontStyle: "normal",
                  fontWeight: 600,
                  fontSize: "22px",
                  // lineHeight: "30px",
                  textTransform: "none",
                  background: "#08121b",
                  "&:hover": {
                    backgroundColor: "black",
                  },
                  width: "240px",
                  height: "60px",
                  borderRadius: "30px",
                }}
                // disabled={unsupp}
                onClick={() => mintNFT()}
              >
                Mint
              </Button>
              <p
                style={{
                  width: "60%",
                  color: "black",
                  marginLeft: "auto",
                  marginRight: "auto",
                  marginTop: "50px",
                  fontSize: "24px",
                }}
              >
                Owning a Diamond Pass allows you to use our platform for FREE,
                forever. <br></br> First 500 passes are free. The remainder are{" "}
                <span className="eth">Ξ</span>0.1 Each.
              </p>
            </div>
            <Dialog
              open={startTransaction}
              keepMounted
              // onClose={}
              aria-describedby="alert-dialog-slide-description"
            >
              <DialogContent>
                <div
                  style={{
                    textAlign: "center",
                  }}
                >
                  <h2>{ReactHtmlParser(transactionTitle)}</h2>
                  <div
                    style={{
                      display: "inline-flex",
                      alignItems: "center",
                    }}
                  >
                    <div hidden={transactionSuccessful}>
                      <CircularIndeterminate />
                    </div>
                  </div>
                </div>
              </DialogContent>
            </Dialog>
            <SuccessfulMint
              showing={transactionSuccessful}
              transactionHash={txHash}
            />
            <Footer></Footer>
          </div>
        </div>
      </Body>
    </>
  );
}

export default Mint;
