//Converts seconds to days/hours/minutes/seconds (only showing the highest one)
export function secondsToString(seconds) {
  let returnString = "~";
  const secondsInDay = 86400;
  if (seconds >= secondsInDay) {
    const days = Math.floor(seconds / secondsInDay);
    if (days === 1) {
      returnString = returnString + " " + days + " day";
    } else {
      returnString = returnString + " " + days + " days";
    }
  } else {
    if (seconds >= 3600) {
      const decimals = parseFloat(
        "0." + (seconds / secondsInDay + "").split(".")[1]
      );
      const hours = Math.floor((decimals * secondsInDay) / 3600);
      if (hours === 1) {
        returnString = returnString + " " + hours + " hour";
      } else {
        returnString = returnString + " " + hours + " hours";
      }
    } else if (seconds >= 60) {
      const decimals = parseFloat("0." + (seconds / 3600 + "").split(".")[1]);
      const minutes = Math.floor((decimals * 3600) / 60);
      if (minutes === 1) {
        returnString = returnString + " " + minutes + " minute";
      } else {
        returnString = returnString + " " + minutes + " minutes";
      }
    } else {
      if (seconds === 1) {
        returnString = `${returnString} 1 second`;
      } else {
        returnString = returnString + " " + Math.floor(seconds) + " seconds";
      }
    }
  }
  return returnString;
}
