import * as React from "react";
import PropTypes from "prop-types";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
import Paper from "@mui/material/Paper";
import { visuallyHidden } from "@mui/utils";
import CircularIndeterminate from "./CircularIndeterminate";
import { useNavigate } from "react-router-dom";

import { secondsToString } from "../extensions/secondsToString";
import { BigNumber } from "ethers";

import NoImage from "../images/no-image.png";
import { useState, useEffect } from "react";

function descendingComparator(a, b, orderBy) {
  if (
    orderBy === "all_time_diamond_duration" ||
    orderBy === "current_longest_diamond_duration"
  ) {
    //Convert to number
    if (Number(b[orderBy]) < Number(a[orderBy])) {
      return -1;
    }
    if (Number(b[orderBy]) > Number(a[orderBy])) {
      return 1;
    }
  } else {
    if (b[orderBy] < a[orderBy]) {
      return -1;
    }
    if (b[orderBy] > a[orderBy]) {
      return 1;
    }
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

// This method is created for cross-browser compatibility, if you don't
// need to support IE11, you can use Array.prototype.sort() directly
function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

const currentHeadCells = [
  {
    id: "rank",
    numeric: true,
    disablePadding: false,
    label: "#",
  },
  {
    id: "collection_name",
    numeric: false,
    disablePadding: false,
    label: "Collection",
  },
  {
    id: "current_diamond_count",
    numeric: true,
    disablePadding: false,
    label: "# Diamond-Handed NFTs",
  },
  {
    id: "current_unique_wallets",
    numeric: true,
    disablePadding: false,
    label: "# Diamond-Handers (unique wallets)",
  },
  {
    id: "current_longest_diamond_duration",
    numeric: true,
    disablePadding: false,
    label: "Longest Diamond-Hand Duration",
  },
];

const allTimeHeadCells = [
  {
    id: "rank",
    numeric: true,
    disablePadding: false,
    label: "#",
  },
  {
    id: "collection_name",
    numeric: false,
    disablePadding: false,
    label: "Collection",
  },
  {
    id: "all_time_diamond_count",
    numeric: true,
    disablePadding: false,
    label: "# Diamond-Handed NFTs",
  },
  {
    id: "all_time_unique_wallets",
    numeric: true,
    disablePadding: false,
    label: "# Diamond-Handers (unique wallets)",
  },
  {
    id: "all_time_diamond_duration",
    numeric: true,
    disablePadding: false,
    label: "Longest Diamond-Hand Duration",
  },
];

function EnhancedTableHead(props) {
  const { order, orderBy, onRequestSort, isAllTime } = props;
  const createSortHandler = (property) => (event) => {
    if (property !== "collection_name" && property !== "rank") {
      onRequestSort(event, property);
    }
  };
  const [headCells, setHeadCells] = useState(currentHeadCells);

  useEffect(() => {
    if (isAllTime) {
      setHeadCells(allTimeHeadCells);
    } else {
      setHeadCells(currentHeadCells);
    }
  }, [isAllTime]);

  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? "right" : "left"}
            padding={headCell.disablePadding ? "none" : "normal"}
            sortDirection={orderBy === headCell.id ? order : false}
            sx={{
              backgroundColor: "#24272d",
              color: "white",
              fontFamily: "Poppins",
              // fontWeight: 600,
              fontSize: "16px",
              textAlign: "center",
              width: headCell.id === "rank" ? "40px" : "25%",
              cursor:
                headCell.id === "rank" || headCell.id === "collection_name"
                  ? "default"
                  : "pointer",
            }}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={createSortHandler(headCell.id)}
              style={{ color: "white" }}
              sx={{
                "&.Mui-active": {
                  color: "white",
                },
                "& .MuiTableSortLabel-icon": {
                  display:
                    headCell.id === "collection_name" || headCell.id === "rank"
                      ? "none"
                      : "block",
                  color: "white !important",
                },
                cursor:
                  headCell.id === "rank" || headCell.id === "collection_name"
                    ? "default"
                    : "pointer",
              }}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === "desc" ? "sorted descending" : "sorted ascending"}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  onRequestSort: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

export default function CollectionTable({ data, isAllTime }) {
  const [order, setOrder] = useState("desc");
  const [orderBy, setOrderBy] = useState("all_time_diamond_count");
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [rows, setRows] = useState(false);
  const [allRows, setAllRows] = useState(false);
  const ERC721 = 0;
  const ERC1155 = 1;
  let navigate = useNavigate();

  //Navigate to specific collection stats
  function clickedRow(collection_address, asset_type, token_id) {
    if (asset_type === ERC721) {
      navigate(`${collection_address}`);
    } else if (asset_type === ERC1155) {
      navigate(`${collection_address}/${token_id}`);
    }
  }
  //Dictionary matching collection_address to img url
  const [imageDict, setImageDict] = useState({});
  //Also match collection name
  const [nameDict, setNameDict] = useState({});

  //useEffect to update rows
  useEffect(() => {
    if (data) {
      var intProps = { diamond_count: 1, unique_wallets: 1 };
      var floatProps = { longest_diamond_duration: 1 };

      const rows = JSON.parse(data, function (k, v) {
        if (intProps.hasOwnProperty(k)) {
          return parseInt(v, 10);
        } else if (floatProps.hasOwnProperty(k)) {
          return parseFloat(v);
        }
        return v;
      });

      setAllRows(rows);

      //Fetch image from OpenSea:
      const options = {
        method: "GET",
        headers: { Accept: "application/json" },
      };
      let fetchLink =
        "https://" +
        (process.env.REACT_APP_ENV === "sandbox" ||
        process.env.REACT_APP_ENV === "dev"
          ? "testnets-"
          : "") +
        "api.opensea.io/api/v1/assets?";
      for (let j = 0; j < rows.length; j++) {
        if (Number(rows[j].asset_type) === ERC721) {
          fetchLink = fetchLink + "token_ids=1&";
        } else {
          let tokenID = BigNumber.from(rows[j].token_id).toString();
          fetchLink = fetchLink + "token_ids=" + tokenID + "&";
        }
      }
      for (let i = 0; i < rows.length; i++) {
        fetchLink =
          fetchLink +
          "asset_contract_addresses=" +
          rows[i].collection_address +
          "&";
      }
      fetch(fetchLink, options)
        .then((response) => response.json())
        .then((response) => {
          //match opensea's results
          const openSeaResults = response.assets;
          for (let j = 0; j < rows.length; j++) {
            const filtered = openSeaResults.filter((obj) => {
              return (
                String(obj.asset_contract.address).toLowerCase() ===
                String(rows[j].collection_address).toLowerCase()
              );
            })[0];
            //Store in imageDict
            setImageDict((prevState) => ({
              ...prevState,
              [rows[j].collection_address]: filtered.collection.image_url,
            }));
            setNameDict((prevState) => ({
              ...prevState,
              [rows[j].collection_address]: filtered.collection.name,
            }));
          }
        })
        .catch((err) => console.error(err));
    }
  }, [data]);

  useEffect(() => {
    if (isAllTime) {
      //Change orderBy to counterpart
      if (orderBy === "current_longest_diamond_duration") {
        setOrderBy("all_time_diamond_duration");
      } else if (orderBy === "current_diamond_count") {
        setOrderBy("all_time_diamond_count");
      } else if (orderBy === "current_unique_wallets") {
        setOrderBy("all_time_unique_wallets");
      }
    } else {
      if (orderBy === "all_time_diamond_duration") {
        setOrderBy("current_longest_diamond_duration");
      } else if (orderBy === "all_time_diamond_count") {
        setOrderBy("current_diamond_count");
      } else if (orderBy === "all_time_unique_wallets") {
        setOrderBy("current_unique_wallets");
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAllTime]);

  useEffect(() => {
    //Filter rows based on isAllTime
    if (allRows) {
      if (isAllTime) {
        setRows(allRows);
      } else {
        const filteredRows = allRows.filter((el) => {
          if (Number(el.current_diamond_count) === 0) {
            //skip collections with 0 current diamond hands
            return false;
          }
          return true;
        });
        setRows(filteredRows);
      }
    }
  }, [isAllTime, allRows]);

  if (!rows) {
    return (
      <Box
        sx={{
          width: "80%",
          maxWidth: "1200px",
          textAlign: "center",
          marginX: "auto",
        }}
      >
        <CircularIndeterminate size={"3vw"} />
      </Box>
    );
  } else {
    const handleRequestSort = (event, property) => {
      const isAsc = orderBy === property && order === "asc";
      setOrder(isAsc ? "desc" : "asc");
      setOrderBy(property);
    };

    const handleChangePage = (event, newPage) => {
      setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
      setRowsPerPage(parseInt(event.target.value, 10));
      setPage(0);
    };

    // Avoid a layout jump when reaching the last page with empty rows.
    const emptyRows =
      page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

    return (
      <Box sx={{ width: "80%", maxWidth: "1200px", marginX: "auto" }}>
        <Paper sx={{ width: "100%", mb: 2 }}>
          <TableContainer>
            <Table
              sx={{ minWidth: 600 }}
              aria-labelledby="tableTitle"
              size={"medium"}
              // table-layout="fixed"
            >
              <EnhancedTableHead
                order={order}
                orderBy={orderBy}
                onRequestSort={handleRequestSort}
                rowCount={rows.length}
                isAllTime={isAllTime}
              />
              <TableBody>
                {/* if you don't need to support IE11, you can replace the `stableSort` call with:
                     rows.slice().sort(getComparator(order, orderBy)) */}
                {stableSort(rows, getComparator(order, orderBy))
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((row, index) => {
                    const labelId = `enhanced-table-checkbox-${index}`;

                    return (
                      <TableRow
                        hover
                        tabIndex={-1}
                        key={row.collection_address}
                        onClick={() => {
                          clickedRow(
                            row.collection_address,
                            Number(row.asset_type),
                            row.token_id ? row.token_id : 1
                          );
                        }}
                        className="statsTable"
                      >
                        <TableCell id={labelId} scope="row" padding="none">
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "center",
                            }}
                          >
                            <p
                              style={{
                                marginTop: "auto",
                                marginBottom: "auto",
                              }}
                            >
                              {page * rowsPerPage + index + 1}
                            </p>
                          </div>
                        </TableCell>
                        <TableCell id={labelId} scope="row" padding="none">
                          <div style={{ display: "flex" }}>
                            <img
                              src={
                                imageDict[row.collection_address]
                                  ? imageDict[row.collection_address]
                                  : NoImage
                              }
                              className="collection-Rankings-Image"
                              alt="NFT"
                              onError={({ currentTarget }) => {
                                currentTarget.onerror = null; // prevents looping
                                currentTarget.src = NoImage;
                              }}
                            />
                            <p
                              style={{
                                marginTop: "auto",
                                marginBottom: "auto",
                                marginLeft: "10px",
                              }}
                            >
                              {nameDict[row.collection_address]
                                ? nameDict[row.collection_address]
                                : row.collection_name === ""
                                ? row.collection_address.substring(0, 6) +
                                  "..." +
                                  row.collection_address.substring(36)
                                : row.collection_name}
                            </p>
                          </div>
                        </TableCell>
                        <TableCell align="right">
                          {
                            <p
                              style={{
                                marginTop: "auto",
                                marginBottom: "auto",
                                marginLeft: "10px",
                              }}
                            >
                              {isAllTime
                                ? row.all_time_diamond_count
                                : row.current_diamond_count}
                            </p>
                          }
                        </TableCell>
                        <TableCell align="right">
                          <p
                            style={{
                              marginTop: "auto",
                              marginBottom: "auto",
                              marginLeft: "10px",
                            }}
                          >
                            {isAllTime
                              ? row.all_time_unique_wallets
                              : row.current_unique_wallets}
                          </p>
                        </TableCell>
                        <TableCell align="right">
                          <p
                            style={{
                              marginTop: "auto",
                              marginBottom: "auto",
                              marginLeft: "10px",
                            }}
                          >
                            {secondsToString(
                              isAllTime
                                ? row.all_time_diamond_duration
                                : row.current_longest_diamond_duration
                            )}
                          </p>
                        </TableCell>
                      </TableRow>
                    );
                  })}
                {emptyRows > 0 && (
                  <TableRow
                    style={{
                      height: 53 * emptyRows,
                    }}
                  >
                    <TableCell colSpan={6} />
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[]}
            component="div"
            count={Math.ceil(rows.length / rowsPerPage)}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Paper>
      </Box>
    );
  }
}
