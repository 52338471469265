import * as React from "react";
import Box from "@mui/material/Box";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import StepContent from "@mui/material/StepContent";
import Typography from "@mui/material/Typography";
import CircularIndeterminate from "../CircularIndeterminate";

// import ReactHtmlParser from "react-html-parser";

export default function VerticalStepper({ steps, activeStep, finishMessage }) {
  return (
    <Box sx={{ maxWidth: 400 }}>
      <Stepper activeStep={activeStep} orientation="vertical">
        {steps.map((step, index) => (
          <Step key={step.label}>
            <div
              style={{
                display: "inline-flex",
                alignItems: "center",
              }}
            >
              <StepLabel>
                <Typography
                  sx={{
                    fontFamily: "Poppins",
                    fontSize: "14px",
                    color: "gray",
                  }}
                >
                  {step.label}
                </Typography>
              </StepLabel>
              <div hidden={activeStep !== index} style={{ marginLeft: 10 }}>
                <CircularIndeterminate size={20} />
              </div>
            </div>
            <StepContent>
              <Typography sx={{ fontFamily: "Poppins" }}>
                {step.description}
              </Typography>
            </StepContent>
          </Step>
        ))}
      </Stepper>
      {/* {activeStep === steps.length && (
        <>
          <Paper square elevation={0} sx={{ p: 3 }}>
            <Typography sx={{ fontFamily: "Poppins" }}>
              {ReactHtmlParser(finishMessage)}
            </Typography>
          </Paper>
          <Button
            style={{
              margin: "0 auto",
              display: "flex",
              textDecoration: "none",
              textTransform: "none",
              fontFamily: "Poppins",
              fontStyle: "normal",
              fontWeight: 400,
              fontSize: "22px",
              color: "white",
              "&:hover": {
                backgroundColor: "black",
              },
              backgroundColor: "black",
              borderRadius: "30px",
              paddingLeft: "15px",
              paddingRight: "15px",
            }}
            href="/profile"
          >
            Go to Profile
          </Button>
        </>
      )} */}
    </Box>
  );
}
