import styled from "styled-components";

export const Header = styled.header`
  background-color: white;
  min-height: 70px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  color: white;
`;

export const Body = styled.div`
  align-items: center;
  background-color: white;
  color: white;
  display: flex;
  flex-direction: column;
  font-size: calc(10px + 2vmin);
  justify-content: flex-start;
  min-height: calc(100vh - 70px);
  overflow-x: hidden;
`;
export const BodyAlignedTop = styled.div`
  align-items: center;
  background-color: #282c34;
  color: white;
  display: flex;
  flex-direction: column;
  font-size: calc(10px + 2vmin);
  justify-content: start;
  min-height: calc(100vh - 70px);
`;

// export const Image = styled.img`
//   height: 40vmin;
//   margin-bottom: 16px;
//   pointer-events: none;
// `;

// export const Link = styled.a.attrs({
//   target: "_blank",
//   rel: "noopener noreferrer",
// })`
//   color: #61dafb;
//   margin-top: 10px;
// `;

export const Button = styled.button`
  background-color: white;
  // border: none;
  border-radius: 20px;
  width: 160px;
  height: 50px;

  color: #282c34;
  cursor: pointer;
  text-align: center;
  text-decoration: none;
  margin: 0px 20px;
  padding: 12px 24px;

  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;

  // ${(props) => props.hidden && "hidden"} :focus {
  //   border: none;
  //   outline: none;
  // }
`;
