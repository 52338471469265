import { useState, useEffect } from "react";
import NoImage from "../../images/no-image.png";
import blockies from "ethereum-blockies";

export default function CustomAvatar({ address, ensImage, size }) {
  const [iconURL, setIconURL] = useState(NoImage);

  useEffect(() => {
    const newIconURL = blockies
      .create({
        seed: address, // seed used to generate icon data, default: random
        size: 8, // width/height of the icon in blocks, default: 8
        scale: 16, // width/height of each block in pixels, default: 4
        // spotcolor: "#000", // each pixel has a 13% chance of being of a third color,
        // default: random. Set to -1 to disable it. These "spots" create structures
        // that look like eyes, mouths and noses.
      })
      .toDataURL();
    if (newIconURL !== iconURL) {
      setIconURL(newIconURL);
    }
  }, [address, iconURL]);

  return (
    <img
      src={iconURL}
      width={size}
      height={size}
      alt="Profile"
      onError={({ currentTarget }) => {
        currentTarget.onerror = null; // prevents looping
        currentTarget.src = NoImage;
      }}
    />
  );
}
