import { ConnectButton } from "@rainbow-me/rainbowkit";
import { Button } from "@mui/material";
export default function CustomConnectButton() {
  return (
    <ConnectButton.Custom>
      {({
        account,
        chain,
        openAccountModal,
        openChainModal,
        openConnectModal,
        authenticationStatus,
        mounted,
      }) => {
        // Note: If your app doesn't use authentication, you
        // can remove all 'authenticationStatus' checks
        const ready = mounted;
        // && authenticationStatus !== "loading";
        const connected = ready && account && chain;
        // &&
        // (!authenticationStatus || authenticationStatus === "authenticated");

        return (
          <div
            {...(!ready && {
              "aria-hidden": true,
              style: {
                opacity: 0,
                pointerEvents: "none",
                userSelect: "none",
              },
            })}
          >
            {(() => {
              if (!connected) {
                return (
                  <Button
                    onClick={openConnectModal}
                    sx={{
                      borderRadius: "20px",
                      border: "2px solid #93a2a9",
                      textTransform: "none",
                      height: "50px",
                      width: "150px",
                      marginLeft: "10px",
                    }}
                  >
                    <p
                      style={{
                        fontFamily: "Poppins",
                        color: "#93a2a9",
                        fontSize: "16px",
                      }}
                    >
                      Connect Wallet
                    </p>
                  </Button>
                );
              }
              if (chain.unsupported) {
                return (
                  <Button
                    onClick={openChainModal}
                    sx={{
                      borderRadius: "20px",
                      border: "2px solid #93a2a9",
                      textTransform: "none",
                      height: "50px",
                      width: "150px",
                      marginLeft: "10px",
                    }}
                  >
                    <p
                      style={{
                        fontFamily: "Poppins",
                        color: "#ef476f",
                        fontSize: "16px",
                      }}
                    >
                      Wrong Network
                    </p>
                  </Button>
                );
              }
              return (
                <Button
                  onClick={openAccountModal}
                  sx={{
                    borderRadius: "20px",
                    border: "2px solid #93a2a9",
                    textTransform: "none",
                    height: "50px",
                    width: "150px",
                    marginLeft: "10px",
                  }}
                >
                  <p
                    style={{
                      fontFamily: "Poppins",
                      color: "#93a2a9",
                      fontSize: "16px",
                    }}
                  >
                    {account.displayName}
                  </p>
                </Button>
              );
            })()}
          </div>
        );
      }}
    </ConnectButton.Custom>
  );
}
