const addresses = {
  //Goerli:
  diamondHandFactory: "0xA128F0c8CdD05E0AC1e26D9441663E6214493BB4",
  diamondPass: "0x47F6CC4412D58B3acE4d9c9dc556Dfeddf6904d5",
  diamondVault: "0xcCa2D0cf3b269d2F6ed8BBF9C76D083d40c4E24d",

  //Mainnet:
  diamondPassMainnet: "0xCcFd8b9757F49ca1c890d3a17Ae262542f8d85a7",
  diamondHandFactoryMainnet: "0xE503d0008B3587a55F539D6E58170f5f13Af89E4",
  diamondVaultMainnet: "0x5e36A382e4Fe3117c121f8D4dd28A87AE2e66e60",
};

export default addresses;
