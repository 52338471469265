import Box from "@mui/material/Box";

import * as React from "react";
import List from "@mui/material/List";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import Collapse from "@mui/material/Collapse";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import { addresses } from "@project/contracts";

import { Link } from "react-router-dom";

export default function FAQSection() {
  const [qOneOpen, setQOneOpen] = React.useState(false);
  const [qTwoOpen, setQTwoOpen] = React.useState(false);
  const [qThreeOpen, setQThreeOpen] = React.useState(false);
  const [qFourOpen, setQFourOpen] = React.useState(false);
  const [qFiveOpen, setQFiveOpen] = React.useState(false);
  const [codeZeroOpen, setCodeZeroOpen] = React.useState(false);
  const [codeOneOpen, setCodeOneOpen] = React.useState(false);
  const [codeTwoOpen, setCodeTwoOpen] = React.useState(false);
  const [codeThreeOpen, setCodeThreeOpen] = React.useState(false);
  const [codeFourOpen, setCodeFourOpen] = React.useState(false);

  //   allCollapseIDs = ["q1", "q2", "q3", "code1", "code2", "code3", "code4"];
  const handleClick = (collapseID) => {
    switch (collapseID) {
      case "q1":
        setQOneOpen(!qOneOpen);
        break;
      case "q2":
        setQTwoOpen(!qTwoOpen);
        break;
      case "q3":
        setQThreeOpen(!qThreeOpen);
        break;
      case "q4":
        setQFourOpen(!qFourOpen);
        break;
      case "q5":
        setQFiveOpen(!qFiveOpen);
        break;
      case "code0":
        setCodeZeroOpen(!codeZeroOpen);
        break;
      case "code1":
        setCodeOneOpen(!codeOneOpen);
        break;
      case "code2":
        setCodeTwoOpen(!codeTwoOpen);
        break;
      case "code3":
        setCodeThreeOpen(!codeThreeOpen);
        break;
      case "code4":
        setCodeFourOpen(!codeFourOpen);
        break;
      default:
        break;
    }
  };

  return (
    <Box
      sx={{
        width: "100%",
        background: "#1D335F",
        paddingBottom: "30vh",
      }}
    >
      <h2 className="FAQ_title">FAQ</h2>
      <List
        sx={{ width: "80%", marginLeft: "auto", marginRight: "auto" }}
        component="nav"
        aria-labelledby="nested-list-subheader"
      >
        <Box
          sx={{
            backgroundColor: "white",
            borderRadius: "10px",
            ":hover": {
              bgcolor: "white",
            },
            padding: "20px",
            width: "80%",
            marginLeft: "auto",
            marginRight: "auto",
          }}
        >
          <ListItemButton
            onClick={() => {
              handleClick("q1");
            }}
            sx={{
              ":hover": {
                bgcolor: "white",
              },
            }}
          >
            <ListItemText
              primary="1. Is this safe? Can you transfer my NFTs away? Are you gonna drain me?"
              primaryTypographyProps={{
                fontFamily: "Poppins",
                fontStyle: "normal",
                fontWeight: 400,
                fontSize: {
                  lg: "24px",
                  xs: "18px",
                },
                lineHeight: "36px",
                color: "#93A2A9",
              }}
            />
            {qOneOpen ? (
              <ExpandLess
                sx={{
                  color: "#000000",
                  lg: {
                    width: "50px",
                    height: "50px",
                  },
                  xs: {
                    width: "25px",
                    height: "25px",
                  },
                }}
              />
            ) : (
              <ExpandMore
                sx={{
                  color: "#000000",
                  lg: {
                    width: "50px",
                    height: "50px",
                  },
                  xs: {
                    width: "25px",
                    height: "25px",
                    paddingRight: "0px",
                  },
                }}
              />
            )}
          </ListItemButton>
          <Collapse in={qOneOpen} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>
              <ListItemText>
                <p className="FAQp">
                  We have worked with{" "}
                  <a className="FAQa" href="https://www.certik.com/">
                    Certik
                  </a>{" "}
                  to ensure that there are no security vulnerabilities in our
                  smart contracts. The report can be found{" "}
                  <a
                    className="FAQa"
                    href="https://www.certik.com/projects/diamond-hand"
                  >
                    here
                  </a>
                  . <br></br>
                  The smart contract source codes can be found below:
                </p>
                <a
                  className="FAQa"
                  href={
                    process.env.REACT_APP_ENV === "sandbox" ||
                    process.env.REACT_APP_ENV === "dev"
                      ? "https://goerli.etherscan.io/address/" +
                        addresses.diamondHandFactory +
                        "#code"
                      : "https://etherscan.io/address/" +
                        addresses.diamondHandFactoryMainnet +
                        "#code"
                  }
                >
                  DiamondHandFactory
                </a>
                <br></br>
                <a
                  className="FAQa"
                  href={
                    process.env.REACT_APP_ENV === "sandbox" ||
                    process.env.REACT_APP_ENV === "dev"
                      ? "https://goerli.etherscan.io/address/" +
                        addresses.diamondVault +
                        "#code"
                      : "https://etherscan.io/address/" +
                        addresses.diamondVaultMainnet +
                        "#code"
                  }
                >
                  DiamondVault
                </a>
                <p className="FAQp">
                  When diamond-handing, you will transfer your NFTs into your
                  very own DiamondVault (created by the DiamondHandFactory
                  contract). You will become the vault owner and{" "}
                  <b>ONLY YOU </b> will be able to withdraw from this vault.
                  Even if this site goes offline, you will still have access to
                  your assets through the smart contracts.
                  <br></br> Here’s also a quick breakdown of the main functions
                  you would call when diamond-handing:
                </p>
                <ListItemButton
                  onClick={() => {
                    handleClick("code0");
                  }}
                >
                  <ListItemText
                    primary="createDiamondVault()"
                    primaryTypographyProps={{
                      fontFamily: "Poppins",
                      fontStyle: "normal",
                      fontWeight: 400,
                      fontSize: {
                        lg: "18px",
                        xs: "14px",
                      },
                      lineHeight: "28px",
                      color: "#93A2A9",
                    }}
                  />
                  {codeZeroOpen ? (
                    <ExpandLess
                      sx={{
                        lg: {
                          width: "25px",
                          height: "25px",
                        },
                        sx: {
                          width: "20px",
                          height: "20px",
                        },
                        color: "#000000",
                        cursor: "pointer",
                      }}
                    />
                  ) : (
                    <ExpandMore
                      sx={{
                        lg: {
                          width: "25px",
                          height: "25px",
                        },
                        sx: {
                          width: "20px",
                          height: "20px",
                        },
                        color: "#000000",
                        cursor: "pointer",
                      }}
                    />
                  )}
                </ListItemButton>
                <Collapse in={codeZeroOpen} timeout="auto" unmountOnExit>
                  <List component="div" disablePadding>
                    <ListItemText>
                      <p className="FAQp2">
                        You will only call this function the very first time you
                        diamond-hand. This is a call to the{" "}
                        <a
                          className="FAQa2"
                          href={
                            process.env.REACT_APP_ENV === "sandbox" ||
                            process.env.REACT_APP_ENV === "dev"
                              ? "https://goerli.etherscan.io/address/" +
                                addresses.diamondHandFactory +
                                "#code"
                              : "https://etherscan.io/address/" +
                                addresses.diamondHandFactoryMainnet +
                                "#code"
                          }
                        >
                          DiamondHandFactory
                        </a>{" "}
                        to deploy your own{" "}
                        <a
                          className="FAQa2"
                          href={
                            process.env.REACT_APP_ENV === "sandbox" ||
                            process.env.REACT_APP_ENV === "dev"
                              ? "https://goerli.etherscan.io/address/" +
                                addresses.diamondVault +
                                "#code"
                              : "https://etherscan.io/address/" +
                                addresses.diamondVaultMainnet +
                                "#code"
                          }
                        >
                          DiamondVault
                        </a>{" "}
                        and set yourself as the vault owner. The actual
                        implementation is through an{" "}
                        <a
                          className="FAQa2"
                          href="https://eips.ethereum.org/EIPS/eip-1167"
                        >
                          EIP-1167 (Minimal Proxy Contract)
                        </a>{" "}
                        which clones a contract in an immutable way (you don't
                        have to worry about others tempering with your vault
                        code). A minimal proxy also saves you gas when you
                        create your vault!
                      </p>
                    </ListItemText>
                  </List>
                </Collapse>
                <ListItemButton
                  onClick={() => {
                    handleClick("code1");
                  }}
                >
                  <ListItemText
                    primary="setApprovalForAll()"
                    primaryTypographyProps={{
                      fontFamily: "Poppins",
                      fontStyle: "normal",
                      fontWeight: 400,
                      fontSize: {
                        lg: "18px",
                        xs: "14px",
                      },
                      lineHeight: "28px",
                      color: "#93A2A9",
                    }}
                  />
                  {codeOneOpen ? (
                    <ExpandLess
                      sx={{
                        lg: {
                          width: "25px",
                          height: "25px",
                        },
                        sx: {
                          width: "20px",
                          height: "20px",
                        },
                        color: "#000000",
                        cursor: "pointer",
                      }}
                    />
                  ) : (
                    <ExpandMore
                      sx={{
                        lg: {
                          width: "25px",
                          height: "25px",
                        },
                        sx: {
                          width: "20px",
                          height: "20px",
                        },
                        color: "#000000",
                        cursor: "pointer",
                      }}
                    />
                  )}
                </ListItemButton>
                <Collapse in={codeOneOpen} timeout="auto" unmountOnExit>
                  <List component="div" disablePadding>
                    <ListItemText>
                      <p className="FAQp2">
                        This is a standard call made to approve the transfer of
                        your NFTs into your DiamondVault. This is the same call
                        you make when you list your NFTs for sale on OpenSea,
                        LooksRare…etc
                        <br /> <br />
                        No, we cannot transfer your NFTs to someone else (and
                        definitely not our own wallets). You are the vault owner
                        and only you can call the functions for withdrawing NFTs
                        when you are done diamond-handing (breakUnlock and
                        releaseDiamond)
                      </p>
                    </ListItemText>
                  </List>
                </Collapse>
                <ListItemButton
                  onClick={() => {
                    handleClick("code2");
                  }}
                >
                  <ListItemText
                    primary="createDiamondHand()"
                    primaryTypographyProps={{
                      fontFamily: "Poppins",
                      fontStyle: "normal",
                      fontWeight: 400,
                      fontSize: {
                        lg: "18px",
                        xs: "14px",
                      },
                      lineHeight: "28px",
                      color: "#93A2A9",
                    }}
                  />
                  {codeTwoOpen ? (
                    <ExpandLess
                      sx={{
                        lg: {
                          width: "25px",
                          height: "25px",
                        },
                        sx: {
                          width: "20px",
                          height: "20px",
                        },
                        color: "#000000",
                        cursor: "pointer",
                      }}
                    />
                  ) : (
                    <ExpandMore
                      sx={{
                        lg: {
                          width: "25px",
                          height: "25px",
                        },
                        sx: {
                          width: "20px",
                          height: "20px",
                        },
                        color: "#000000",
                        cursor: "pointer",
                      }}
                    />
                  )}
                </ListItemButton>
                <Collapse in={codeTwoOpen} timeout="auto" unmountOnExit>
                  <List component="div" disablePadding>
                    <ListItemText>
                      <p className="FAQp2">
                        Creates a Diamond-Hand order with the relevant
                        information (your NFT's information, scheduled release
                        time, emergency unlock price), and transfers your NFT
                        into your DiamondVault.
                      </p>
                    </ListItemText>
                  </List>
                </Collapse>
                <ListItemButton
                  onClick={() => {
                    handleClick("code3");
                  }}
                >
                  <ListItemText
                    primary="breakUnlock()"
                    primaryTypographyProps={{
                      fontFamily: "Poppins",
                      fontStyle: "normal",
                      fontWeight: 400,
                      fontSize: {
                        lg: "18px",
                        xs: "14px",
                      },
                      lineHeight: "28px",
                      color: "#93A2A9",
                    }}
                  />
                  {codeThreeOpen ? (
                    <ExpandLess
                      sx={{
                        lg: {
                          width: "25px",
                          height: "25px",
                        },
                        sx: {
                          width: "20px",
                          height: "20px",
                        },
                        color: "#000000",
                        cursor: "pointer",
                      }}
                    />
                  ) : (
                    <ExpandMore
                      sx={{
                        lg: {
                          width: "25px",
                          height: "25px",
                        },
                        sx: {
                          width: "20px",
                          height: "20px",
                        },
                        color: "#000000",
                        cursor: "pointer",
                      }}
                    />
                  )}
                </ListItemButton>
                <Collapse in={codeThreeOpen} timeout="auto" unmountOnExit>
                  <List component="div" disablePadding>
                    <ListItemText>
                      <p className="FAQp2">
                        Checks if the emergency unlock price (set by you) has
                        been paid. If so, release the NFT back to you. Only you
                        (the vault owner) can call this function.
                      </p>
                    </ListItemText>
                  </List>
                </Collapse>
                <ListItemButton
                  onClick={() => {
                    handleClick("code4");
                  }}
                >
                  <ListItemText
                    primary="releaseDiamond()"
                    primaryTypographyProps={{
                      fontFamily: "Poppins",
                      fontStyle: "normal",
                      fontWeight: 400,
                      fontSize: {
                        lg: "18px",
                        xs: "14px",
                      },
                      lineHeight: "28px",
                      color: "#93A2A9",
                    }}
                  />
                  {codeFourOpen ? (
                    <ExpandLess
                      sx={{
                        lg: {
                          width: "25px",
                          height: "25px",
                        },
                        sx: {
                          width: "20px",
                          height: "20px",
                        },
                        color: "#000000",
                        cursor: "pointer",
                      }}
                    />
                  ) : (
                    <ExpandMore
                      sx={{
                        lg: {
                          width: "25px",
                          height: "25px",
                        },
                        sx: {
                          width: "20px",
                          height: "20px",
                        },
                        color: "#000000",
                        cursor: "pointer",
                      }}
                    />
                  )}
                </ListItemButton>
                <Collapse in={codeFourOpen} timeout="auto" unmountOnExit>
                  <List component="div" disablePadding>
                    <ListItemText>
                      <p className="FAQp2">
                        Checks if the current time is after the scheduled
                        release time (set by you). If so, release the NFT back
                        to you. Only you (the vault owner) can call this
                        function.
                      </p>
                    </ListItemText>
                  </List>
                </Collapse>
              </ListItemText>
            </List>
          </Collapse>
        </Box>

        <Box
          sx={{
            backgroundColor: "white",
            borderRadius: "10px",
            ":hover": {
              bgcolor: "white",
            },
            padding: "20px",
            marginTop: "30px",
            width: "80%",
            marginLeft: "auto",
            marginRight: "auto",
          }}
        >
          <ListItemButton
            onClick={() => {
              handleClick("q2");
            }}
            sx={{
              ":hover": {
                bgcolor: "white",
              },
            }}
          >
            <ListItemText
              primary="2. Can I withdraw my NFTs earlier than the scheduled release time?"
              primaryTypographyProps={{
                fontFamily: "Poppins",
                fontStyle: "normal",
                fontWeight: 400,
                fontSize: {
                  lg: "24px",
                  xs: "18px",
                },
                lineHeight: "36px",
                color: "#93A2A9",
              }}
            />
            {qTwoOpen ? (
              <ExpandLess
                sx={{
                  color: "#000000",
                  lg: {
                    width: "50px",
                    height: "50px",
                  },
                  xs: {
                    width: "25px",
                    height: "25px",
                  },
                }}
              />
            ) : (
              <ExpandMore
                sx={{
                  color: "#000000",
                  lg: {
                    width: "50px",
                    height: "50px",
                  },
                  xs: {
                    width: "25px",
                    height: "25px",
                    paddingRight: "0px",
                  },
                }}
              />
            )}
          </ListItemButton>
          <Collapse in={qTwoOpen} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>
              <ListItemText>
                <p className="FAQp">
                  Yes, but ONLY if you pay the emergency unlock price. This
                  price is set by you at the time of storing your NFTs away.
                  Note that the minimum unlock price is{" "}
                  <span className="eth">Ξ </span>
                  0.1.
                </p>
              </ListItemText>
            </List>
          </Collapse>
        </Box>

        <Box
          sx={{
            backgroundColor: "white",
            borderRadius: "10px",
            ":hover": {
              bgcolor: "white",
            },
            padding: "20px",
            marginTop: "30px",
            width: "80%",
            marginLeft: "auto",
            marginRight: "auto",
          }}
        >
          <ListItemButton
            onClick={() => {
              handleClick("q3");
            }}
            sx={{
              ":hover": {
                bgcolor: "white",
              },
            }}
          >
            <ListItemText
              primary="3. Do I need to pay?"
              primaryTypographyProps={{
                fontFamily: "Poppins",
                fontStyle: "normal",
                fontWeight: 400,
                fontSize: {
                  lg: "24px",
                  xs: "18px",
                },
                lineHeight: "36px",
                color: "#93A2A9",
              }}
            />
            {qThreeOpen ? (
              <ExpandLess
                sx={{
                  color: "#000000",
                  lg: {
                    width: "50px",
                    height: "50px",
                  },
                  xs: {
                    width: "25px",
                    height: "25px",
                  },
                }}
              />
            ) : (
              <ExpandMore
                sx={{
                  color: "#000000",
                  lg: {
                    width: "50px",
                    height: "50px",
                  },
                  xs: {
                    width: "25px",
                    height: "25px",
                    paddingRight: "0px",
                  },
                }}
              />
            )}
          </ListItemButton>
          <Collapse in={qThreeOpen} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>
              <ListItemText>
                <p className="FAQp">
                  Diamond-handing costs <span className="eth">Ξ </span>0.01.
                  However, it is FREE if you own a Diamond Pass!
                  <br /> <br />
                  You can mint a Diamond Pass{" "}
                  <Link to={"/diamondpass"} style={{ color: "#1D335F" }}>
                    here
                  </Link>{" "}
                  (supply capped at 5,000). First 500 passes are free!
                </p>
              </ListItemText>
            </List>
          </Collapse>
        </Box>

        <Box
          sx={{
            backgroundColor: "white",
            borderRadius: "10px",
            ":hover": {
              bgcolor: "white",
            },
            padding: "20px",
            marginTop: "30px",
            width: "80%",
            marginLeft: "auto",
            marginRight: "auto",
          }}
        >
          <ListItemButton
            onClick={() => {
              handleClick("q4");
            }}
            sx={{
              ":hover": {
                bgcolor: "white",
              },
            }}
          >
            <ListItemText
              primary="4. If my diamond-handed NFT does an airdrop, will I receive it?"
              primaryTypographyProps={{
                fontFamily: "Poppins",
                fontStyle: "normal",
                fontWeight: 400,
                fontSize: {
                  lg: "24px",
                  xs: "18px",
                },
                lineHeight: "36px",
                color: "#93A2A9",
              }}
            />
            {qFourOpen ? (
              <ExpandLess
                sx={{
                  color: "#000000",
                  lg: {
                    width: "50px",
                    height: "50px",
                  },
                  xs: {
                    width: "25px",
                    height: "25px",
                  },
                }}
              />
            ) : (
              <ExpandMore
                sx={{
                  color: "#000000",
                  lg: {
                    width: "50px",
                    height: "50px",
                  },
                  xs: {
                    width: "25px",
                    height: "25px",
                    paddingRight: "0px",
                  },
                }}
              />
            )}
          </ListItemButton>
          <Collapse in={qFourOpen} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>
              <ListItemText>
                <p className="FAQp">
                  YES! A benefit of having your own DiamondVault is that if your
                  diamond-handed assets receive an airdrop, the airdrop would go
                  into your DiamondVault. You could check for airdrops{" "}
                  <a
                    className="FAQa"
                    href="https://diamond-hand.me/vault-airdrop"
                  >
                    here
                  </a>
                  . Alternatively, there are four functions in your DiamondVault
                  contract that helps you claim any airdrop: WithdrawERC721,
                  WithdrawERC1155, WithdrawERC20, and WithdrawETH. <br></br>{" "}
                  These functions are only callable by the vault owner (you),
                  and they can be called at anytime (you don't have to wait for
                  your scheduled release date to claim your airdrops)
                </p>
              </ListItemText>
            </List>
          </Collapse>
        </Box>

        <Box
          sx={{
            backgroundColor: "white",
            borderRadius: "10px",
            ":hover": {
              bgcolor: "white",
            },
            padding: "20px",
            marginTop: "30px",
            width: "80%",
            marginLeft: "auto",
            marginRight: "auto",
          }}
        >
          <ListItemButton
            onClick={() => {
              handleClick("q5");
            }}
            sx={{
              ":hover": {
                bgcolor: "white",
              },
            }}
          >
            <ListItemText
              primary="5. What is coming next?"
              primaryTypographyProps={{
                fontFamily: "Poppins",
                fontStyle: "normal",
                fontWeight: 400,
                fontSize: {
                  lg: "24px",
                  xs: "18px",
                },
                lineHeight: "36px",
                color: "#93A2A9",
              }}
            />
            {qFiveOpen ? (
              <ExpandLess
                sx={{
                  color: "#000000",
                  lg: {
                    width: "50px",
                    height: "50px",
                  },
                  xs: {
                    width: "25px",
                    height: "25px",
                  },
                }}
              />
            ) : (
              <ExpandMore
                sx={{
                  color: "#000000",
                  lg: {
                    width: "50px",
                    height: "50px",
                  },
                  xs: {
                    width: "25px",
                    height: "25px",
                    paddingRight: "0px",
                  },
                }}
              />
            )}
          </ListItemButton>
          <Collapse in={qFiveOpen} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>
              <ListItemText>
                <p className="FAQp">
                  We are working on allowing you to also diamond-hand your ERC20
                  tokens, as well as ETH (I know my ETH sometimes randomly turn
                  into JPEGS 😅, so this would be a very useful feature for
                  myself.) <br /> <br /> Oh and something else might be coming?
                  💎 🤫
                </p>
              </ListItemText>
            </List>
          </Collapse>
        </Box>
      </List>
    </Box>
  );
}
