import { useEffect, useState, useRef } from "react";
import { useLocation } from "react-router-dom";
import { Body } from "../components";
import NavBarHome from "../components/Home/NavBarHome";
import { formatDuration, intervalToDuration } from "date-fns";
import Web3 from "web3-utils";
import { ethers } from "ethers";

import ReactHtmlParser from "react-html-parser";
import { startGrid } from "../components/Styles/GridWave";
import ipfsToUrl from "../extensions/ipfsToUrl";

//Contract
import { Contract } from "@ethersproject/contracts";
import {
  contractFactoryAbi,
  contractFactoryAddress,
  vaultAbi,
} from "./SpecificNFT";

import { useAccount, useSigner, useNetwork } from "wagmi";

//Components
import { Dialog, DialogContent, Button, Box, Tooltip } from "@mui/material";
import CircularIndeterminate from "../components/CircularIndeterminate";
import ErrorAlert from "../components/Modals/ErrorAlert";
import Footer from "../components/Footer";
import ShareableCard from "../components/Modals/ShareableCard";

import NoImage from "../images/no-image.png";

function DiamondNFT() {
  const location = useLocation();
  const diamondID = location.state?.asset[0];
  const nftAsset = location.state?.asset[3][0];
  const diamondList = location.state?.asset[4];

  const { isCurrentUsers } = location.state;

  const [address, setAddress] = useState("");
  const { data: signer } = useSigner();

  //For transaction UI
  const [startTransaction, setStartTransaction] = useState(false);
  const [transactionSuccessful, setTransactionSuccessful] = useState(false);
  const [transactionTitle, setTransactionTitle] = useState("");
  const [totalDuration, setTotalDuration] = useState("");
  const [isEmergencyBreak, setIsEmergencyBreak] = useState(false);

  //For displaying alerts
  const [alertTitle, setAlertTitle] = useState("");
  const [alertMessage, setAlertMessage] = useState("");
  const [showingAlert, setShowingAlert] = useState(false);
  const [chainError, setChainError] = useState(false);

  //Tx hash
  const [txHash, setTxHash] = useState("");

  //Load gridwave animation
  useEffect(() => {
    startGrid();
  }, []);

  //Wallet
  const account = useAccount({
    onConnect({ address }) {
      setAddress(address);
    },
    onDisconnect() {
      setAddress("");
    },
  });

  useEffect(() => {
    //Make sure account updates
    if (account.isConnected) {
      setAddress(account.address);
    } else if (account.isDisconnected) {
      setAddress("");
    }
  }, [account]);

  const { chain, chains } = useNetwork();
  useEffect(() => {
    if (account.isConnected) {
      //Check if chain is supported
      if (chain.unsupported) {
        //Disable buttons
        setChainError(true);
      } else {
        setChainError(false);
      }
    }
  }, [chain, account]);

  useEffect(() => {
    //Calculate total diamond-hand duration
    let startTime = ethers.BigNumber.from(diamondList[1]).toNumber() * 1000;
    let releaseTime = ethers.BigNumber.from(diamondList[2]).toNumber() * 1000;
    let currentTime = Date.now();
    let endTime = currentTime > releaseTime ? currentTime : releaseTime;
    let duration = intervalToDuration({
      start: startTime,
      end: endTime,
    });

    const formatted = formatDuration(duration, {
      delimiter: ", ",
    });
    setTotalDuration(formatted);
  }, [diamondList]);

  function handleError(error) {
    if (error.code === "ACTION_REJECTED") {
      //Ignore
      //Closing step dialog
      setStartTransaction(false);
    } else {
      setAlertTitle("Error");
      setAlertMessage("Unlocking failed, please try again later");
      setShowingAlert(true);
      setStartTransaction(false);
      //Scroll to the top so user sees error message
      window.scrollTo(0, 0);
    }
  }

  async function emergencyUnlock(unlockPrice) {
    //Get vault address
    const diamondFactoryContract = new Contract(
      contractFactoryAddress,
      contractFactoryAbi,
      signer
    );
    const fetchedVaultAddress = (
      await diamondFactoryContract.getVaultAddresses(address)
    )[0];
    if (fetchedVaultAddress !== "") {
      //UI
      setStartTransaction(true);
      setTransactionTitle("Emergency Unlocking");

      const diamondContract = new Contract(
        fetchedVaultAddress,
        vaultAbi,
        signer
      );

      await diamondContract
        .breakUnlock(diamondID, {
          value: unlockPrice,
          // gasPrice: gasPrice._hex,
        })
        .then(
          (receipt) => {
            //Send receipt to backend to listen to transaction receipt and add to DB
            fetch(
              (process.env.REACT_APP_ENV === "production" ||
              process.env.REACT_APP_ENV === "sandbox"
                ? process.env.REACT_APP_HOST
                : "http://localhost:3001") + "/emergency_unlock_receipt",
              {
                method: "POST",
                headers: {
                  "Content-Type": "application/json",
                },
                body: JSON.stringify({
                  receipt,
                  fetchedVaultAddress,
                }),
              }
            ).then((response) => {
              return response.text();
            });
            finishUnlock(receipt);
            setIsEmergencyBreak(true);
          },
          (error) => {
            handleError(error);
          }
        );
    }
  }

  async function finishUnlock(unlockHash) {
    await unlockHash.wait().then(
      (receipt) => {
        // This is entered if the transaction receipt indicates success
        setTransactionSuccessful(true);
        setTransactionTitle(
          "Your NFT is now back in your wallet! <br></br> See <a href=https://" +
            (process.env.REACT_APP_ENV === "sandbox" ||
            process.env.REACT_APP_ENV === "dev"
              ? "goerli."
              : "") +
            "etherscan.io/tx/" +
            unlockHash.hash +
            "> transaction </a>"
        );
        //Hide modal
        setStartTransaction(false);
        setTxHash(unlockHash.hash);
      },
      (error) => {
        // This is entered if the status of the receipt is failure
        handleError(error);
      }
    );
  }

  async function releaseNFT() {
    //Get vault address
    const diamondFactoryContract = new Contract(
      contractFactoryAddress,
      contractFactoryAbi,
      signer
    );
    const fetchedVaultAddress = (
      await diamondFactoryContract.getVaultAddresses(address)
    )[0];
    setStartTransaction(true);
    setTransactionTitle("Withdrawing...");

    const diamondContract = new Contract(fetchedVaultAddress, vaultAbi, signer);
    await diamondContract.releaseDiamond(Number(diamondID)).then(
      (receipt) => {
        //Send receipt to backend to listen to transaction receipt and add to DB
        fetch(
          (process.env.REACT_APP_ENV === "production" ||
          process.env.REACT_APP_ENV === "sandbox"
            ? process.env.REACT_APP_HOST
            : "http://localhost:3001") + "/release_unlock_receipt",
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
              receipt,
              fetchedVaultAddress,
            }),
          }
        ).then((response) => {
          return response.text();
        });
        finishUnlock(receipt);
      },
      (error) => {
        handleError(error);
      }
    );
  }

  //Fetch vault address
  // useEffect(() => {
  //   if (address !== "" && isCurrentUsers && signer) {
  //     fetchVault();
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [address, signer, chainError]);

  //For timer
  const [timeLeft, setTimeLeft] = useState("");
  const timerRef = useRef(null);

  useEffect(() => {
    function calculateTimeLeft() {
      const releaseTime =
        ethers.BigNumber.from(diamondList[2]).toNumber() * 1000;
      const now = new Date();
      if (now >= releaseTime) {
        return "Unlocked!";
      }

      let duration = intervalToDuration({
        start: new Date(),
        end: releaseTime,
      });

      const formatted = formatDuration(duration, {
        delimiter: ", ",
      });

      return formatted;
    }

    timerRef.current = setInterval(() => {
      setTimeLeft(calculateTimeLeft());
    }, 1000);
    return () => clearInterval(timerRef.current);
  }, [diamondList]);

  useEffect(() => {
    if (transactionSuccessful) {
      //Stop timer
      clearInterval(timerRef.current);
    }
  }, [transactionSuccessful]);

  return (
    <>
      <Body>
        <div className="grid-wave-container-2">
          <div className="grid-wave w-full h-full bg-blue-1"></div>
          <div className="grid-wave-content">
            <NavBarHome />
            <ErrorAlert
              title={alertTitle}
              message={alertMessage}
              open={showingAlert}
              updateOpen={setShowingAlert}
            />
            <Box
              sx={{
                width: "80%",
                height: "100%",
                background: "#1D335F",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                borderRadius: "20px",
                paddingTop: "15vh",
                paddingBottom: "15vh",
                marginX: "auto",
              }}
            >
              <h2 style={{ textAlign: "center", color: "white" }}>
                {nftAsset.collection.name
                  ? nftAsset.collection.name
                  : nftAsset.contract_address.substring(0, 6) +
                    "..." +
                    nftAsset.contract_address.substring(36)}
              </h2>
              <h2 style={{ textAlign: "center", color: "white" }}>
                {nftAsset.name
                  ? nftAsset.name
                  : nftAsset.collection.name
                  ? nftAsset.collection.name + " " + nftAsset.token_id
                  : "Metadata Unavailable"}
              </h2>
              <img
                src={
                  nftAsset.image_url
                    ? nftAsset.image_url
                    : nftAsset.extra_metadata.image_original_url
                    ? ipfsToUrl(nftAsset.extra_metadata.image_original_url)
                      ? ipfsToUrl(nftAsset.extra_metadata.image_original_url)
                      : NoImage
                    : NoImage
                }
                onError={({ currentTarget }) => {
                  currentTarget.onerror = null; // prevents looping
                  currentTarget.src = NoImage;
                }}
                alt="NFT Not Found"
                className="NFT-Image"
              />
              <h2 style={{ textAlign: "center", color: "white" }}>
                Time remaining until unlock:
              </h2>
              <h2 style={{ textAlign: "center", color: "white" }}>
                {timeLeft}
              </h2>
              {timeLeft === "Unlocked!" ||
              timeLeft === "" ||
              !isCurrentUsers ? null : (
                <Tooltip
                  title={
                    chainError
                      ? "Please switch your wallet to the " +
                        chains[0]?.name +
                        " network!"
                      : address === ""
                      ? "Your wallet is not connected"
                      : "Emergency Unlock your NFT!"
                  }
                >
                  <div>
                    <Button
                      disabled={chainError || address === ""}
                      onClick={() =>
                        emergencyUnlock(ethers.BigNumber.from(diamondList[3]))
                      }
                      // hidden={timeLeft === "Unlocked!" || !isCurrentUsers}
                      variant="contained"
                      sx={{
                        display:
                          timeLeft === "Unlocked!" || !isCurrentUsers
                            ? "none"
                            : "block",
                        color: "white",
                        textTransform: "none",
                        background: "#08121b",
                        "&:hover": {
                          backgroundColor: "black",
                        },
                        ":disabled": {
                          backgroundColor: "gray",
                        },
                        height: "80px",
                        marginTop: "20px",
                        marginLeft: "auto",
                        marginRight: "auto",
                        width: 250,
                        fontSize: 21,
                        fontFamily: "Poppins",
                        borderRadius: "20px",
                      }}
                    >
                      Emergency Unlock for <span className="eth">Ξ</span>{" "}
                      {Web3.fromWei(
                        ethers.BigNumber.from(diamondList[3]).toString(),
                        "ether"
                      )}
                    </Button>
                  </div>
                </Tooltip>
              )}
              {timeLeft !== "Unlocked!" || !isCurrentUsers ? null : (
                <Tooltip
                  title={
                    chainError
                      ? "Please switch your wallet to the " +
                        chains[0]?.name +
                        " network!"
                      : address === ""
                      ? "Your wallet is not connected"
                      : "Withdraw your NFT!"
                  }
                >
                  <div>
                    <Button
                      disabled={chainError || address === ""}
                      onClick={() => releaseNFT()}
                      // hidden={timeLeft !== "Unlocked!" || !isCurrentUsers}
                      sx={{
                        display:
                          timeLeft !== "Unlocked!" || !isCurrentUsers
                            ? "none"
                            : "block",
                        color: "white",
                        textTransform: "none",
                        background: "#08121b",
                        "&:hover": {
                          backgroundColor: "black",
                        },
                        ":disabled": {
                          backgroundColor: "gray",
                        },
                        height: "80px",
                        marginTop: "20px",
                        marginLeft: "auto",
                        marginRight: "auto",
                        width: 250,
                        fontSize: 21,
                        fontFamily: "Poppins",
                        borderRadius: "20px",
                      }}
                    >
                      Withdraw
                    </Button>
                  </div>
                </Tooltip>
              )}
            </Box>
            <Dialog
              open={startTransaction}
              keepMounted
              // onClose={}
              aria-describedby="alert-dialog-slide-description"
            >
              <DialogContent>
                <div
                  style={{
                    textAlign: "center",
                  }}
                >
                  <h2>{ReactHtmlParser(transactionTitle)}</h2>
                  <div
                    style={{
                      display: "inline-flex",
                      alignItems: "center",
                    }}
                  >
                    <div hidden={transactionSuccessful}>
                      <CircularIndeterminate />
                    </div>
                    <div hidden={!transactionSuccessful}>
                      <Button
                        style={{
                          margin: "0 auto",
                          display: "flex",
                          textDecoration: "none",
                          textTransform: "none",
                          fontFamily: "Poppins",
                          fontStyle: "normal",
                          fontWeight: 400,
                          fontSize: "22px",
                          color: "white",
                          "&:hover": {
                            backgroundColor: "black",
                          },
                          backgroundColor: "black",
                          borderRadius: "30px",
                          paddingLeft: "15px",
                          paddingRight: "15px",
                        }}
                        href="/profile"
                      >
                        Done!
                      </Button>
                    </div>
                  </div>
                </div>
              </DialogContent>
            </Dialog>
            <ShareableCard
              assetTitle={
                nftAsset.name
                  ? nftAsset.name
                  : nftAsset.collection.name
                  ? nftAsset.collection.name + " " + nftAsset.token_id
                  : "Metadata Unavailable"
              }
              collectionTitle={
                nftAsset.collection.name
                  ? nftAsset.collection.name
                  : nftAsset.contract_address.substring(0, 6) +
                    "..." +
                    nftAsset.contract_address.substring(36)
              }
              endDate={""}
              duration={totalDuration}
              imageURL={
                nftAsset.image_url
                  ? nftAsset.image_url
                  : nftAsset.extra_metadata.image_original_url
                  ? ipfsToUrl(nftAsset.extra_metadata.image_original_url)
                    ? ipfsToUrl(nftAsset.extra_metadata.image_original_url)
                    : NoImage
                  : NoImage
              }
              ranking={0}
              rankingURL={
                "https://diamond-hand.me/stats/" + nftAsset.contract_address
              }
              showing={transactionSuccessful}
              walletAddress={address}
              isComplete={true}
              isEmergencyBreak={isEmergencyBreak}
              transactionHash={txHash}
            />
            <Footer></Footer>
          </div>
        </div>
      </Body>
    </>
  );
}

export default DiamondNFT;
