import { useEffect, useState } from "react";

import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";

import paperHand from "../../images/whyUse/paperhand_large.svg";
import paperHand2 from "../../images/whyUse/paperhand_small.svg";

export default function WhyUse() {
  const [azukiFloor, setAzukiFloor] = useState(25);
  const [coolCatsFloor, setCoolCatsFloor] = useState(7.7);
  const [pudgyFloor, setPudgyFloor] = useState(2);
  const [checksFloor, setChecksFloor] = useState(1.97);

  useEffect(() => {
    const fetchData = () => {
      const collectionSlugs = [
        "azuki",
        "cool-cats-nft",
        "pudgypenguins",
        "vv-checks",
      ];

      for (let i = 0; i < collectionSlugs.length; i++) {
        const options = {
          method: "GET",
          headers: { Accept: "application/json" },
        };
        const fetchString =
          "https://api.opensea.io/api/v1/collection/" +
          collectionSlugs[i] +
          "/stats";
        fetch(fetchString, options)
          .then((response) => response.json())
          .then((response) => {
            switch (i) {
              case 0:
                setAzukiFloor(response.stats.floor_price.toFixed(2));
                break;
              case 1:
                setCoolCatsFloor(response.stats.floor_price.toFixed(2));
                break;
              case 2:
                setPudgyFloor(response.stats.floor_price.toFixed(2));
                break;
              case 3:
                setChecksFloor(response.stats.floor_price.toFixed(2));
                break;
              default:
                break;
            }
          })
          .catch((err) => console.error(err));
      }
    };
    fetchData();
  }, []);

  return (
    <Box
      sx={{
        width: "100%",
        height: "auto",
        background: "white",
        paddingBottom: "50px",
      }}
    >
      <Grid container width={"100%"} marginLeft={"auto"} marginRight={"auto"}>
        <Grid
          item
          xs={12}
          lg={6}
          xl={7}
          sx={{
            display: {
              xs: "none",
              lg: "flex",
            },
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <img src={paperHand} className="why_use_paperhand" alt="paper hand" />
        </Grid>
        <Grid item xs={12} lg={6} xl={5} sx={{ paddingLeft: "2vw" }}>
          <h2 className="why_use_title">
            Why would I use this? I can just keep NFTs in my wallet!
          </h2>
          <p className="why_use_p">
            That's what I thought too, but then over time, I sold:
          </p>
          <div className="why_use_sale_container">
            <div className="why_use_sale_item" id="wus1">
              <div className="why_use_sale_left">
                <p className="why_use_sale_label">
                  <span className="why_use_sale_emphasis">Azuki</span> at{" "}
                  <span className="eth">Ξ</span>2.41
                </p>
              </div>
              <div className="horizontal_dotted_line"></div>
              <div className="why_use_sale_right">
                <p className="why_use_sale_label">
                  now{" "}
                  <span className="why_use_sale_emphasis2">
                    <span className="eth">Ξ</span>
                    {azukiFloor}
                  </span>
                </p>
              </div>
            </div>
            <div className="why_use_sale_item" id="wus2">
              <div className="why_use_sale_left">
                <p className="why_use_sale_label">
                  <span className="why_use_sale_emphasis">Checks</span> at{" "}
                  <span className="eth">Ξ</span>0.008
                </p>
              </div>
              <div className="horizontal_dotted_line"></div>
              <div className="why_use_sale_right">
                <p className="why_use_sale_label">
                  now{" "}
                  <span className="why_use_sale_emphasis2">
                    <span className="eth">Ξ</span>
                    {checksFloor}
                  </span>
                </p>
              </div>
            </div>
            <div className="why_use_sale_item" id="wus3">
              <div className="why_use_sale_left">
                <p className="why_use_sale_label">
                  <span className="why_use_sale_emphasis">Pudgy Penguins</span>{" "}
                  at <span className="eth">Ξ</span>0.12
                </p>
              </div>
              <div className="horizontal_dotted_line"></div>
              <div className="why_use_sale_right">
                <p className="why_use_sale_label">
                  now{" "}
                  <span className="why_use_sale_emphasis2">
                    <span className="eth">Ξ</span>
                    {pudgyFloor}
                  </span>
                </p>
              </div>
            </div>
            <div className="why_use_sale_item" id="wus4">
              <div className="why_use_sale_left">
                <p className="why_use_sale_label">
                  <span className="why_use_sale_emphasis">Cool Cats</span> at{" "}
                  <span className="eth">Ξ</span>0.585
                </p>
              </div>
              <div className="horizontal_dotted_line"></div>
              <div className="why_use_sale_right">
                <p className="why_use_sale_label">
                  now{" "}
                  <span className="why_use_sale_emphasis2">
                    <span className="eth">Ξ</span>
                    {coolCatsFloor}
                  </span>
                </p>
              </div>
            </div>
          </div>
          <p className="why_use_p">and a lot more 😭 </p>
          <p className="why_use_p">
            I know I'm not the only one. I wished there was a tool that
            eliminated my emotions from my trades, a tool that made sure I don't
            paper-hand or panic-sell projects I believe in. I couldn't really
            find anything out there, so I decided to build it.
          </p>
          <p className="why_use_p">
            I hope this tool would be useful for you too! Yes, we are in a bear
            market and many projects have fallen from their ATH, but... isn't
            this a perfect time to diamond-hand?
          </p>
          <p className="why_use_p">
            Concerned about safety? You should be! Read the FAQ below!
          </p>
        </Grid>
        <Grid
          item
          xs={12}
          sx={{
            display: {
              xs: "flex",
              lg: "none",
            },
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <img
            src={paperHand2}
            className="why_use_paperhand2"
            alt="paper hand"
          />
        </Grid>
      </Grid>
    </Box>
  );
}
