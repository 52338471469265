import React from "react";

//Routing
import { Routes, Route, Navigate } from "react-router-dom";

//Pages
import Home from "./pages/Home";
import SpecificNFT from "./pages/SpecificNFT";
import Profile from "./pages/Profile";
import DiamondNFT from "./pages/DiamondNFT";
import Start from "./pages/Start";
import Stats from "./pages/Stats";
import SpecificCollectionStats from "./pages/SpecificCollectionStats";
import Mint from "./pages/Mint";
import Airdrops from "./pages/Airdrops";
import TermsOfUse from "./pages/TermsOfUse";
import PrivacyPolicy from "./pages/PrivacyPolicy";
import DiamondAirdrop from "./pages/DiamondAirdrop";

function App() {
  return (
    <div>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="start" element={<Start />} />
        <Route path="diamond-handing" element={<SpecificNFT />} />
        <Route path="diamond-asset" element={<DiamondNFT />} />
        <Route path="profile" element={<Profile />} />
        <Route path="stats" element={<Stats />} />
        <Route path="stats/:collection_address">
          <Route path="" element={<SpecificCollectionStats />} />
          <Route path=":token_id" element={<SpecificCollectionStats />} />
        </Route>
        <Route path="profile/:specified_address" element={<Profile />} />
        <Route path="diamondpass" element={<Mint />} />
        <Route path="vault-airdrop" element={<Airdrops />} />
        <Route path="terms" element={<TermsOfUse />} />
        <Route path="privacy" element={<PrivacyPolicy />} />
        {/* <Route path="airdrop" element={<DiamondAirdrop />} /> */}
        <Route path="*" element={<Navigate to="/" />} />
      </Routes>
    </div>
  );
}

export default App;
