import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Slide from "@mui/material/Slide";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function ConfirmationAlert({
  open,
  updateOpen,
  title,
  message,
  confirmLabel,
  declineLabel,
  onConfirm,
  onDecline,
}) {
  const declined = () => {
    updateOpen(false);
    onDecline();
  };
  const confirmed = () => {
    updateOpen(false);
    onConfirm();
  };

  return (
    <div>
      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={declined}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle sx={{ fontFamily: "Poppins" }}>{title}</DialogTitle>
        <DialogContent>
          <DialogContentText
            id="alert-dialog-slide-description"
            sx={{ whiteSpace: "pre-line", fontFamily: "Poppins" }}
          >
            {message}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={declined} sx={{ color: "gray" }}>
            {declineLabel}
          </Button>
          <Button onClick={confirmed}>{confirmLabel}</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
