import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Slide from "@mui/material/Slide";
import NoImage from "../../images/no-image.png";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function ClaimAlert({
  open,
  updateOpen,
  title,
  message,
  imageURL,
  confirmLabel,
  declineLabel,
  onConfirm,
  onDecline,
}) {
  const declined = () => {
    updateOpen(false);
    onDecline();
  };
  const confirmed = () => {
    updateOpen(false);
    onConfirm();
  };

  return (
    <div>
      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={declined}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle
          sx={{
            fontFamily: "Poppins",
            textAlign: "center",
            fontWeight: "550",
          }}
        >
          {title}
        </DialogTitle>
        <DialogContent>
          <img
            src={imageURL === "" ? NoImage : imageURL}
            alt="NFT"
            className="NFT-Image"
            onError={({ currentTarget }) => {
              currentTarget.onerror = null; // prevents looping
              currentTarget.src = NoImage;
            }}
          />
          <DialogContentText
            id="alert-dialog-slide-description"
            sx={{
              whiteSpace: "pre-line",
              fontFamily: "Poppins",
              marginTop: "40px",
              textAlign: "center",
            }}
          >
            {message}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={declined} sx={{ color: "gray" }}>
            {declineLabel}
          </Button>
          <Button onClick={confirmed}>{confirmLabel}</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
