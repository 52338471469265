import "./index.css";

import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";
import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";

import App from "./App";
import "@rainbow-me/rainbowkit/styles.css";
import {
  getDefaultWallets,
  RainbowKitProvider,
  darkTheme,
} from "@rainbow-me/rainbowkit";
import { chain, configureChains, createClient, WagmiConfig } from "wagmi";
import { infuraProvider } from "wagmi/providers/infura";
import { publicProvider } from "wagmi/providers/public";
import CustomAvatar from "./components/WalletButton/CustomAvatar";

//Sentry setup
Sentry.init({
  dsn: "https://6a4937b136614e8ab2efdb115ce32cf8@o4503962775650304.ingest.sentry.io/4503962786660352",
  integrations: [new BrowserTracing()],

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 1.0,
});

//Wallet setup
const { chains, provider } = configureChains(
  process.env.REACT_APP_ENV === "sandbox" || process.env.REACT_APP_ENV === "dev"
    ? [chain.goerli]
    : [chain.mainnet],
  [
    infuraProvider({ apiKey: process.env.REACT_APP_INFURA_ID, priority: 0 }),
    publicProvider({ priority: 1 }),
  ]
);

const { connectors } = getDefaultWallets({
  appName: "Diamond-Hand.Me",
  chains,
});

const wagmiClient = createClient({
  autoConnect: true,
  connectors,
  provider,
});

// const Disclaimer: DisclaimerComponent = ({ Text, Link }) => (
//   <Text>
//     By connecting your wallet, you agree to the{" "}
//     <Link href="https://termsofservice.xyz">Terms of Service</Link> and
//     acknowledge you have read and understand the protocol{" "}
//     <Link href="https://disclaimer.xyz">Disclaimer</Link>
//   </Text>
// );

const Disclaimer = () => {
  return (
    <p style={{ fontFamily: "Open Sans", color: "white" }}>
      By connecting your wallet, you agree to the{" "}
      <a
        style={{ fontFamily: "Open Sans", color: "#48cae4" }}
        href="https://diamond-hand.me/terms"
      >
        Terms of Use
      </a>{" "}
      and understand that your use of our platform shall be at your sole risk.{" "}
    </p>
  );
};

ReactDOM.render(
  <BrowserRouter>
    <WagmiConfig client={wagmiClient}>
      <RainbowKitProvider
        chains={chains}
        theme={darkTheme()}
        avatar={CustomAvatar}
        appInfo={{
          appName: "Diamond-Hand.Me",
          disclaimer: Disclaimer,
        }}
        coolMode
      >
        <App />
      </RainbowKitProvider>
    </WagmiConfig>
  </BrowserRouter>,
  document.getElementById("root")
);
