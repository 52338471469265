import * as React from "react";
import CircularProgress from "@mui/material/CircularProgress";
import Box from "@mui/material/Box";

export default function CircularIndeterminate({ size }) {
  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        marginX: "auto",
        marginY: "auto",
      }}
    >
      <CircularProgress
        size={size}
        sx={{ color: "#24272d", maxWidth: "100px" }}
      />
    </Box>
  );
}
