import * as React from "react";
import { Button, Box, Modal, IconButton, Tooltip } from "@mui/material";
import ConfettiExplosion from "react-confetti-explosion";
import { Launch } from "@mui/icons-material";
import diamondPass from "../../images/diamond_pass.gif";

export default function SuccessfulMint({ showing, transactionHash }) {
  return (
    <Modal open={showing} sx={{ display: "flex" }}>
      <Box
        sx={{
          bgcolor: "background.paper",
          width: "60vw",
          //   height: "80vh",
          marginX: "auto",
          marginY: "auto",
          textAlign: "center",
          borderRadius: "10px",
          paddingBottom: "20px",
        }}
      >
        <Box sx={{ display: "flex", justifyContent: "center" }}>
          <Box sx={{ width: "60px" }}></Box>
          <h3 style={{ color: "black" }}>Diamond Pass Minted!</h3>
          <Tooltip title="See Transaction">
            <IconButton
              size="large"
              aria-label="Go to Vault"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={() => {
                let url =
                  process.env.REACT_APP_ENV === "sandbox" ||
                  process.env.REACT_APP_ENV === "dev"
                    ? "https://goerli.etherscan.io/tx/" + transactionHash
                    : "https://etherscan.io/tx/" + transactionHash;
                window.open(url);
              }}
              sx={{
                height: "60px",
                width: "60px",
                marginTop: "auto",
                marginBottom: "auto",
              }}
            >
              <Launch sx={{ color: "#93a2a9" }}></Launch>
            </IconButton>
          </Tooltip>
        </Box>
        {showing && (
          <Box
            sx={{
              width: "100%",
              justifyContent: "center",
              display: "flex",
            }}
          >
            <ConfettiExplosion floorHeight={2000} />
          </Box>
        )}
        <img
          className="Diamondhanded-Image"
          src={diamondPass}
          alt="Diamond-Pass"
        />
        <p className="p-shareablecard">
          Congratulations! You have successfully minted a Diamond Pass! You can
          now use this site for free, forever!
        </p>
        <br></br>
        <Button
          style={{
            margin: "0 auto",
            width: "25vw",
            maxWidth: 400,
            minWidth: 200,
            minHeight: 65,
            display: "flex",
            textDecoration: "none",
            textTransform: "none",
            fontFamily: "Poppins",
            fontStyle: "normal",
            fontWeight: 400,
            fontSize: "22px",
            color: "white",
            "&:hover": {
              backgroundColor: "black",
            },
            backgroundColor: "black",
            borderRadius: "30px",
            paddingLeft: "15px",
            paddingRight: "15px",
          }}
          href="/start"
        >
          Start Diamond-Handing!
        </Button>
      </Box>
    </Modal>
  );
}
