import * as React from "react";
import { Box, IconButton, Tooltip } from "@mui/material";
import {
  Facebook,
  Twitter,
  Telegram,
  Link,
  Download,
} from "@mui/icons-material";
import { CopyToClipboard } from "react-copy-to-clipboard";
import domtoimage from "dom-to-image";

export default function SocialShare({ message, address, showDownload }) {
  const websiteURL = "https://diamond-hand.me";
  const websiteURLEncoded = encodeURIComponent(websiteURL);
  return (
    <Box sx={{ display: "flex", width: "100%", justifyContent: "center" }}>
      <Tooltip title="Copy the link to your profile">
        <IconButton
          sx={{
            width: 40,
            height: 40,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <CopyToClipboard text={websiteURL + "/profile/" + address}>
            <Link sx={{ width: 30, height: 30 }} />
          </CopyToClipboard>
        </IconButton>
      </Tooltip>
      <IconButton
        sx={{
          width: 40,
          height: 40,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
        href={
          "https://www.facebook.com/sharer/sharer.php?u=" + websiteURLEncoded
        }
      >
        <Facebook sx={{ width: 30, height: 30 }} />
      </IconButton>
      <IconButton
        sx={{
          width: 40,
          height: 40,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
        href={
          "https://twitter.com/intent/tweet?url=" +
          websiteURLEncoded +
          "&text=" +
          encodeURIComponent(message) +
          encodeURIComponent(" You can do the same at ") +
          "&via=DiamondHandMe"
        }
      >
        <Twitter sx={{ width: 30, height: 30 }} />
      </IconButton>
      <IconButton
        sx={{
          width: 40,
          height: 40,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
        href={
          "https://telegram.me/share/?url=" +
          websiteURLEncoded +
          "&text=" +
          encodeURIComponent(message)
        }
      >
        <Telegram sx={{ width: 30, height: 30 }} />
      </IconButton>
      {showDownload && (
        <Tooltip title="Download This Image!">
          <IconButton
            sx={{
              width: 40,
              height: 40,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
            onClick={() => {
              function filter(node) {
                return node.id !== "tx-link";
              }
              var shareElement = document.getElementById("share-card");
              domtoimage
                .toPng(shareElement, { filter: filter })
                .then(function (dataUrl) {
                  var link = document.createElement("a");
                  link.download = "diamond-hand.png";
                  link.href = dataUrl;
                  link.click();
                });
            }}
          >
            <Download sx={{ color: "#f4a261" }} />
          </IconButton>
        </Tooltip>
      )}
    </Box>
  );
}
