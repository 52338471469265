import * as React from "react";
import howItWorksBackground from "../../images/howItWorks/howItWorksBackground.svg";
import clock from "../../images/howItWorks/clock.svg";
import vault from "../../images/howItWorks/vaultImage.png";
import logo from "../../images/logoWhite.png";
import Box from "@mui/material/Box";

import Grid from "@mui/material/Grid";

//Carousel for mobile
import Carousel from "react-material-ui-carousel";

function Item(props) {
  return (
    <Box sx={{ width: "80%", height: "auto", marginX: "auto" }}>
      <div className="hiw_card">
        <div className="hiw_circle">
          <img
            src={props.item.image}
            className="hiw_image"
            alt="how it works"
          />
        </div>
        <h2 className="hiw_label">{props.item.label}</h2>
      </div>
    </Box>
  );
}

export default function HowItWorks() {
  var items = [
    {
      image: clock,
      label: "Set a duration",
    },
    {
      image: vault,
      label: "Store your NFT in our time-locked smart contract",
    },
    {
      image: logo,
      label: "That’s it! Diamond-Hand it until your scheduled time!",
    },
  ];

  return (
    // <Box style={styles.boxContainer}>
    <Box
      sx={{
        backgroundImage: { md: `url(${howItWorksBackground})` },
        // height: "600px",
        width: "80vw",
        borderRadius: "20px",

        position: "absolute",
        left: "50%",
        transform: "translate(-50%, -50%)",
        top: {
          xs: "max(120vh, 1400px)",
          sm: "max(130vh, 1520px)",
          md: "max(95vh, 1400px)",
        },
      }}
      className="hiw_background_box"
    >
      <h1 className="hiw_title">How it works</h1>
      <Grid
        container
        columnSpacing={3}
        direction="row"
        justifyContent="center"
        // alignItems="center"
        marginLeft={"auto"}
        marginRight={"auto"}
        sx={{ width: "85%", display: { xs: "none", md: "flex" } }}
      >
        <Grid item xs={4}>
          <div className="hiw_card">
            <div className="hiw_circle">
              <img src={clock} className="hiw_image" alt="clock" />
            </div>
            <h2 className="hiw_label">
              Choose how long you want to diamond-hand
            </h2>
          </div>
        </Grid>
        <Grid item xs={4}>
          <div className="hiw_card">
            <div className="hiw_circle">
              <img src={vault} className="hiw_image" alt="vault" />
            </div>
            <h2 className="hiw_label">
              Store your NFT in your own time-locked smart contract
            </h2>
          </div>
        </Grid>
        <Grid item xs={4}>
          <div className="hiw_card">
            <div className="hiw_circle">
              <img src={logo} className="hiw_image" alt="logo" />
            </div>
            <h2 className="hiw_label">
              That’s it! Diamond-Hand it until your scheduled time!
            </h2>
          </div>
        </Grid>
      </Grid>

      <Carousel sx={{ display: { xs: "block", md: "none" } }} autoPlay={false}>
        {items.map((item, i) => (
          <Item key={i} item={item} />
        ))}
      </Carousel>

      <h2 className="hiw_subtitle">
        What if I really need to withdraw before the scheduled time?
      </h2>
      <p className="hiw_p">
        You can set your own emergency unlock price, and if you really need to
        unlock your NFT before your scheduled date, you can pay that price to
        withdraw your NFT
      </p>
    </Box>
  );
}
