import { useEffect, useState, Fragment } from "react";
import { useLocation, Navigate } from "react-router-dom";
import { Body } from "../components";

import {
  Button,
  TextField,
  InputAdornment,
  Dialog,
  DialogContent,
  DialogTitle,
  Tooltip,
} from "@mui/material";

import dayjs from "dayjs";

import advancedFormat from "dayjs/plugin/advancedFormat";

import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";

import { formatDuration, intervalToDuration } from "date-fns";

import { createTheme, ThemeProvider } from "@mui/material/styles";
import { blue, grey } from "@mui/material/colors";

//Wallet
import Web3 from "web3-utils";
import { useAccount, useSigner, useNetwork } from "wagmi";

import { ethers } from "ethers";

//Components
import NavBarHome from "../components/Home/NavBarHome";
import Footer from "../components/Footer";

import Box from "@mui/material/Box";

import ConfirmationAlert from "../components/Modals/ConfirmationAlert";
import VerticalStepper from "../components/Modals/VerticalStepper";
import ErrorAlert from "../components/Modals/ErrorAlert";
import ShareableCard from "../components/Modals/ShareableCard";

//Contract
import { Contract } from "@ethersproject/contracts";
import { addresses, abis } from "@project/contracts";

import NoImage from "../images/no-image.png";
import { startGrid } from "../components/Styles/GridWave";

import ipfsToUrl from "../extensions/ipfsToUrl";

export const contractFactoryAbi = abis.diamondHandFactory;
export const contractFactoryAddress =
  process.env.REACT_APP_ENV === "sandbox" || process.env.REACT_APP_ENV === "dev"
    ? addresses.diamondHandFactory
    : addresses.diamondHandFactoryMainnet;
export const vaultAbi = abis.diamondVault;

function SpecificNFT() {
  const location = useLocation();
  const asset = location.state?.asset;

  const nftAddress = asset.contract_address;
  const tokenType = asset.contract.type;
  const tokenID = asset.token_id;
  const minUnlockPrice = 0.1;
  const maxUnlockPrice = 999999;

  const [address, setAddress] = useState("");
  const { data: signer } = useSigner();

  const [diamondHandPrice, setDiamondHandPrice] = useState(0.01);
  const [hasVault, setHasVault] = useState(false);
  const [fetchedVaultAddress, setFetchedVaultAddress] = useState(false);

  //Network
  const [chainError, setChainError] = useState(false);

  //For user's ranking
  const [userRank, setUserRank] = useState(0);

  //For transaction hash
  const [txHash, setTxHash] = useState("");

  //Gridwave animation
  useEffect(() => {
    startGrid();
  }, []);

  //Wallet
  const account = useAccount({
    onConnect({ address }) {
      setAddress(address);
    },
    onDisconnect() {
      setAddress("");
    },
  });

  useEffect(() => {
    //Make sure account updates
    if (account.isConnected) {
      setAddress(account.address);
    } else if (account.isDisconnected) {
      setAddress("");
    }
  }, [account]);

  const { chain, chains } = useNetwork();
  useEffect(() => {
    if (account.isConnected) {
      //Check if chain is supported
      if (chain.unsupported) {
        //Disable buttons
        setChainError(true);
      } else {
        setChainError(false);
      }
    }
  }, [chain, account]);

  //Fetching diamondHandPrice
  async function fetchDiamondHandPrice() {
    const diamondContract = new Contract(
      contractFactoryAddress,
      contractFactoryAbi,
      signer
    );
    //get DiamondHandPrice from contract
    const price = await diamondContract.price();
    setDiamondHandPrice(Web3.fromWei(price.toString(), "ether"));
  }
  async function checkUserVault() {
    //check if user already has a diamondVault
    const diamondFactoryContract = new Contract(
      contractFactoryAddress,
      contractFactoryAbi,
      signer
    );

    await diamondFactoryContract.getVaultAddresses(address).then(
      async (result) => {
        if (result[0] !== ethers.constants.AddressZero) {
          setHasVault(true);
          setFetchedVaultAddress(result[0]);
        }
      },
      (error) => {
        // console.log("error = " + error);
        setHasVault(false);
      }
    );
  }

  const [fetchedDiamondSpecial, setFetchedDiamondSpecial] = useState(false);

  //Fetch DiamondSpecial
  useEffect(() => {
    function getDiamondSpecial() {
      if (!fetchedDiamondSpecial) {
        fetch(
          (process.env.REACT_APP_ENV === "production" ||
          process.env.REACT_APP_ENV === "sandbox"
            ? process.env.REACT_APP_HOST
            : "http://localhost:3001") + "/diamond_special"
        )
          .then((response) => {
            return response.text();
          })
          .then((data) => {
            setFetchedDiamondSpecial(JSON.parse(data));
          });
      }
    }
    getDiamondSpecial();
  }, [fetchedDiamondSpecial]);

  useEffect(() => {
    async function checkDiamondSpecial() {
      //Detect if user has diamondSpecial
      const erc1155ABI = abis.erc1155;
      const erc721ABI = abis.erc721;

      for (let i = 0; i < fetchedDiamondSpecial.length; i++) {
        if (fetchedDiamondSpecial[i].token_type === 0) {
          //Check if user owns this diamondSpecial
          const nftContract = new Contract(
            fetchedDiamondSpecial[i].contract_address,
            erc721ABI,
            signer
          );

          let nftBalance = await nftContract.balanceOf(address);
          if (nftBalance > 0) {
            setOwnsDiamondSpecial(true);
            setDiamondSpecial([
              fetchedDiamondSpecial[i].contract_address,
              0,
              0,
            ]);
          }
        } else {
          const nftContract = new Contract(
            fetchedDiamondSpecial[i].contract_address,
            erc1155ABI,
            signer
          );

          let nftBalance = await nftContract.balanceOf(
            address,
            fetchedDiamondSpecial[i].token_id
          );
          if (nftBalance > 0) {
            setOwnsDiamondSpecial(true);
            setDiamondSpecial([
              fetchedDiamondSpecial[i].contract_address,
              1,
              fetchedDiamondSpecial[i].token_id,
            ]);
          }
        }
      }
    }
    if (address !== "" && signer) {
      checkDiamondSpecial();
    }
  }, [address, fetchedDiamondSpecial, signer]);

  //Local Variables
  const [dateValue, setDateValue] = useState(dayjs().add(2, "weeks"));
  dayjs.extend(advancedFormat);

  const [dateValueError, setDateValueError] = useState(false);
  const [unlockValue, setUnlockValue] = useState(1);
  const [unlockValueError, setUnlockValueError] = useState(false);

  //For displaying alerts
  const [alertTitle, setAlertTitle] = useState("");
  const [alertMessage, setAlertMessage] = useState("");
  const [showingAlert, setShowingAlert] = useState(false);

  function handleError(error) {
    if (error.code === "ACTION_REJECTED") {
      //Ignore
      //Closing step dialog
      setStartStep(false);
    } else {
      setAlertTitle("Error");
      setAlertMessage("Diamond-Handing failed, please try again later");
      setShowingAlert(true);
      setStartStep(false);
      //Scroll to the top so user sees error message
      window.scrollTo(0, 0);
    }
  }

  async function createDiamondVault() {
    //Create new diamond vault for user
    const diamondFactoryContract = new Contract(
      contractFactoryAddress,
      contractFactoryAbi,
      signer
    );

    await diamondFactoryContract.createDiamondVault().then(
      (receipt) => {
        // This is entered if the transaction receipt indicates success
        finishCreatingVault(receipt);
      },
      (error) => {
        // This is entered if the status of the receipt is failure
        handleError(error);
      }
    );
  }

  async function finishCreatingVault(creationHash) {
    await creationHash.wait().then(
      (receipt) => {
        // This is entered if the transaction receipt indicates success
        let vaultEventIndex = receipt.events.length - 1;
        const result = receipt.events[vaultEventIndex].topics[1].toString();
        const vaultAddress = "0x" + result.substring(26, result.length);
        setHasVault(true);
        setApprovalForAll(vaultAddress);
        //store user's vault address to DB
        addNewUserDB(address, vaultAddress, dayjs().unix());
      },
      (error) => {
        // This is entered if the status of the receipt is failure
        handleError(error);
      }
    );
  }

  async function setApprovalForAll(vaultAddress) {
    //This is next step
    setActiveStep(1);

    const erc1155ABI = abis.erc1155;
    const erc721ABI = abis.erc721;

    let nftAbi = erc721ABI;
    if (tokenType === "ERC1155") {
      nftAbi = erc1155ABI;
    }

    const nftContract = new Contract(nftAddress, nftAbi, signer);

    //Check if approval is already set for this collection
    const wasApproved = await nftContract.isApprovedForAll(
      address,
      vaultAddress
    );

    if (!wasApproved) {
      await nftContract
        .setApprovalForAll(
          //this contract address as the operator
          vaultAddress,
          1
        )
        .then(
          (receipt) => {
            // This is entered if the transaction receipt indicates success
            finishSettingApproval(receipt, vaultAddress);
          },
          (error) => {
            handleError(error);
          }
        );
    } else {
      //NFT already approved
      createDiamondHand(vaultAddress);
    }
  }

  async function finishSettingApproval(approvalHash, vaultAddress) {
    await approvalHash.wait().then(
      (receipt) => {
        // This is entered if the transaction receipt indicates success
        createDiamondHand(vaultAddress);
      },
      (error) => {
        handleError(error);
      }
    );
  }

  async function createDiamondHand(vaultAddress) {
    //update step
    setActiveStep(2);

    const diamondContract = new Contract(vaultAddress, vaultAbi, signer);
    const releaseTime = dayjs(dateValue).unix();
    const breakPrice = ethers.BigNumber.from(
      Web3.toWei(Number(unlockValue).toString(), "ether")
    ).toHexString();

    let tokenTypeForContract = 0;
    if (tokenType === "ERC1155") {
      tokenTypeForContract = 1;
    }

    //get DiamondHandPrice from contract

    let payment = Web3.toWei(diamondHandPrice.toString(), "ether");

    if (ownsDiamondSpecial || ownsDiamondPass) {
      payment = 0;
    }

    const diamondNFTs = [nftAddress, [], tokenTypeForContract, [tokenID], [1]];
    await diamondContract
      .createDiamondHands(
        diamondNFTs,
        releaseTime,
        breakPrice,
        diamondSpecial,
        {
          value: payment,
        }
      )
      .then((receipt) => {
        // This is entered if the transaction receipt indicates success
        finishCreatingDiamondHands(receipt, vaultAddress);

        let collectionName = asset.collection.name
          ? asset.collection.name
          : asset.contract.name
          ? asset.contract.name
          : asset.contract_address.substring(0, 6) +
            "..." +
            asset.contract_address.substring(36);
        fetch(
          (process.env.REACT_APP_ENV === "production" ||
          process.env.REACT_APP_ENV === "sandbox"
            ? process.env.REACT_APP_HOST
            : "http://localhost:3001") + "/create_diamond_receipt",
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
              receipt,
              vaultAddress,
              releaseTime,
              address,
              unlockValue,
              collectionName,
              nftAddress,
              tokenID,
              tokenTypeForContract,
            }),
          }
        ).then((response) => {});
      })
      .catch((error) => {
        handleError(error);
      });
  }

  async function finishCreatingDiamondHands(diamondHash, vaultAddress) {
    await diamondHash
      .wait()
      .then((receipt) => {
        //Check if added in DB and get user rank
        //check tx hash in DB, if after 5 retries still haven't gotten, then drop the request and still show success message but rank is ?
        getUserRank(diamondHash.hash);

        // This is entered if the transaction receipt indicates success
        // setActiveStep(3);
        setDiamondHandFinishMessage(
          "Diamond-Hand Created Successfully! See <a href=https://" +
            process.env.REACT_APP_ENV ===
            "sandbox"
            ? "goerli."
            : "" +
                "etherscan.io/tx/" +
                diamondHash.hash +
                "> transaction </a>" +
                "<br></br> You can also check out your vault <a href=https://" +
                process.env.REACT_APP_ENV ===
              "sandbox"
            ? "goerli."
            : "" +
              "etherscan.io/address/" +
              vaultAddress +
              "> here </a>" +
              " <br> </br> View & Unlock your Diamond-Hands in your Profile!"
        );
        setTxHash(diamondHash.hash);

        //Hide NFT info
        setStepTitle("Diamond-Hand Created!");
      })
      .catch((error) => {
        // This is entered if the status of the receipt is failure
        handleError(error);
      });
  }

  //Get User Ranking
  async function getUserRank(tx_hash) {
    const wallet_address = address;
    const collection_address = nftAddress;
    const token_id = tokenID;
    if (tokenType === "ERC1155") {
      fetch(
        (process.env.REACT_APP_ENV === "production" ||
        process.env.REACT_APP_ENV === "sandbox"
          ? process.env.REACT_APP_HOST
          : "http://localhost:3001") +
          "/get_user_rank_ERC1155?" +
          new URLSearchParams({
            wallet_address,
            token_id,
            collection_address,
            tx_hash,
          })
      )
        .then((response) => {
          return response.text();
        })
        .then((data) => {
          //successfully return rank
          setActiveStep(3);
          if (data === "?") {
            setUserRank("?");
          } else {
            setUserRank(Number(JSON.parse(data)[0].row_number));
          }
        })
        .catch((error) => {
          //still show success message but set rank to be ?
          setActiveStep(3);
          setUserRank("?");
        });
    } else {
      //ERC721
      fetch(
        (process.env.REACT_APP_ENV === "production" ||
        process.env.REACT_APP_ENV === "sandbox"
          ? process.env.REACT_APP_HOST
          : "http://localhost:3001") +
          "/get_user_rank_ERC721?" +
          new URLSearchParams({ wallet_address, collection_address, tx_hash })
      )
        .then((response) => {
          return response.text();
        })
        .then((data) => {
          //successfully return rank
          setActiveStep(3);
          if (data === "?") {
            setUserRank("?");
          } else {
            setUserRank(Number(JSON.parse(data)[0].row_number));
          }
        })
        .catch((error) => {
          //still show success message but set rank to be ?
          setActiveStep(3);
          setUserRank("?");
        });
    }
  }

  function addNewUserDB(wallet_address, vault_address, current_time) {
    fetch(
      (process.env.REACT_APP_ENV === "production" ||
      process.env.REACT_APP_ENV === "sandbox"
        ? process.env.REACT_APP_HOST
        : "http://localhost:3001") + "/add_user",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          wallet_address,
          vault_address,
          current_time,
        }),
      }
    ).then((response) => {
      return response.text();
    });
  }

  //function to check if user owns a DiamondPass
  const [diamondPassMessage, setDiamondPassMessage] = useState(
    "Note: Diamond-Handing your NFT costs Ξ" +
      diamondHandPrice +
      ". You can do it for FREE if you own a Diamond Pass!"
  );
  const [ownsDiamondPass, setOwnsDiamondPass] = useState(false);

  async function checkDiamondPass() {
    const diamondPassAddress =
      process.env.REACT_APP_ENV === "sandbox" ||
      process.env.REACT_APP_ENV === "dev"
        ? addresses.diamondPass
        : addresses.diamondPassMainnet;
    const diamondPassABI = abis.diamondPass;
    const nftContract = new Contract(
      diamondPassAddress,
      diamondPassABI,
      signer
    );
    const accountBalance = await nftContract.balanceOf(address);
    const accountBalanceInt = parseInt(accountBalance.toString());
    if (accountBalanceInt > 0) {
      setDiamondPassMessage(
        "Since you own a Diamond Pass, Diamond-Handing is FREE!"
      );
      setOwnsDiamondPass(true);
    } else if (ownsDiamondSpecial) {
      setDiamondPassMessage(
        "Since you own an NFT that is on our Diamond Special List, Diamond-Handing is FREE!"
      );
    } else {
      setDiamondPassMessage(
        "Note: Diamond-Handing your NFT costs Ξ" +
          diamondHandPrice +
          ". You can do it for FREE if you own a Diamond Pass!"
      );
    }
  }

  const [ownsDiamondSpecial, setOwnsDiamondSpecial] = useState(false);
  let [diamondSpecial, setDiamondSpecial] = useState([
    ethers.constants.AddressZero,
    0,
    0,
  ]);

  //For confirmation alerts
  const [isOpen, setIsOpen] = useState(false);

  function updateOpen(openStatus) {
    setIsOpen(openStatus);
  }

  async function confirmedDiamondHand() {
    setStartStep(true);
    if (hasVault) {
      setApprovalForAll(fetchedVaultAddress);
    } else {
      //Double check that user doesn't have a vault
      const diamondFactoryContract = new Contract(
        contractFactoryAddress,
        contractFactoryAbi,
        signer
      );

      await diamondFactoryContract.getVaultAddresses(address).then(
        async (result) => {
          if (result[0] !== ethers.constants.AddressZero) {
            setHasVault(true);
            setFetchedVaultAddress(result[0]);
          } else {
            setHasVault(false);
            // Create Diamond Vault
            createDiamondVault();
          }
        },
        (error) => {
          // console.log("error = " + error);
          setHasVault(false);
          // Create Diamond Vault
          createDiamondVault();
        }
      );
    }
  }
  function declinedDiamondHand() {}

  function calculateTimeLeft() {
    const releaseTime = dayjs(dateValue).unix() * 1000;
    if (releaseTime) {
      let duration = intervalToDuration({
        start: new Date(),
        end: releaseTime,
      });

      const formatted = formatDuration(duration, {
        delimiter: ", ",
      });

      return formatted;
    } else {
      return "Error";
    }
  }

  //Transaction Steps
  const [startStep, setStartStep] = useState(false);
  const [stepTitle, setStepTitle] = useState("Diamond-Hand In Progress...");
  const [activeStep, setActiveStep] = useState(0);
  const [diamondHandFinishMessage, setDiamondHandFinishMessage] = useState("");

  const steps = [
    {
      label: "Create your own Diamond Vault",
      description:
        "First time diamond-handing? Let's set you up with your own Diamond Vault. Only you can store/withdraw from this vault! (You only need to do this once)",
    },
    {
      label: "Approve your NFT for Diamond-Handing",
      description: "Setting approval to transfer your NFT into your vault",
    },
    {
      label: "Creating your Diamond-Hand",
      description:
        "Transferring your NFT to your vault with the specified unlock date and emergency unlock price",
    },
  ];

  const theme = createTheme({
    palette: {
      primary: blue,
      secondary: grey,
    },
  });

  if (asset === null) {
    return <Navigate to="/" />;
  }

  return (
    <>
      <Body>
        <ErrorAlert
          title={alertTitle}
          message={alertMessage}
          open={showingAlert}
          updateOpen={setShowingAlert}
        />
        <div className="grid-wave-container-2">
          <div className="grid-wave w-full h-full bg-blue-1"></div>
          <div className="grid-wave-content">
            <NavBarHome />
            <Box
              sx={{
                width: "80%",
                height: "100%",
                background: "#1D335F",
                display: "flex",
                borderRadius: "20px",
                paddingTop: "10vh",
                paddingBottom: "10vh",
                marginX: "auto",
              }}
            >
              <div style={{ textAlign: "center", width: "100%" }}>
                {/* <h4 className="NFT-Label">{asset.name}</h4> */}
                {/* <img src={asset.image_url} alt="NFT" className="NFT-Image" /> */}

                <h4 className="NFT-Label">
                  {asset.name
                    ? asset.name
                    : asset.collection.name
                    ? asset.collection.name + " " + asset.token_id
                    : "Metadata Unavailable"}
                </h4>
                <img
                  src={
                    asset.image_url
                      ? asset.image_url
                      : asset.extra_metadata.image_original_url
                      ? ipfsToUrl(asset.extra_metadata.image_original_url)
                        ? ipfsToUrl(asset.extra_metadata.image_original_url)
                        : NoImage
                      : NoImage
                  }
                  onError={({ currentTarget }) => {
                    currentTarget.onerror = null; // prevents looping
                    currentTarget.src = NoImage;
                  }}
                  alt="NFT"
                  className="NFT-Image"
                />
                <h2 className={"specific_nft_h2"}>Set an unlock date:</h2>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <ThemeProvider theme={theme}>
                    <DateTimePicker
                      renderInput={(props) => (
                        <TextField
                          {...props}
                          sx={{
                            input: { color: "white", fontFamily: "Poppins" },
                            label: {
                              color: "lightgrey",
                              fontFamily: "Poppins",
                            },
                            width: {
                              xs: "80%",
                              sm: "60%",
                              md: "40%",
                              lg: "30%",
                              xl: "20%",
                            },
                          }}
                        />
                      )}
                      label="Unlock Date &amp; Time"
                      value={dateValue}
                      onChange={(newValue) => {
                        setDateValue(newValue);
                        if (
                          dayjs(newValue).format() < dayjs().format() ||
                          !dayjs(newValue).isValid()
                        ) {
                          setDateValueError(true);
                        } else {
                          setDateValueError(false);
                        }
                      }}
                      minDateTime={dayjs()}
                      color="primary"
                    />
                  </ThemeProvider>
                </LocalizationProvider>
                <h2 className={"specific_nft_h2"}>
                  Set an Emergency Unlock Price:
                </h2>
                <p className="note">
                  Note: This is how much ETH you have to pay if you decide to
                  forcibly break your diamond hand and withdraw before your
                  unlock date. <br />
                  (minimum = {minUnlockPrice})
                </p>
                <br></br>
                <TextField
                  error={unlockValueError}
                  id="outlined-number"
                  label="Emergency Unlock Price"
                  type="number"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  value={unlockValue}
                  onChange={(event) => {
                    let newValue = event.target.value;
                    if (newValue < minUnlockPrice) {
                      setUnlockValueError(true);
                    } else if (newValue > maxUnlockPrice) {
                      setUnlockValueError(true);
                    } else {
                      setUnlockValueError(false);
                    }
                    setUnlockValue(newValue);
                  }}
                  sx={{
                    input: { color: "white" },
                    label: { color: "white" },
                    width: {
                      xs: "80%",
                      sm: "60%",
                      md: "40%",
                      lg: "30%",
                      xl: "20%",
                    },
                  }}
                  inputProps={{
                    min: minUnlockPrice,
                    max: maxUnlockPrice,
                    step: "0.1",
                  }}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <h2 className="eth-symbol">Ξ</h2>
                      </InputAdornment>
                    ),
                  }}
                />
                <br></br>
                <Tooltip
                  title={
                    unlockValueError
                      ? "Invalid emergency unlock price!"
                      : dateValueError
                      ? "Invalid unlock date!"
                      : chainError
                      ? "Please switch your wallet to the " +
                        chains[0].name +
                        " network!"
                      : address === ""
                      ? "Your wallet is not connected"
                      : "Time-lock your NFT!"
                  }
                >
                  <div>
                    <Button
                      onClick={() => {
                        updateOpen(true);
                        checkDiamondPass();
                        fetchDiamondHandPrice();
                        checkUserVault();
                      }}
                      disabled={
                        unlockValueError ||
                        dateValueError ||
                        chainError ||
                        address === ""
                      }
                      sx={{
                        color: "white",
                        textTransform: "none",
                        background: "#08121b",
                        "&:hover": {
                          backgroundColor: "black",
                        },
                        height: "80px",
                        marginTop: "20px",
                        marginLeft: "auto",
                        marginRight: "auto",
                        width: 250,
                        fontSize: 21,
                        fontFamily: "Poppins",
                        borderRadius: "20px",
                      }}
                    >
                      Diamond-Hand Me!
                    </Button>
                  </div>
                </Tooltip>
              </div>
              <ShareableCard
                assetTitle={
                  asset.name
                    ? asset.name
                    : asset.collection.name
                    ? asset.collection.name + " " + asset.token_id
                    : "Metadata Unavailable"
                }
                collectionTitle={
                  asset.collection.name
                    ? asset.collection.name
                    : asset.contract_address.substring(0, 6) +
                      "..." +
                      asset.contract_address.substring(36)
                }
                endDate={dayjs(dateValue).format("MMMM Do YYYY, h:mm:ss a")}
                duration={calculateTimeLeft()}
                imageURL={
                  asset.image_url
                    ? asset.image_url
                    : asset.extra_metadata.image_original_url
                    ? ipfsToUrl(asset.extra_metadata.image_original_url)
                      ? ipfsToUrl(asset.extra_metadata.image_original_url)
                      : NoImage
                    : NoImage
                }
                ranking={userRank}
                rankingURL={"https://diamond-hand.me/stats/" + nftAddress}
                showing={activeStep === 3 && userRank !== 0}
                walletAddress={address}
                isComplete={false}
                transactionHash={txHash}
              />

              <ConfirmationAlert
                open={isOpen}
                updateOpen={updateOpen}
                title="Confirm your Diamond-Hand Details"
                message={[
                  <Fragment key={"confirmation"}>
                    You are locking away your NFT for approximately{" "}
                    <b>{calculateTimeLeft()}</b> – to be unlocked on{" "}
                    <b>{dayjs(dateValue).format("MMMM Do YYYY, h:mm:ss a")}.</b>
                    <br />
                    <br /> Before this date, you will NOT be able to withdraw
                    your NFT, unless you decide to pay the price of{" "}
                    <b>
                      <span className="eth">Ξ</span> {Number(unlockValue)} ETH
                    </b>{" "}
                    (as set by you) to use the emergency unlock. <br />
                    <br />{" "}
                    <span className="confirmation_note">
                      {diamondPassMessage}
                    </span>
                  </Fragment>,
                ]}
                declineLabel="Cancel"
                confirmLabel="Diamond-Hand Me"
                onConfirm={confirmedDiamondHand}
                onDecline={declinedDiamondHand}
              />
              <Dialog
                open={startStep}
                keepMounted
                // onClose={}
                aria-describedby="alert-dialog-slide-description"
              >
                <DialogTitle
                  sx={{
                    fontFamily: "Poppins",
                  }}
                >
                  {stepTitle}
                </DialogTitle>
                <DialogContent>
                  <VerticalStepper
                    steps={steps}
                    activeStep={activeStep}
                    finishMessage={diamondHandFinishMessage}
                  />
                </DialogContent>
              </Dialog>
            </Box>
            <Footer></Footer>
          </div>
        </div>
      </Body>
    </>
  );
}

export default SpecificNFT;
