import React, { useEffect, useState } from "react";
import { BigNumber } from "ethers";

//Components
import { Body } from "../components";
import NavBarHome from "../components/Home/NavBarHome";
import SpecificCollectionTable from "../components/SpecificCollectionTable";
import { Box, Tooltip, FormControlLabel } from "@mui/material";
import { StatsSwitch } from "../components/Styles/SwitchStyles";

import { useParams } from "react-router-dom";
import Footer from "../components/Footer";
import NoImage from "../images/no-image.png";
import { startGrid } from "../components/Styles/GridWave";

function SpecificCollectionStats() {
  const { collection_address, token_id } = useParams();
  const [collectionName, setCollectionName] = useState("");
  const [assetType, setAssetType] = useState("");
  const [loading, setLoading] = useState(true);

  const [collectionImageURL, setCollectionImageUrl] = useState("");
  const [diamondHanders, setDiamondHanders] = useState(false);
  const [isAllTime, setIsAllTime] = useState(false);

  //Gridwave animation
  useEffect(() => {
    startGrid();
  }, []);

  useEffect(() => {
    if (!token_id) {
      setAssetType(721);
    } else {
      //ERC1155
      setAssetType(1155);
    }

    const fetchData = () => {
      if (assetType === 1155) {
        fetch(
          (process.env.REACT_APP_ENV === "production" ||
          process.env.REACT_APP_ENV === "sandbox"
            ? process.env.REACT_APP_HOST
            : "http://localhost:3001") +
            "/individual_rankings_ERC1155?" +
            new URLSearchParams({ collection_address, token_id })
        )
          .then((response) => {
            return response.text();
          })
          .then((data) => {
            setDiamondHanders(data);
          });
      } else if (assetType === 721) {
        fetch(
          (process.env.REACT_APP_ENV === "production" ||
          process.env.REACT_APP_ENV === "sandbox"
            ? process.env.REACT_APP_HOST
            : "http://localhost:3001") +
            "/individual_rankings_ERC721?" +
            new URLSearchParams({ collection_address })
        )
          .then((response) => {
            return response.text();
          })
          .then((data) => {
            setDiamondHanders(data);
          });
      }
    };

    if (collectionImageURL === "" && assetType !== "") {
      fetchData();
      //Fetch image from OpenSea:
      const options = {
        method: "GET",
        headers: { Accept: "application/json" },
      };
      let fetchLink =
        "https://" +
        (process.env.REACT_APP_ENV === "sandbox" ||
        process.env.REACT_APP_ENV === "dev"
          ? "testnets-"
          : "") +
        "api.opensea.io/api/v1/assets?token_ids=" +
        1 +
        "&asset_contract_addresses=" +
        collection_address;
      if (assetType === 1155) {
        fetchLink =
          "https://" +
          (process.env.REACT_APP_ENV === "sandbox" ||
          process.env.REACT_APP_ENV === "dev"
            ? "testnets-"
            : "") +
          "api.opensea.io/api/v1/assets?token_ids=" +
          BigNumber.from(token_id).toString() +
          "&asset_contract_addresses=" +
          collection_address;
      }
      setLoading(true);
      fetch(fetchLink, options)
        .then((response) => response.json())
        .then((response) => {
          //opensea's results actually aren't in order, so have to match it up
          setCollectionImageUrl(response.assets[0].collection.image_url);
          setCollectionName(response.assets[0].collection.name);
          setLoading(false);
        })
        .catch((err) => console.error(err));
    }
  }, [assetType, collectionImageURL, collection_address, token_id]);

  return (
    <>
      <Body>
        <div className="grid-wave-container-2">
          <div className="grid-wave w-full h-full bg-blue-1"></div>
          <div className="grid-wave-content">
            <NavBarHome />
            <img
              src={collectionImageURL ? collectionImageURL : NoImage}
              className={"NFT-Image"}
              onError={({ currentTarget }) => {
                currentTarget.onerror = null; // prevents looping
                currentTarget.src = NoImage;
              }}
              alt="NFT Collection"
              style={{ display: loading ? "none" : "block" }}
            />

            <Box
              sx={{
                width: "80vw",
                maxWidth: "1200px",
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                alignItems: "center",
                marginLeft: "auto",
                marginRight: "auto",
              }}
            >
              <Box
                sx={{
                  width: "300px",
                }}
              ></Box>
              <Box
                sx={{
                  width: "80vw",
                }}
              >
                <h1 hidden={collectionImageURL === ""} className="stats_title">
                  Top 💎 of {collectionName}
                </h1>
              </Box>
              <Box
                sx={{
                  width: "300px",
                  height: "80px",
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "flex-end",
                }}
              >
                <Tooltip title="Display Current or All-Time Stats">
                  <FormControlLabel
                    control={
                      <StatsSwitch
                        onChange={(event) => {
                          if (event.target.checked) {
                            setIsAllTime(true);
                          } else {
                            setIsAllTime(false);
                          }
                        }}
                      />
                    }
                    label={
                      <p className="stats-switch-label">
                        {isAllTime ? "All-Time" : "Current"}
                      </p>
                    }
                  />
                </Tooltip>
              </Box>
            </Box>
            <SpecificCollectionTable
              data={diamondHanders}
              isAllTime={isAllTime}
            />
            <Footer></Footer>
          </div>
        </div>
      </Body>
    </>
  );
}

export default SpecificCollectionStats;
