import React, { useEffect, useState } from "react";

//Components
import { Body } from "../components";
import NavBarHome from "../components/Home/NavBarHome";
import Footer from "../components/Footer";
import { Box, Tooltip, FormControlLabel } from "@mui/material";
import { StatsSwitch } from "../components/Styles/SwitchStyles";

import CollectionTable from "../components/CollectionTable";
import { startGrid } from "../components/Styles/GridWave";

function Stats() {
  //Gridwave Animation
  useEffect(() => {
    startGrid();
  }, []);

  const [diamondHands, setDiamondHands] = useState(false);
  const [isAllTime, setIsAllTime] = useState(false);

  useEffect(() => {
    getCollectionRankings();
  }, []);
  function getCollectionRankings() {
    fetch(
      (process.env.REACT_APP_ENV === "production" ||
      process.env.REACT_APP_ENV === "sandbox"
        ? process.env.REACT_APP_HOST
        : "http://localhost:3001") + "/collection_rankings"
    )
      .then((response) => {
        return response.text();
      })
      .then((data) => {
        setDiamondHands(data);
      });
  }

  // return (
  //   <div>
  //     {diamondHands ? diamondHands : "There is no diamondHand data available"}
  //     <br />
  //   </div>
  // );
  return (
    <>
      <Body>
        <div className="grid-wave-container-2">
          <div className="grid-wave w-full h-full bg-blue-1"></div>
          <div className="grid-wave-content">
            <NavBarHome />
            <Box
              sx={{
                width: "80vw",
                maxWidth: "1200px",
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                alignItems: "center",
                marginLeft: "auto",
                marginRight: "auto",
              }}
            >
              <Box
                sx={{
                  width: "300px",
                }}
              ></Box>
              <Box
                sx={{
                  width: "80vw",
                }}
              >
                <h1 className="stats_title">Top 💎 Collections</h1>
              </Box>
              <Box
                sx={{
                  width: "300px",
                  height: "80px",
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "flex-end",
                }}
              >
                <Tooltip title="Display Current or All-Time Stats">
                  <FormControlLabel
                    control={
                      <StatsSwitch
                        onChange={(event) => {
                          if (event.target.checked) {
                            setIsAllTime(true);
                          } else {
                            setIsAllTime(false);
                          }
                        }}
                      />
                    }
                    label={
                      <p className="stats-switch-label">
                        {isAllTime ? "All-Time" : "Current"}
                      </p>
                    }
                  />
                </Tooltip>
              </Box>
            </Box>
            <CollectionTable data={diamondHands} isAllTime={isAllTime} />
            <Footer></Footer>
          </div>
        </div>
      </Body>
    </>
  );
}

export default Stats;
