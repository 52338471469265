import React, { useEffect, useState } from "react";
import { useAccount } from "wagmi";

//Routing
import { Link } from "react-router-dom";

//Components
import { Body } from "../components";
import NavBarHome from "../components/Home/NavBarHome";
import Footer from "../components/Footer";

import { Grid, Box, Card, CardMedia, CardContent, Button } from "@mui/material";

import CircularIndeterminate from "../components/CircularIndeterminate";
// import AssetSkeleton from "../components/AssetSkeleton";

import NoImage from "../images/no-image.png";
import { startGrid } from "../components/Styles/GridWave";
import ipfsToUrl from "../extensions/ipfsToUrl";

function Start() {
  const [loading, setLoading] = useState(true);

  const [address, setAddress] = useState("");
  const [assetsMessage, setAssetsMessage] = useState(
    "Select an NFT to Diamond-Hand"
  );

  //Gridwave animation
  useEffect(() => {
    startGrid();
  }, []);

  const account = useAccount({
    onConnect({ address }) {
      setAddress(address);
    },
    onDisconnect() {
      setAddress("");
    },
  });

  useEffect(() => {
    //Make sure account updates
    if (account.isConnected) {
      setAddress(account.address);
    } else if (account.isDisconnected) {
      setAddress("");
    }
    if (address !== account.address && address !== "" && account.address) {
      //Address switched, reload
      window.location.reload();
    }
  }, [account, address]);

  const NFTAssets = ({ address }) => {
    const increment = 25;
    const [data, setData] = useState([]);
    const [endIndex, setEndIndex] = useState(increment);
    const [currentFetchCursor, setCurrentFetchCursor] = useState("");
    const [nextFetchCursor, setNextFetchCursor] = useState(null);

    function loadMore() {
      setCurrentFetchCursor(nextFetchCursor);
    }
    useEffect(() => {
      async function fetchNFTs() {
        let next = currentFetchCursor;
        let filter =
          process.env.REACT_APP_ENV === "production" ? "true" : "false";
        await fetch(
          (process.env.REACT_APP_ENV === "production" ||
          process.env.REACT_APP_ENV === "sandbox"
            ? process.env.REACT_APP_HOST
            : "http://localhost:3001") +
            "/get_nfts_of_address?" +
            new URLSearchParams({
              address,
              next,
              filter,
            })
        )
          .then((response) => response.json())
          .then((response) => {
            if (response.next) {
              setNextFetchCursor(response.next);
            } else {
              setNextFetchCursor(null);
            }
            //merge data with old data
            setData((data) => data.concat(response.nfts));
            if (data.concat(response.nfts).length === 0) {
              setAssetsMessage("No NFTs found in your wallet");
            } else {
              setAssetsMessage("Select an NFT to Diamond-Hand");
            }
            setLoading(false);
          })
          .catch((error) => console.log("error", error));
      }
      if (address !== "") {
        fetchNFTs();
      }
    }, [address, currentFetchCursor]);

    //Helper functions
    let displayTillIndex = endIndex > data.length ? data.length : endIndex;
    //Displaying data by increment. If endIndex + 1 > length of data, then only display until data
    const GridData = data.slice(0, displayTillIndex).map((assets) => {
      return (
        <Grid item xs={12} sm={6} md={4} lg={3} xl={2} key={assets.nft_id}>
          <Card
            sx={{
              height: "340px",
              // height: "300px",
              width: "80%",
              maxWidth: "240px",
              marginLeft: "auto",
              marginRight: "auto",
              backgroundColor: "white",
              borderRadius: "10px",
              boxShadow: "0 16px 70px -12.125px rgba(0,0,0,0.3)",
              paddingBottom: "20px",
            }}
          >
            <Link
              to={"/diamond-handing"}
              state={{ asset: assets }}
              style={{ textDecoration: "none" }}
            >
              <Box sx={{ height: "240px" }}>
                <CardMedia
                  component="img"
                  className="NFT-Image-Start"
                  image={
                    assets.image_url
                      ? assets.image_url
                      : assets.extra_metadata.image_original_url
                      ? ipfsToUrl(assets.extra_metadata.image_original_url)
                        ? ipfsToUrl(assets.extra_metadata.image_original_url)
                        : NoImage
                      : NoImage
                  }
                  onError={({ currentTarget }) => {
                    currentTarget.onerror = null; // prevents looping
                    currentTarget.src = NoImage;
                  }}
                  onLoad={() => {}}
                  sx={{ objectFit: "contain" }}
                  alt="NFT"
                />
              </Box>
              <CardContent>
                {/* <p className="nft-collection-name">{assets.collection.name}</p>
                <p className="nft-name">{assets.name}</p> */}
                <p className="nft-collection-name">
                  {assets.collection.name
                    ? assets.collection.name
                    : assets.contract_address.substring(0, 6) +
                      "..." +
                      assets.contract_address.substring(36)}
                </p>
                <p className="nft-name">
                  {assets.name
                    ? assets.name
                    : assets.collection.name
                    ? assets.collection.name + " " + assets.token_id
                    : "Metadata Unavailable"}
                </p>
              </CardContent>
            </Link>
          </Card>
        </Grid>
      );
    });

    return (
      <Box
        sx={{
          width: "80%",
          height: "100%",
          background: "#1D335F",
          display:
            address === "" ||
            assetsMessage === "No NFTs found in your wallet" ||
            loading
              ? "none"
              : "flex",
          borderRadius: "20px",
          marginLeft: "auto",
          marginRight: "auto",
          minHeight: "60vh",
        }}
      >
        <Box
          sx={{
            flexGrow: 2,
            width: 8 / 10,
            justifyContent: "center",
            paddingTop: "10vh",
            paddingBottom: "10vh",
          }}
        >
          <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="center"
            rowSpacing={3}
            columnSpacing={1}
          >
            {GridData}
          </Grid>
          <Button
            sx={{
              color: "white",
              textTransform: "none",
              background: "#08121b",
              "&:hover": {
                backgroundColor: "black",
              },
              width: "200px",
              height: "60px",
              marginTop: "20px",
              marginLeft: "auto",
              marginRight: "auto",
              fontSize: 18,
              fontFamily: "Poppins",
              borderRadius: "20px",
              display:
                nextFetchCursor !== null || endIndex < data.length
                  ? "block"
                  : "none",
            }}
            onClick={() => {
              //change endIndex
              if (endIndex + increment <= data.length) {
                setEndIndex(endIndex + increment);
              } else {
                setEndIndex(endIndex + increment);
                if (nextFetchCursor !== null) {
                  loadMore();
                }
              }
            }}
          >
            Load more
          </Button>
        </Box>
      </Box>
    );
  };

  return (
    <>
      {/* <Header></Header> */}
      <Body>
        <div className="grid-wave-container-2">
          <div className="grid-wave w-full h-full bg-blue-1"></div>
          <div className="grid-wave-content">
            <NavBarHome />
            <h2
              hidden={address !== ""}
              style={{
                marginTop: "auto",
                marginBottom: "auto",
                textAlign: "center",
              }}
            >
              Please connect your wallet to get started!
            </h2>

            <h1
              hidden={address === "" || loading}
              style={{
                textAlign: "center",
                paddingLeft: "20px",
                paddingRight: "20px",
              }}
            >
              {assetsMessage}
            </h1>
            <div className="spacer"></div>
            {loading && address !== "" && <CircularIndeterminate />}

            <NFTAssets address={address} />
            <Footer></Footer>
          </div>
        </div>
      </Body>
    </>
  );
}

export default Start;
