import React from "react";

//Components
import { Body } from "../components";
import NavBarHome from "../components/Home/NavBarHome";
import Footer from "../components/Footer";
import { Box } from "@mui/material";

function PrivacyPolicy() {
  return (
    <>
      <Body>
        <NavBarHome />
        <Box sx={{ width: "80%", marginX: "auto" }}>
          <h1 className="TOU_title">PRIVACY Policy</h1>
          <h2 className="TOU_last_updated">Last updated Jan 04, 2023</h2>
          <p className="TOU_p">
            This privacy notice for the website{" "}
            <a href="https://diamond-hand.me">https://diamond-hand.me</a>{" "}
            operated by Momo Labs LLC ("Company," "we," "us," or "our"),
            describes how and why we might collect, store, use, and/or share
            ("process") your information when you use our services ("Services").
            Reading this privacy notice will help you understand your privacy
            rights and choices. If you do not agree with our policies and
            practices, please do not use our Services. If you still have any
            questions or concerns, please contact us at info.momolabs@gmail.com.
          </p>
          <h2 className="TOU_subtitle">1. WHAT INFORMATION DO WE COLLECT?</h2>
          <p className="TOU_p">
            We collect personal information – specifically your ethereum wallet
            address – which you voluntarily provide to us when you use our
            Services. We also may collect your personal information when you
            express an interest in obtaining information about us or our
            products and Services, or otherwise when you contact us. We do not
            process sensitive information.
            <br></br>
            <br></br>
            <b>Information automatically collected</b> <br></br>
            Some information — such as your Internet Protocol (IP) address
            and/or browser and device characteristics — is collected
            automatically when you visit our Services. We automatically collect
            certain information when you visit, use, or navigate the Services.
            This information does not reveal your specific identity (like your
            name or contact information) but may include device and usage
            information, such as your IP address, browser and device
            characteristics, operating system, language preferences, referring
            URLs, device name, country, location, information about how and when
            you use our Services, and other technical information. This
            information is primarily needed to maintain the security and
            operation of our Services, and for our internal analytics and
            reporting purposes. Like many businesses, we also collect
            information through cookies and similar technologies.
          </p>
          <h2 className="TOU_subtitle">
            2. WHEN AND WITH WHOM DO WE SHARE YOUR PERSONAL INFORMATION?
          </h2>
          <p className="TOU_p">
            We may share or transfer your information in connection with, or
            during negotiations of, any merger, sale of company assets,
            financing, or acquisition of all or a portion of our business to
            another company. We may share your information with our affiliates,
            in which case we will require those affiliates to honor this privacy
            notice. Affiliates include our parent company and any subsidiaries,
            joint venture partners, or other companies that we control or that
            are under common control with us. We may share your information with
            our business partners to offer you certain products, services, or
            promotions.
          </p>
          <h2 className="TOU_subtitle">
            3. DO WE USE COOKIES AND OTHER TRACKING TECHNOLOGIES?
          </h2>
          <p className="TOU_p">
            We may use cookies and similar tracking technologies (like web
            beacons and pixels) to access or store information.
          </p>
          <h2 className="TOU_subtitle">
            4. CONTROLS FOR DO-NOT-TRACK FEATURES
          </h2>
          <p className="TOU_p">
            Most web browsers and some mobile operating systems and mobile
            applications include a Do-Not-Track ("DNT") feature or setting you
            can activate to signal your privacy preference not to have data
            about your online browsing activities monitored and collected. At
            this stage no uniform technology standard for recognizing and
            implementing DNT signals has been finalized. As such, we do not
            currently respond to DNT browser signals or any other mechanism that
            automatically communicates your choice not to be tracked online. If
            a standard for online tracking is adopted that we must follow in the
            future, we will inform you about that practice in a revised version
            of this privacy notice.
          </p>
          <h2 className="TOU_subtitle">
            5. DO WE MAKE UPDATES TO THIS NOTICE?
          </h2>
          <p className="TOU_p">
            We may update this privacy notice from time to time. The updated
            version will be indicated by an updated "Revised" date and the
            updated version will be effective as soon as it is accessible. If we
            make material changes to this privacy notice, we may notify you
            either by prominently posting a notice of such changes or by
            directly sending you a notification. We encourage you to review this
            privacy notice frequently to be informed of how we are protecting
            your information.
          </p>
        </Box>
        <Footer></Footer>
      </Body>
    </>
  );
}

export default PrivacyPolicy;
