import * as React from "react";
import Alert from "@mui/material/Alert";
import AlertTitle from "@mui/material/AlertTitle";
import Collapse from "@mui/material/Collapse";

export default function ErrorAlert({ title, message, open, updateOpen }) {
  return (
    <Collapse in={open}>
      <Alert
        onClose={() => {
          updateOpen(false);
        }}
        severity="error"
        sx={{ width: "80%", marginX: "auto" }}
      >
        <AlertTitle>{title}</AlertTitle>
        {message}
      </Alert>
    </Collapse>
  );
}
