import * as React from "react";

import { Grid, Box, Tooltip } from "@mui/material";

import discord from "../images/footer/discord-original.svg";
import discordHover from "../images/footer/discord-hover.svg";
import twitter from "../images/footer/twitter-original.svg";
import twitterHover from "../images/footer/twitter-hover.svg";

export default function Footer() {
  return (
    <>
      <div style={{ height: "200px" }}></div>

      <Box
        sx={{
          width: "100%",
          // background: "white",
          height: "150px",
          // marginTop: "5vh",
          position: "absolute",
          bottom: "25px",
        }}
      >
        <Grid
          container
          width={"100%"}
          justifyContent={"space-between"}
          spacing={2}
        >
          <Grid item xs={2} md={3} lg={4} xl={6}>
            {/* <Button
              href="/"
              sx={{
                "&:hover": {
                  backgroundColor: "transparent",
                },
              }}
              disableRipple
              className="footer_logo"
            >
              <img src={logo} className="footer_logo_image" />
            </Button> */}
          </Grid>
          <Grid item xs={12} md={7} lg={6}>
            <Box className="footer_socials">
              <Tooltip title="Coming Soon!">
                <div className="footer_socials_indiv">
                  {/* <a href="http://google.com"> */}
                  <img src={discord} className="img-normal" alt="Discord" />
                  <img src={discordHover} className="img-top" alt="Discord" />
                  {/* </a> */}
                </div>
              </Tooltip>
              <div className="footer_socials_indiv">
                <a href="https://twitter.com/DiamondHandMe">
                  <img src={twitter} className="img-normal" alt="Twitter" />
                  <img src={twitterHover} className="img-top" alt="Twitter" />
                </a>
              </div>
            </Box>
            <p className="footer_text">2023 Momo Labs LLC</p>
            <div className="footer_links">
              <a
                href="https://www.diamond-hand.me/terms"
                style={{ textDecoration: "none" }}
              >
                <p className="footer_terms">Terms of Use</p>
              </a>
              <a
                href="https://www.diamond-hand.me/privacy"
                style={{ textDecoration: "none" }}
              >
                <p className="footer_terms">Privacy Policy</p>
              </a>
            </div>
          </Grid>
        </Grid>
      </Box>
    </>
  );
}
