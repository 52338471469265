import React from "react";

//Components
import { Body } from "../components";
import NavBarHome from "../components/Home/NavBarHome";
import Footer from "../components/Footer";
import { Box } from "@mui/material";

function TermsOfUse() {
  return (
    <>
      <Body>
        <NavBarHome />
        <Box sx={{ width: "80%", marginX: "auto" }}>
          <h1 className="TOU_title">Terms of Use</h1>
          <h2 className="TOU_last_updated">Last updated Jan 04, 2023</h2>
          <h2 className="TOU_subtitle">1. AGREEMENT TO TERMS</h2>
          <p className="TOU_p">
            These Terms of Use constitute a legally binding agreement made
            between you, whether personally or on behalf of an entity (“you”)
            and Momo Labs LLC ("Company," “we," “us," or “our”), concerning your
            access to and use of the{" "}
            <a href="https://diamond-hand.me">https://diamond-hand.me</a>{" "}
            website as well as any other media form, media channel, mobile
            website or mobile application related, linked, or otherwise
            connected thereto (collectively, the “Site”). You agree that by
            accessing the Site, you have read, understood, and agreed to be
            bound by all of these Terms of Use. If You do not agree to these
            Terms of Use, please do not use or visit the Site. We reserve the
            right, in our sole discretion, to make changes or modifications to
            these Terms of Use from time to time. We will alert you about any
            changes by updating the “Last updated” date of these Terms of Use,
            and you waive any right to receive specific notice of each such
            change. Please ensure that you check the applicable Terms every time
            you use our Site so that you understand which Terms apply. You will
            be subject to, and will be deemed to have been made aware of and to
            have accepted, the changes in any revised Terms of Use by your
            continued use of the Site after the date such revised Terms of Use
            are posted. The Site is not intended for children under 13. If You
            are under 13 years of age, please do not use the Site.
          </p>
          <h2 className="TOU_subtitle">2. INTELLECTUAL PROPERTY RIGHTS</h2>
          <p className="TOU_p">
            Unless otherwise indicated, the Site is our proprietary property and
            all original source code, databases, functionality, software,
            website designs, audio, video, text, photographs, and graphics on
            the Site (collectively, the “Content”) and the trademarks, service
            marks, and logos contained therein (the “Marks”) are owned or
            controlled by us or licensed to us, and are protected by copyright
            and trademark laws and various other intellectual property rights
            and unfair competition laws of the United States, international
            copyright laws, and international conventions. The Content and the
            Marks are provided on the Site “AS IS” for your information and
            personal use only.
          </p>
          <h2 className="TOU_subtitle">3. USER REPRESENTATIONS</h2>
          <p className="TOU_p">
            By using the Site, you represent and warrant that: (1) you have the
            legal capacity and you agree to comply with these Terms of Use; (2)
            you are not a minor in the jurisdiction in which you reside; (3) you
            will not use the Site for any illegal or unauthorized purpose; and
            (4) your use of the Site will not violate any applicable law or
            regulation.
          </p>
          <h2 className="TOU_subtitle">4. PROHIBITED ACTIVITIES</h2>
          <p className="TOU_p">
            You may not access or use the Site for any purpose other than that
            for which we make the Site available. The Site may not be used in
            connection with any commercial endeavors except those that are
            specifically endorsed or approved by us. <br></br> As a user of the
            Site, you agree not to:
            <li className="TOU_p">
              Systematically retrieve data or other content from the Site to
              create or compile, directly or indirectly, a collection,
              compilation, database, or directory without written permission
              from us.
            </li>
            <li className="TOU_p">
              Trick, defraud, or mislead us and other users, especially in any
              attempt to learn sensitive account information.
            </li>
            <li className="TOU_p">
              Circumvent, disable, or otherwise interfere with security-related
              features of the Site, including features that prevent or restrict
              the use or copying of any Content or enforce limitations on the
              use of the Site and/or the Content contained therein.
            </li>
            <li className="TOU_p">
              Use any information obtained from the Site in order to harass,
              abuse, or harm another person.
            </li>
            <li className="TOU_p">
              Use the Site in a manner inconsistent with any applicable laws or
              regulations. Engage in unauthorized framing of or linking to the
              Site.
            </li>
            <li className="TOU_p">
              Upload or transmit (or attempt to upload or to transmit) viruses,
              Trojan horses, or other material, including excessive use of
              capital letters and spamming (continuous posting of repetitive
              text), that interferes with any party’s uninterrupted use and
              enjoyment of the Site or modifies, impairs, disrupts, alters, or
              interferes with the use, features, functions, operation, or
              maintenance of the Site.
            </li>
            <li className="TOU_p">
              Upload or transmit (or attempt to upload or to transmit) any
              material that acts as a passive or active information collection
              or transmission mechanism, including without limitation, clear
              graphics interchange formats (“gifs”), 1×1 pixels, web bugs,
              cookies, or other similar devices (sometimes referred to as
              “spyware” or “passive collection mechanisms” or “pcms”).
            </li>
            <li className="TOU_p">
              Interfere with, disrupt, or create an undue burden on the Site or
              the networks or services connected to the Site.
            </li>
            <li className="TOU_p">
              Attempt to bypass any measures of the Site designed to prevent or
              restrict access to the Site, or any portion of the Site.
            </li>
          </p>
          <h2 className="TOU_subtitle">5. THIRD-PARTY WEBSITE AND CONTENT</h2>
          <p className="TOU_p">
            The Site may contain (or you may be sent via the Site) links to
            other websites ("Third- Party Websites") as well as articles,
            photographs, text, graphics, pictures, designs, music, sound, video,
            information, applications, software, and other content or items
            belonging to or originating from third parties ("Third-Party
            Content"). Such Third-Party Websites and Third-Party Content are not
            investigated, monitored, or checked for accuracy, appropriateness,
            or completeness by us, and we are not responsible for any
            Third-Party Websites accessed through the Site or any Third-Party
            Content posted on, available through, or installed from the Site,
            including the content, accuracy, offensiveness, opinions,
            reliability, privacy practices, or other policies of or contained in
            the Third-Party Websites or the Third-Party Content. Inclusion of,
            linking to, or permitting the use or installation of any Third-Party
            Websites or any Third-Party Content does not imply approval or
            endorsement thereof by us. If you decide to leave the Site and
            access the Third-Party Websites or to use or install any Third-Party
            Content, you do so at your own risk, and you should be aware these
            Terms of Use no longer govern. You should review the applicable
            terms and policies, including privacy and data gathering practices,
            of any website to which you navigate from the Site or relating to
            any applications you use or install from the Site. Any purchases you
            make through Third-Party Websites will be through other websites and
            from other companies, and we take no responsibility whatsoever in
            relation to such purchases which are exclusively between you and the
            applicable third party. You agree and acknowledge that we do not
            endorse the products or services offered on Third-Party Websites and
            you shall hold us harmless from any harm caused by your purchase of
            such products or services. Additionally, you shall hold us harmless
            from any losses sustained by you or harm caused to you relating to
            or resulting in any way from any Third-Party Content or any contact
            with Third-Party Websites.
          </p>
          <h2 className="TOU_subtitle">6. PRIVACY POLICY</h2>
          <p className="TOU_p">
            We care about data privacy and security. By using the Site, you
            agree to be bound by our{" "}
            <a href="https://www.diamond-hand.me/privacy">Privacy Policy</a> ,
            which is incorporated into these Terms of Use. Please be advised the
            Site is hosted in the United States. If you access the Site from any
            other region of the world with laws or other requirements governing
            personal data collection, use, or disclosure that differ from
            applicable laws in the United States, then through your continued
            use of the Site, you are transferring your data to the United
            States, and you agree to have your data transferred to and processed
            in the United States.
          </p>
          <h2 className="TOU_subtitle">7. MODIFICATIONS AND INTERRUPTIONS</h2>
          <p className="TOU_p">
            We reserve the right to change, modify, or remove the contents of
            the Site at any time or for any reason at our sole discretion
            without notice. However, we have no obligation to update any
            information on our Site. We also reserve the right to modify or
            discontinue all or part of the Site without notice at any time. We
            will not be liable to you or any third party for any modification,
            price change, suspension, or discontinuance of the Site. We cannot
            guarantee the Site will be available at all times. We may experience
            hardware, software, or other problems or need to perform maintenance
            related to the Site, resulting in interruptions, delays, or errors.
            We reserve the right to change, revise, update, suspend,
            discontinue, or otherwise modify the Site at any time or for any
            reason without notice to you. You agree that we have no liability
            whatsoever for any loss, damage, or inconvenience caused by your
            inability to access or use the Site during any downtime or
            discontinuance of the Site. Nothing in these Terms of Use will be
            construed to obligate us to maintain and support the Site or to
            supply any corrections, updates, or releases in connection
            therewith.
          </p>
          <h2 className="TOU_subtitle">8. CORRECTIONS</h2>
          <p className="TOU_p">
            There may be information on the Site that contains typographical
            errors, inaccuracies, or omissions, including descriptions, pricing,
            availability, and various other information. We reserve the right to
            correct any errors, inaccuracies, or omissions and to change or
            update the information on the Site at any time, without prior
            notice.
          </p>
          <h2 className="TOU_subtitle">9. DISCLAIMER</h2>
          <p className="TOU_p">
            THE SITE IS PROVIDED ON AN AS-IS AND AS-AVAILABLE BASIS. YOU AGREE
            THAT YOUR USE OF THE SITE AND OUR SERVICES WILL BE AT YOUR SOLE
            RISK. TO THE FULLEST EXTENT PERMITTED BY LAW, WE DISCLAIM ALL
            WARRANTIES, EXPRESS OR IMPLIED, IN CONNECTION WITH THE SITE AND YOUR
            USE THEREOF, INCLUDING, WITHOUT LIMITATION, THE IMPLIED WARRANTIES
            OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, AND
            NON-INFRINGEMENT. WE MAKE NO WARRANTIES OR REPRESENTATIONS ABOUT THE
            ACCURACY OR COMPLETENESS OF THE SITE’S CONTENT OR THE CONTENT OF ANY
            WEBSITES LINKED TO THE SITE AND WE WILL ASSUME NO LIABILITY OR
            RESPONSIBILITY FOR ANY (1) ERRORS, MISTAKES, OR INACCURACIES OF
            CONTENT AND MATERIALS, (2) PERSONAL INJURY OR PROPERTY DAMAGE, OF
            ANY NATURE WHATSOEVER, RESULTING FROM YOUR ACCESS TO AND USE OF THE
            SITE, (3) ANY UNAUTHORIZED ACCESS TO OR USE OF OUR SECURE SERVERS
            AND/OR ANY AND ALL PERSONAL INFORMATION AND/OR FINANCIAL INFORMATION
            STORED THEREIN, (4) ANY INTERRUPTION OR CESSATION OF TRANSMISSION TO
            OR FROM THE SITE, (5) ANY BUGS, VIRUSES, TROJAN HORSES, OR THE LIKE
            WHICH MAY BE TRANSMITTED TO OR THROUGH THE SITE BY ANY THIRD PARTY,
            AND/OR (6) ANY ERRORS OR OMISSIONS IN ANY CONTENT AND MATERIALS OR
            FOR ANY LOSS OR DAMAGE OF ANY KIND INCURRED AS A RESULT OF THE USE
            OF ANY CONTENT POSTED, TRANSMITTED, OR OTHERWISE MADE AVAILABLE VIA
            THE SITE. WE DO NOT WARRANT, ENDORSE, GUARANTEE, OR ASSUME
            RESPONSIBILITY FOR ANY PRODUCT OR SERVICE ADVERTISED OR OFFERED BY A
            THIRD PARTY THROUGH THE SITE, ANY HYPERLINKED WEBSITE, OR ANY
            WEBSITE OR MOBILE APPLICATION FEATURED IN ANY BANNER OR OTHER
            ADVERTISING, AND WE WILL NOT BE A PARTY TO OR IN ANY WAY BE
            RESPONSIBLE FOR MONITORING ANY TRANSACTION BETWEEN YOU AND ANY
            THIRD-PARTY PROVIDERS OF PRODUCTS OR SERVICES. AS WITH THE PURCHASE
            OF A PRODUCT OR SERVICE THROUGH ANY MEDIUM OR IN ANY ENVIRONMENT,
            YOU SHOULD USE YOUR BEST JUDGMENT AND EXERCISE CAUTION WHERE
            APPROPRIATE.
          </p>
          <h2 className="TOU_subtitle">10. LIMITATIONS OF LIABILITY</h2>
          <p className="TOU_p">
            IN NO EVENT WILL WE OR OUR DIRECTORS, EMPLOYEES, OR AGENTS BE LIABLE
            TO YOU OR ANY THIRD PARTY FOR ANY DIRECT, INDIRECT, CONSEQUENTIAL,
            EXEMPLARY, INCIDENTAL, SPECIAL, OR PUNITIVE DAMAGES, INCLUDING LOST
            PROFIT, LOST REVENUE, LOSS OF DATA, OR OTHER DAMAGES ARISING FROM
            YOUR USE OF THE SITE, EVEN IF WE HAVE BEEN ADVISED OF THE
            POSSIBILITY OF SUCH DAMAGES. WE ARE NOT RESPONSIBLE FOR THE
            NON-FUNGIBLE TOKENS (NFTs), ERC20 TOKENS, OR ETHER (COLLECTIVELY
            “DIGITAL ASSETS”) THAT ARE STORED THROUGH THE SITE AND THE
            ASSOCIATED SMART CONTRACT. USERS ARE ENTIRELY RESPONSIBLE FOR THE
            SAFETY AND MANAGEMENT OF THEIR OWN PRIVATE ETHEREUM WALLETS AND
            VALIDATING ALL TRANSACTIONS AND CONTRACTS GENERATED BY THIS SITE
            BEFORE APPROVAL. BY USING THIS SITE, YOU ARE ACCEPTING SOLE
            RESPONSIBILITY FOR TRANSACTIONS INVOLVING YOUR DIGITAL ASSETS.
          </p>
          <h2 className="TOU_subtitle">11. INDEMNIFICATION</h2>
          <p className="TOU_p">
            You agree to defend, indemnify, and hold us harmless, including our
            subsidiaries, affiliates, and all of our respective officers,
            agents, partners, and employees, from and against any loss, damage,
            liability, claim, or demand, including reasonable attorneys’ fees
            and expenses, made by any third party due to or arising out of: (1)
            use of the Site; (2) breach of these Terms of Use; (3) any breach of
            your representations and warranties set forth in these Terms of Use;
            (4) your violation of the rights of a third party, including but not
            limited to intellectual property rights; or (5) any overt harmful
            act toward any other user of the Site with whom you connected via
            the Site. Notwithstanding the foregoing, we reserve the right, at
            your expense, to assume the exclusive defense and control of any
            matter for which you are required to indemnify us, and you agree to
            cooperate, at your expense, with our defense of such claims. We will
            use reasonable efforts to notify you of any such claim, action, or
            proceeding which is subject to this indemnification upon becoming
            aware of it.
          </p>
          <h2 className="TOU_subtitle">12. MISCELLANEOUS</h2>
          <p className="TOU_p">
            These Terms of Use and any policies or operating rules posted by us
            on the Site or in respect to the Site constitute the entire
            agreement and understanding between you and us. Our failure to
            exercise or enforce any right or provision of these Terms of Use
            shall not operate as a waiver of such right or provision. These
            Terms of Use operate to the fullest extent permissible by law. We
            may assign any or all of our rights and obligations to others at any
            time. We shall not be responsible or liable for any loss, damage,
            delay, or failure to act caused by any cause beyond our reasonable
            control. If any provision or part of a provision of these Terms of
            Use is determined to be unlawful, void, or unenforceable, that
            provision or part of the provision is deemed severable from these
            Terms of Use and does not affect the validity and enforceability of
            any remaining provisions. There is no joint venture, partnership,
            employment or agency relationship created between you and us as a
            result of these Terms of Use or use of the Site. You agree that
            these Terms of Use will not be construed against us by virtue of
            having drafted them. You hereby waive any and all defenses you may
            have based on the electronic form of these Terms of Use and the lack
            of signing by the parties hereto to execute these Terms of Use.
          </p>
          <h2 className="TOU_subtitle">13. CONTACT US</h2>
          <p className="TOU_p">
            In order to resolve a complaint regarding the Site or to receive
            further information regarding use of the Site, please contact us at:
            info.momolabs@gmail.com
          </p>
        </Box>
        <Footer></Footer>
      </Body>
    </>
  );
}

export default TermsOfUse;
